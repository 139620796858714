import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import NavMenu from './NavMenu';
export class Layout extends Component {
    //static displayName = Layout.name;
    constructor(props) {
        super(props);
        this.state = {
            cartDetails: this.props.cartContents,
            appVersion :''
       };
       
    }
    componentDidMount() {
        this.getAppVersion();
    }
  render () {
    return (

      <div className = "UWA_PageContainer">
            <NavMenu cartContents={this.props.cartContents}/>
            <Container>
                <Row>
                    <Col>
                    {this.props.children}
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <footer className="UWA_footer">
                            <hr/>
                            <span className="text-muted">&copy; 2024 Universal Weather and Aviation, Inc. <b>Build #-{this.state.appVersion}</b></span>
                        </footer>
                </Row>
                    
         </Container>
      </div>
    );
    }
    async getAppVersion() {
        var endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
        const serviceUrl = endPointURL + 'api/ChargebeeWidgets/GetAppVersion';
        const response = await fetch(serviceUrl);
        const data = await response.text();
        this.setState({ appVersion: data, loading: false });
    }
}
