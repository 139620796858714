import React, { useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';
function FIQAddUserModal(props) {
 
    //const editName = props.editName;
    //const editUserName = props.editUserName;
    //const editEmail = props.editEmail;
    const open = props.open;
    const onClose = props.onClose;
    const [validationErrMsg, updateValidationErrMsg] = React.useState("");
    //const [hasInputValues, toggleHasInputValues] = React.useState(false);
    //const [isEditRecord, updateEditRecordFlag] = React.useState(false);
    const [fname, updateFName] = React.useState("");
    const [lname, updateLName] = React.useState("");
    const [userName, updateUserName] = React.useState("");
    const [originalEmail] = React.useState(props.editEmail);
    const [email, updateEmail] = React.useState(props.editEmail);
    const [errFNameMsg, updateFNameErrorMsg] = React.useState("");
    const [errLNameMsg, updateLNameErrorMsg] = React.useState("");
    const [errUserNameMsg, updateUserNameErrorMsg] = React.useState("");
    const [errEmailMsg, updateEmailErrorMsg] = React.useState("");

    //const [editName, updateEditName] = props.editName;
    //const [editUserName, updateEditUserName] = props.editUserName;
    //const [editEmail, updateEditEmail] = props.editEmail;


   

    //function onClearFields(e)
    //{
    //    updateName("");
    //    updateUserName("");
    //    updateEmail("");
    //    updateValidationErrMsg("");

    //};
    const handleFNameChange = (e) =>{
        updateFName(e.target.value);
    };
    const handleLNameChange = (e) => {
        updateLName(e.target.value);
    };
    const handleuserNameChange = (e) => {
        updateUserName(e.target.value);
    };
    const handleEmailChange = (e) => {
        updateEmail(e.target.value);
    };
    const onFIQUserAdd = async() =>{
        if (await validateUserFields()) {
          

            //if (await props.updateUserList(originalEmail, ((!!fname) ? fname : props.editFName), ((!!lname) ? lname : props.editLName), ((!!userName) ? userName : props.editUserName), ((!!email) ? email : props.editEmail))) {
            if (await props.updatePlanUser(props.editEmail, fname, lname, userName, email)) {
               updateFName("");
                updateLName("");
                updateUserName("");
                updateEmail("");
                updateValidationErrMsg("");
                props.onClose(false);
            }
            else {
                updateValidationErrMsg("Unable to add user to the list of existing users. The username and email must be unique.")
            };

        }
        
        
    }
    async function validateUserFields(){
        var success = true;
        updateFNameErrorMsg("");
        updateLNameErrorMsg("");
        updateUserNameErrorMsg("");
        updateEmailErrorMsg("");
        if (!(fname !== undefined && fname !== '' && /^[a-zA-Z '.-]*$/.test(fname))) {
            updateFNameErrorMsg("You must enter a first name for the subscriber");
            success = false;
        }
        if (!(lname !== undefined && lname !== '' && /^[a-zA-Z '.-]*$/.test(lname))) {
            updateLNameErrorMsg("You must enter a last name for the subscriber");
            success = false;
        }

        if (!(userName !== undefined && userName !== '' && /^[a-zA-Z0-9]+([a-zA-Z0-9](_|-| )[a-zA-Z0-9])*[a-zA-Z0-9]+$/.test(userName))) {
            updateUserNameErrorMsg("Please enter a username using only characters and numbers. Max size of 15 characters.");
            success = false;
        }
        if (!(email !== undefined && email !== '' && /^\w+([\.\+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))) {
            updateEmailErrorMsg("Email is not valid");
            success = false;
        }
        return success;
    }
    useEffect(() => {
        updateValidationErrMsg("");
        updateFNameErrorMsg("");
        updateLNameErrorMsg("");
        updateUserNameErrorMsg("");
        updateEmailErrorMsg("");
        updateFName(props.editFName);
        updateLName(props.editLName);
        updateUserName(props.editUserName);
        updateEmail(props.editEmail);
    }, [open]);
    
    return (
        <Dialog
            open={open}
            onClose={(_, reason) => {
                if (reason !== "backdropClick") {
                    onClose();
                }
            }}
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle className="UWA_DialogTitle" >
                Add Feasibility IQ User
            </DialogTitle>
            <DialogContent className="UWA_FIQAddUserDialogContent" sx={{ margin: '5px' }}>

                <TextField style={{ width: '45%', margin: '5px' }}
                    name="FName"
                    label="First Name"
                    size="small"
                    value={fname}
                    onChange={(handleFNameChange) => updateFName(handleFNameChange.target.value)}
                    helperText={errFNameMsg}
                />
                <TextField style={{ width: '45%', margin: '5px' }}
                    name="LName"
                    label="Last Name"
                    size="small"
                    value={lname}
                    onChange={(handleLNameChange) => updateLName(handleLNameChange.target.value)}
                    helperText={errLNameMsg}
                />
                <TextField style={{ width: '90%', margin: '5px' }}
                    name="username"
                    label="Username"
                    size="small"
                    value={userName }
                     onChange={(handleuserNameChange) => updateUserName(handleuserNameChange.target.value)}
                    inputProps={{ 'maxLength': 15 }}
                    helperText={errUserNameMsg}
                />
                <TextField style={{ width: '90%', margin: '5px' }}
                    name="email"
                    label="Email"
                    size="small"
                    value={email}
                    onChange={(handleEmailChange) => updateEmail(handleEmailChange.target.value)}
                    helperText={errEmailMsg}
                />
                <div style={{ width: '90%', margin: '5px' }}>
                    <span className=" has-error">{validationErrMsg}</span>
                 </div>
                <Button onClick={onFIQUserAdd} autoFocus variant="contained" className="UWA_btn_Submit">
                    Submit
                </Button>
            </DialogContent>
            <DialogActions >
           
                <Button onClick={onClose} autoFocus variant="contained" className="UWA_btn_cancel">
                    Close
                </Button>

            </DialogActions>
        </Dialog>
    );

};
export default FIQAddUserModal;