import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import { Button } from '@mui/material';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import { commonfunctions } from '../_helpers/commonfunctions';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';


export default function RenderQueryParamShoppingCart(props) {
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'left',
        color: theme.palette.text.secondary,
    }));

    const [loading, setLoading] = useState(true);
    const [expandedCompanyInfo, setExpandedCompanyInfo] = useState(false);
    const [expandedPricingInfo, setExpandedPricingInfo] = useState(false);
    const [expandedUsersInfo, setExpandedUsersInfo] = useState(false);
    const [expandedDefault, setExpandedDefault] = useState(true);
    const [hasPlanUsers, setHasPlanUsers] = useState(false);
    const [queryCartID] = useState(props.queryCart);
    const [invalidCartMsg, updateInvalidCartMsg] = useState("");
    const [invalidRejectMsg, updateRejectCartMsg] = useState("");
    const [isValidShoppingCart, updateIsValidShoppingCart] = useState(false);
    const [cartData, setCartData] = React.useState("");

    const [contactName, setCartContactName] = useState("");
    const [accountManagerName, setAccountMgrName] = useState("");
   
    const handleCheckOutClick = async () => {
        var endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
        const body = {
            cartID: queryCartID,
            verificationCode: ''
        };
        try {
            const serviceUrl = endPointURL + 'api/ShoppingCart/StartCOPFIQShoppingCart';
            await axios.post(serviceUrl, body)
                .then((response) => {
                    if (response.status === 200) {
                        //Add the cart to browser storage and redirect to the checkout page.
                        localStorage.setItem("UWA_ShoppingCart", JSON.stringify(queryCartID));
                        window.location.href = (props.checkOutPageLink === '' ? '/CheckoutWizard' : props.checkOutPageLink);
                    }

                })
                .catch((error) => {
                    console.log(error);
                    updateRejectCartMsg("The system has encountered an error while processing your information to begin the checkout. Please contact Customer Service at +1 (713) 378-2778 and provide the full url you entered so that it can be investigated.");

                });
        }
        catch (error) {
            updateRejectCartMsg("The system has encountered an error while processing your information. Please contact Customer Service at +1 (713) 378-2778 and provide the full url you entered so that it can be investigated.");
        }
    }
    const handleRejectClick = async () => {
        if (window.confirm('Are you sure you want to reject this cart configuration?')) {
            var endPointURL = process.env.REACT_APP_API_URL;
            if (process.env.REACT_APP_API_URL === undefined) {
                endPointURL = "/";
            }
            const body = {
                cartID: queryCartID,
                verificationCode: ''
            };
            try {
                const serviceUrl = endPointURL + 'api/ShoppingCart/RejectFIQShoppingCart';
                await axios.post(serviceUrl, body)
                    .then((response) => {
                        if (response.status === 200) {
                            //redirect the user back to the shopping cart page
                            window.location.href = (props.shoppingCartPageLink === '' ? '/GetShoppingCart' : props.shoppingCartPageLink); 

                        }

                    })
                    .catch((error) => {
                        console.log(error);
                        updateRejectCartMsg("The system has encountered an error while processing your information. Please contact Customer Service at +1 (713) 378-2778 and provide the full url you entered so that it can be investigated.");

                    });
            }
            catch (error) {
                updateRejectCartMsg("The system has encountered an error while processing your information. Please contact Customer Service at +1 (713) 378-2778 and provide the full url you entered so that it can be investigated.");
            }
        };
    }
    const handleCompanyClick = () => {
        //Hide all the other panels.
        setExpandedDefault(expandedCompanyInfo);
        setExpandedPricingInfo(false);
        setExpandedUsersInfo(false);
        setExpandedCompanyInfo(!expandedCompanyInfo);
    }
    const handleUsersClick = () => {
        setExpandedDefault(expandedUsersInfo);
        setExpandedPricingInfo(false);
        setExpandedCompanyInfo(false);
        setExpandedUsersInfo(!expandedUsersInfo);
    }
    const handlePricingClick = () => {
        setExpandedDefault(expandedPricingInfo);
        setExpandedUsersInfo(false);
        setExpandedCompanyInfo(false);
        setExpandedPricingInfo(!expandedPricingInfo);
    }
    const ValidateShoppingCart = async () => {
        var endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }

        const body = {
            cartID: queryCartID,
            verificationCode: ''
        };
        try {

            const serviceUrl = endPointURL + 'api/ShoppingCart/ValidateShoppingCart';
            await axios.post(serviceUrl, body)
                .then((response) => {
                    if (response.status === 200) {
                        //console.log(response);
                        if (response.data.subscribers.length > 0) {
                            setHasPlanUsers(true);
                        }
                        if (!response.data.hasBeenViewed) {
                            updateIsValidShoppingCart(true);
                            setCartData(response);
                            setCartContactName(response.data.contactFirstName + " " + response.data.contactLastName);
                            setAccountMgrName(response.data.accountManagerName);
                        }
                        else {
                            localStorage.setItem("UWA_ShoppingCart", JSON.stringify(queryCartID));
                            updateRejectCartMsg("Checkout process has already been started.  Redirecting to the regular shopping cart page.");
                            window.location.href = (props.shoppingCartPageLink === '' ? '/GetShoppingCart' : props.shoppingCartPageLink);
                        }
                        
                      
                    }

                })
                .catch((error) => {
                    console.log(error);
                    updateInvalidCartMsg(error.response.data.detail);
                });
      



        }
        catch (error) {
            updateInvalidCartMsg("The system has encountered an error while processing your information. Please contact Customer Service at +1 (713) 378-2778 and provide the full url you entered so that it can be investigated." );
        }
        setLoading(false);
       
    }
    const getCompanyInfo = (c) => {
         if (!!c){
             return (
                    <><Divider role="presentation" sx={{ marginBottom: '8px' }}> <Chip label="Customer Information" className="UWA_FIQHeader"/></Divider>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                         <Item> {loading ? (<Skeleton variant="rectangular" />) : (<font className="UWA_FIQLabel"><b>Name&nbsp;</b>{c.data.customerName}</font>)}</Item>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                         <Item>{loading ? (<Skeleton variant="rectangular" sx={{ fontSize: '6rem' }} />) : (<font className="UWA_FIQLabel"><b>Billing Address&nbsp;</b><p dangerouslySetInnerHTML={{ __html: c.data.companyBillingAddress }}></p></font>)} </Item>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                         <Item>{loading ? (<Skeleton variant="rectangular" sx={{ fontSize: '6rem' }} />) : (<font className="UWA_FIQLabel"><b>Shipping Address&nbsp;</b><p dangerouslySetInnerHTML={{ __html: c.data.companyShippingAddress }}></p></font>)} </Item>
                        </Grid>
                   
                     </Grid>
                    </>
        );
        }
    };
    const getSubscriberInfo = (c) => {
        console.log(c);
        if (!!c && c.data.subscribers?.length > 0) {
            return (
                React.Children.toArray(
                c.data.subscribers.map((subscriber, index) =>{
                    return (<> 
                            <Grid item xs={12} sm={12} sx={{ pt: 3, display: { xs: "block", sm: "block", md: "none", lg: "none" } }} key={'lblName_' + index} ><b>Name</b>
                            </Grid>
                        <Grid item xs={12} sm={12} md={6} key={'A_' + index} >
                            {loading ? (<Skeleton variant="rectangular" sx={{ fontSize: '6rem' }} />) : (<font className="UWA_FIQLabel">{subscriber.fname + " " + subscriber.lname}</font>)}
                              </Grid>
                     
                        <Grid item xs={12} sm={12} sx={{ pt: 3, display: { xs: "block", sm: "block", md: "none", lg: "none" } }} key={'lblEmail_' + index} ><b>Email</b></Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} key={"C" + index}>
                            {loading ? (<Skeleton variant="rectangular" sx={{ fontSize: '6rem' }} />) : (<font className="UWA_FIQLabel">{subscriber.email}</font>)}
                            </Grid>
                    </>);
                })
                ));
          }
    };
    //const getPricingInfo2 = (c) => {
    //    //console.log(c);
    //    if (!!c && c.data.cartLines?.length > 0) {
    //        return (
                
    //            React.Children.toArray(
    //            c.data.cartLines.map((cartLine, index) =>{
    //                return (<><Divider role="presentation" sx={{ marginBottom: '8px' }}> <Chip label="Pricing Information" className="UWA_FIQHeader" /></Divider>
    //                            <Grid container spacing={1} justifyContent="space-around" alignItems="center">
    //                                <Grid container spacing={2} className="UWA_FIQCartHeader">
    //                                    <Grid item xs={6} align="left">
    //                                        <b>Name</b>
    //                                    </Grid>

    //                                    <Grid item xs={2} align="center">
    //                                        <b>Quantity</b>
    //                                    </Grid>
    //                                    <Grid item xs={2} align="center">
    //                                        <b>Unit Price</b>
    //                                    </Grid>
    //                                    <Grid item xs={2} align="right">
    //                                        <b> Sub Total</b>
    //                                    </Grid>

    //                                </Grid>
    //                        <Grid container spacing={2} className="UWA_FIQCartLine">
    //                            <Grid item xs={6} align="left">
    //                                {cartLine.description}
    //                            </Grid>

    //                            <Grid item xs={2} align="center">
    //                                {cartLine.quantity}
    //                            </Grid>
    //                            <Grid item xs={2} align="center">
    //                                {commonfunctions.formatToCurrency(cartLine.unitPrice)}
    //                            </Grid>
    //                            <Grid item xs={2} align="right">
    //                                {!!cartLine.quantity ? commonfunctions.formatToCurrency(cartLine.unitPrice * cartLine.quantity) : (0)}
    //                            </Grid>
    //                            {(cartLine.discount > 0 ?
    //                                <> <Grid item xs={10} align="right">
    //                                <b> Discounts Applied </b>
    //                            </Grid>
    //                            <Grid item xs={2} align="right">
    //                                    <font className={cartLine.discount > 0 ? "UWA_CartDiscountAmount" : ""}>{commonfunctions.formatToCurrency(cartLine.discount)}</font>
    //                                </Grid>
    //                                </>
    //                            : <></>
    //                            )}
                               
    //                            <Grid item xs={10} align="right">
    //                                <b> Cart Total </b>
    //                            </Grid>
    //                            <Grid item xs={2} align="right">
    //                                {!!cartLine.quantity ? (<font className={((c.data.total)) > 0 ? "UWA_CartTotalAmount" : ""}>{commonfunctions.formatToCurrency(c.data.total)}</font>) : (0)}
    //                            </Grid>

    //                        </Grid>
    //                    </Grid>
    //                    </>);
    //            })
    //            ));
    //    }
    //};
    const getPricingInfo = (c) => {
        if (!!c && c.data.cartLines?.length > 0) {
            return (<>
                <Divider role="presentation" sx={{ marginBottom: '8px', marginTop: '10px' }}> <Chip label="Pricing Information" className="UWA_FIQHeader" /></Divider>
                <Grid container spacing={1} justifyContent="space-around" alignItems="center">
                    <Grid container spacing={2} className="UWA_FIQCartHeader">
                        <Grid item xs={5} align="left">
                            <b>Item</b>
                        </Grid>
                        <Grid item xs={1} align="right">
                            <b>Quantity</b>
                        </Grid>
                        <Grid item xs={2} align="left">
                            <b>Discount Type</b>
                        </Grid>
                        <Grid item xs={1} align="right">
                            <b>MSRP</b>
                        </Grid>
                        <Grid item xs={1} align="right">
                            <b>Unit Price</b>
                        </Grid>
                        <Grid item xs={1} align="right">
                            <b>Discounts</b>
                        </Grid>
                        <Grid item xs={1} align="right">
                            <b> Sub Total</b>
                        </Grid>
                    </Grid>
                    {c.data.cartLines.map((cartLine, index) => {
                        let parser = new DOMParser();
                        let extraInfo = parser.parseFromString(cartLine.extraInfo, "text/xml").childNodes[0];

                        var discountValue = "";
                        if (cartLine.appliedDiscount > 0) {
                            discountValue = cartLine.appliedDiscount;
                            if (cartLine.discountType === 'dollarDiscount') {
                                discountValue = "$" + discountValue;
                            }
                            else {
                                discountValue += "%";
                            }
                            discountValue += " (" + cartLine.discountDuration + ")";
                        }
                        else {
                            discountValue = "N/A";
                        }


                        return (<React.Fragment key={cartLine.lineNumber}>
                            <Grid item xs={5} align="left">
                                {cartLine.description}
                            </Grid>
                           
                            <Grid item xs={1} align="center">
                                {cartLine.quantity}
                            </Grid>

                            <Grid item xs={2} align="left">
                                {discountValue}
                            </Grid>
                            <Grid item xs={1} align="right">
                                {commonfunctions.formatCartPriceToCurrency(cartLine.msrp)}
                            </Grid>
                            <Grid item xs={1} align="right">
                                {commonfunctions.formatCartPriceToCurrency(cartLine.unitPrice)}
                            </Grid>
                            <Grid item xs={1} align="right">
                                {commonfunctions.formatCartPriceToCurrency(cartLine.discount)}
                            </Grid>
                            <Grid item xs={1} align="right">
                                {commonfunctions.formatCartPriceToCurrency(cartLine.subTotal)}
                            </Grid>
                        </React.Fragment>)
                    })}
                    <Grid container >
                        <Grid item xs={8} align="right" >
                            <b> Totals </b>
                        </Grid>
                        <Grid item xs={1} align="right" className="UWA_SubscriptionCartTotals">
                            {commonfunctions.formatCartPriceToCurrency(c.data.subTotal)}
                        </Grid>
                        <Grid item xs={1} align="right" className="UWA_SubscriptionCartTotals">
                            &nbsp;
                        </Grid>
                        <Grid item xs={1} align="right" className="UWA_SubscriptionCartTotals">
                            {commonfunctions.formatCartPriceToCurrency(c.data.discountAmount)}
                        </Grid>

                        <Grid item xs={1} align="right" className="UWA_SubscriptionCartTotals">
                            {commonfunctions.formatCartPriceToCurrency(c.data.total)}
                        </Grid>
                    </Grid>
                </Grid>
            </>)
        }
    };
    useEffect(() => {
        if (!!queryCartID) {
            ValidateShoppingCart(queryCartID);
        }
       
    }, []);
    return(

        <Card sx={{ maxWidth: '85%' }} className="mx-auto my-4 UWA_FIQCartCheckoutInfo">
            <CardHeader title="My Shopping Cart" className="UWA_FIQCardTitle" >
            </CardHeader>
                <CardContent className="UWA_CardContent_A">
                    {loading ? (<Skeleton variant="rectangular" sx={{ fontSize: '4rem' }} />)
                        : (isValidShoppingCart ? (
                            <><font className="UWA_FIQLabel"><p>Thanks for choosing Universal®.<br /><br/>
                               
                            <b>{accountManagerName}</b> has put together a custom shopping cart based on your recent discussions.<br /></p>
                                <p> 
                                If the information below looks correct, select BEGIN CHECK OUT to pay by credit card and complete this purchase.<br/><br/>
                                </p>
                            </font>
                            </>)
                            : (
                              loading ? (<Skeleton variant="rectangular" sx={{ fontSize: '4rem' }} />) :( <Alert severity="error"><>{invalidCartMsg}</></Alert>)
                            )

                        )}

                        {loading ? (<Skeleton variant="rectangular" sx={{ fontSize: '4rem' }} />) :
                            (<> {getCompanyInfo(cartData)} </>)
                  
                        }
                         {loading ? (<Skeleton variant="rectangular" sx={{ fontSize: '4rem' }} />) :
                    (hasPlanUsers ? <><Divider role="presentation" sx={{ marginBottom: '8px', marginTop: '10px' }}> <Chip label="Subscriber Information" className="UWA_FIQHeader" /></Divider>
                        <Grid container spacing={2} className="UWA_FIQCartSubscriberHeader">
                            <Grid item xs={12} sm={12}>The following users will be added as subscribers to this order.
                            </Grid>
                            <Grid item xs={6} sm={6} sx={{display: { xs: "none", md: "block", lg: "block" }}}><b>Name</b>
                                </Grid>
                           
                            <Grid item xs={6} sm={6} sx={{ display: { xs: "none", md: "block", lg: "block" } }}><b>Email</b>
                                </Grid>
                            
                           
                        </Grid>
                        <Grid container spacing={2} className="UWA_FIQCartSubscriberLine"> {getSubscriberInfo(cartData)}</Grid>
                    </> : "")
                        }
          {loading ? (<Skeleton variant="rectangular" sx={{ fontSize: '4rem' }} />) : (<>{isValidShoppingCart ? getPricingInfo(cartData) : ''}</>)}
        </CardContent>
                <CardContent className="UWA_CardContent_A">
                <Grid container spacing={3}>
                    <Grid item xs={6} sm={6}>
                        {isValidShoppingCart && <Button variant="contained" className="UWA_btn_deleteFIQcart" onClick={() => handleRejectClick()} >REJECT</Button>}
                    </Grid>
                    <Grid item xs={6} sm={6} container justifyContent="flex-end">
              
                        {isValidShoppingCart && <Button onClick={() => handleCheckOutClick()} variant="contained" className="UWA_btn_createFIQcart" >BEGIN CHECK OUT</Button>}
                      
                            </Grid>
                </Grid>
                {invalidRejectMsg && <Alert severity="error"><>{invalidRejectMsg}</></Alert>}
               
            </CardContent>
        </Card>

    );
};
