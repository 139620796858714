export const netsuiteService = {
    getOracleCustomer,
    buildHTMLAddress
};
function buildHTMLAddress(name, addr1, addr2, city, state, zip, country) {
    return (!!name ? name + "<br/>" : '' +
        addr1 + "<br/>" +
        city +  (!!state ? ", " + state : '') + " " + (!!zip ? zip : '') + "<br/>" +
        country);

}
 async function getOracleCustomer(id) {
    var endPointURL = process.env.REACT_APP_API_URL;
    if (process.env.REACT_APP_API_URL === undefined) {
        endPointURL = "/";
    }
   
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            SearchQuery: id
        })
    };
    try {
        const serviceUrl = endPointURL + 'api/NetSuite/GetNSCustomerDetails';
        fetch(serviceUrl, requestOptions)
            .then((response) => response.json())
            .then((data) => {
               
                return data;
            })
            .catch((error) => {
                console.error('Error:', error);
            });




    }
    catch (error) {
        return error;
    }
    

}