import React, { useState, useEffect, useCallback } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import AddBoxIcon from '@mui/icons-material/AddBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import cartfunctions from '../_helpers/cartfunctions';
import { commonfunctions } from '../_helpers/commonfunctions';
import Alert from '@mui/material/Alert';
import { ModalWait } from './WaitModal'



function ShoppingCart(props) {
    //const [cart] = useState(props.cartContents);
    //const [cart, updateCartContents] = React.useState(props.cartContents);
    const open = props.open;
    const onClose = props.onClose;
    const [checkOutPageLink] = React.useState(props.CheckOutPageLink);
    const [showWaitModal, updateWaitModal] = React.useState(false);
    const [cartUpdateErrorMsg, updateErrorMsg] = useState("");
  
    //const loadCart = async () => {
    //    var endPointURL = process.env.REACT_APP_API_URL;
    //    if (process.env.REACT_APP_API_URL === undefined) {
    //        endPointURL = "/";
    //    }
    //    const body = {
    //        cartID: cartfunctions.getCartID()
    //    };
    //    try {
    //        const serviceUrl = endPointURL + 'api/ShoppingCart/GetShoppingCart';
    //        await axios.post(serviceUrl, body)
    //            .then((response) => {
    //                if (response.status === 200) {
    //                    console.log(response.data);
    //                    updateCartContents(response.data);
                       

    //                }

    //            })
    //            .catch((error) => {
    //                console.log(error);

    //            });
    //    }
    //    catch (error) {
    //        console.log(error);
    //    }
    //}
    const clearCart = async () => {
        await cartfunctions.clearCart()
            .then((result) => {
                console.log(result);
                props.updateCartContents();
            })
            .catch((error) => {
                //console.log(error);
                updateErrorMsg(error.response.data.detail);
                setTimeout(() => {
                    updateErrorMsg("");
                }, 8000);

            });
  
    };
    const redirectToCheckOutWizard = async () => {
        window.location.href = (checkOutPageLink === '' ? '/CheckoutWizard' : checkOutPageLink);
    };
  
    async function handleAddToCartClick(itemID, unitPrice){
        updateWaitModal(true);
        await cartfunctions.addQtyToCart(itemID, unitPrice);
        props.updateCartContents();
        updateWaitModal(false);

        //LoadShoppingCart();

    };
    async function handleRemoveFromCartClick (itemID, unitPrice){
        await cartfunctions.removeQtyfromCart(itemID, unitPrice);
        props.updateCartContents();
        updateWaitModal(false);
    };
   
    return (
     
        <Dialog
            open={open}
            onClose={(_, reason) => {
                if (reason !== "backdropClick") {
                    onClose();
                }
            }}
        >
            <DialogTitle >
                Shopping Cart Details
            </DialogTitle>
            <DialogContent>
                <TableContainer component={Paper}>
                    {showWaitModal ? (
                        <ModalWait
                            handleResponse={showWaitModal}
                        />
                    ) : null}
                    <Table >
                        <TableHead>
                            <TableRow>
                                <TableCell align="left"> <b>Name</b></TableCell>
                                <TableCell align="right"> <b>Unit Price</b></TableCell>
                                <TableCell align="center"> <b>Quantity</b></TableCell>
                                <TableCell align="right"> <b>Total Price</b></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.cartContents && props.cartContents.cartLines?.map(cartline => (

                                <TableRow key={cartline.productID}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">{cartline.description}</TableCell>
                                    <TableCell component="th" scope="row" align="right">{commonfunctions.formatCartPriceToCurrency(cartline.unitPrice)}</TableCell>
                                    <TableCell component="th" scope="row" align="right">
                                        <Typography sx={{
                                        maxWidth: 200, // percentage also works
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',}}>
                                            {cartline.extraInfo == null && <Button onClick={() => handleRemoveFromCartClick(cartline.productID, cartline.unitPrice)} style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px' }}><IndeterminateCheckBoxIcon color="primary" /></Button>}
                                            {cartline.extraInfo != null && <Button sx={{ color: red[500] }} disabled style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px' }}><IndeterminateCheckBoxIcon /></Button>}
                                        <input type="text" disabled size="2" value={cartline.quantity} />
                                            {cartline.extraInfo == null && <Button onClick={() => handleAddToCartClick(cartline.productID, cartline.unitPrice)} style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px' }} ><AddBoxIcon color="primary" sx={{ pl: '0.1em', }} /></Button>}
                                            {cartline.extraInfo != null && <Button sx={{ color: red[500] }} disabled style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px' }} ><AddBoxIcon sx={{ pl: '0.1em', }} /></Button>}
                                                    </Typography>
                                    </TableCell>
                                    <TableCell component="th" scope="row" align="right">{commonfunctions.formatCartPriceToCurrency(cartline.subTotal)}</TableCell>
                                </TableRow>
                            ))}
                            <TableRow>
                                <TableCell component="th" scope="row" align="left">
                                    {props.cartContents.cartLines?.length > 0 && <Button size="small" sx={{ color: red[500] }} startIcon={<RemoveShoppingCartIcon />} onClick={clearCart} className="btn_clearcart">Clear Cart</Button>}
                                    
                                </TableCell>
                                <TableCell component="th" scope="row" colSpan="2" align="right"><b>Sub Total</b></TableCell>
                                <TableCell component="th" scope="row" align="right">{commonfunctions.formatCartPriceToCurrency(props.cartContents.total)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row" colSpan="4">
                                    {cartUpdateErrorMsg && <Alert severity="error"><>{cartUpdateErrorMsg}</></Alert>}
                                </TableCell>
                            </TableRow>

                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions >
                <Button onClick={onClose} autoFocus variant="contained" className="btn_continueshopping">
                    Continue Shopping
                </Button>
                <Button onClick={redirectToCheckOutWizard} color="success" variant="contained" className="btn_checkout">CHECK OUT</Button>

            </DialogActions>
            </Dialog>
      
    );
};
export default ShoppingCart;