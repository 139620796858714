import { commonService } from '../_services/common.service';
export const commonfunctions =
{
    formatToCurrency: function (amount) {
        if (typeof amount !== 'undefined') {
            return '$' + (Number(amount/100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'));
        }
        else {
            return null;
        }
    },
    formatCartPriceToCurrency: function (amount) {
        if (typeof amount !== 'undefined') {
            return '$' + (Number(amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'));
        }
        else {
            return null;
        }
    },
    formatToPercentage: function (percentage) {
        if (typeof percentage !== 'undefined' && percentage !== null) {
            return (percentage).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') + '%';
        }
        else {
            return null;
        }
    },
    getMonthlyPrice: function (amount) {
        let unitPrice = 0.00;
        if (typeof amount !== 'undefined') {
            unitPrice =   ((amount / 100)/12).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        }
        return '$' + unitPrice;
        
    },
    setUpperLimit: function (boundaryValue) {
        if (!!boundaryValue) { return boundaryValue }
        else { return "higher"}
    },
    handleError: function (responseStatus, error) {
      
        if (responseStatus === 400) { // custom error response format handling
            return "The server returned a 405 - Method not allowed.";
        }
        else if (responseStatus === 405)
        {
            return "The server returned a 405 error - Method not allowed.";
          
        }
        else if (responseStatus === 500)
        {
            console.log(error);
        }
        else if (!responseStatus) {
            return "The server returned a 405 - Method not allowed.";
        }
        return responseStatus;
    },
    getFormattedDate: function (dateVal) {
        
        return dateVal.toLocaleString('en-us', { month: 'long' }) + ' ' + dateVal.toLocaleString('en-us', { day: 'numeric' }) + ', ' + dateVal.toLocaleString('en-us', { year: 'numeric' });

    },
    formatToDate: function (dateVal) {
        if (typeof dateVal === 'string') {
            return new Date(dateVal).toLocaleDateString();
        }
        else {
            return "N/A";
        }
    },
    formatToDateTime: function (dateVal) {
        if (typeof dateVal === 'string') {
            return new Date(dateVal).toLocaleString();
        }
        else {
            return "N/A";
        }
    },
    formatFullName(firstName, lastName) {
        var fullName = "N/A";
        if (typeof firstName === 'string')  {
            fullName = firstName;
        }
        if (typeof lastName === 'string') {
            fullName = fullName + ' ' + lastName;
        }
        return fullName;
    },
    builAddress(name, addr1, city, state, zip, country) {
        var returnAddress = "";
        if (typeof name === 'string') {
            returnAddress = name  + "<br/>";
        }
        if (typeof addr1 === 'string') {
            returnAddress = returnAddress + addr1 + "<br/>";
        }
        if (typeof city === 'string') {
            returnAddress = returnAddress + city + ", ";
        }
        if (typeof state === 'string') {
            returnAddress = returnAddress + state + "  ";
        }
        if (typeof zip === 'string') {
            returnAddress = returnAddress + zip + "  ";
        }
        if (typeof country === 'string') {
            returnAddress = returnAddress + "<br/>" + country;
        }
        return returnAddress;

    },
    checkSubscriptionStatus(status) {
        if (status === 'Cancelled') {
            return true;
        }
        else {return false}
    },
    logEvent(logTypeKey, description, extraInfo) {
        commonService.logEvent(logTypeKey, description, extraInfo)
            .then(result => {
                if (result.status === 200) {
                    return result;
                }
            })
            .catch(error => {
                console.log(error);
            });
    }
}
export default commonfunctions;