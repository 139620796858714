import React, { useEffect, useState, useCallback } from 'react';
import Skeleton from '@mui/material/Skeleton';
import { yellow } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { Container, Row, Col } from 'reactstrap';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SearchIcon from '@mui/icons-material/Search';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Paper from '@mui/material/Paper';
import NetSuiteCustomerBrowse from '../NetSuiteCustomerBrowse';
import FIQAddUserModal from './FIQAddUserModal'
import { netsuiteService } from '../../_services/netsuite.service';
import { commonfunctions } from '../../_helpers/commonfunctions';
import { ModalWait } from '../WaitModal';
import TextField from '@mui/material/TextField';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Radio, RadioGroup, FormControlLabel} from '@mui/material';


export default function FeasibilityIQCart(props) {
    const [loading, setLoading] = React.useState(true);
    const [confirmUserDeleteModal, showConfirmUserDeleteModal] = React.useState(false);
    const [deleteUserEmail, setUserDeletionEmail] = React.useState("");
    const [CardHeaderTitle, updateCardHeaderTitle] = React.useState("Customer Information");

    const [cartMode] = React.useState(props.mode);
    const [currentCartID, updateCurrentCartID] = React.useState("");
    const [disabled, setDisabledFlag] = React.useState(false);
    const [cartLoaded, setCartIsLoaded] = React.useState(false);
    const [cartStatus, updateCartStatus] = React.useState("");
    const [createDate, updateCreateDate] = React.useState("");
    const [linkExpirationDate, updateLinkExpDate] = React.useState("");
    const [cartLink, updateCartLink] = React.useState("");
    const [canEditCart, updateCanEditCart] = React.useState(false);
    const [canArchiveCart, updateCanArchiveCart] = React.useState(false);
    const [canSendCartLink, updateCanSendCartLink] = React.useState(false);
    const [cartContactFName, updateCartContactFName] = React.useState("");
    const [cartContactLName, updateCartContactLName] = React.useState("");
    const [cartContactEmail, updateCartContactEmail] = React.useState("");
    const [cartContactPhone, updateCartContactPhone] = React.useState("");
    const [cartAccountMgrFName, updateCartAccountMgrFName] = React.useState("");
    const [cartAccountMgrLName, updateCartAccountMgrLName] = React.useState("");
    const [cartAccountMgrEmail, updateCartAccountMgrEmail] = React.useState("");

    const [cartNotificationType, updateCartNotificationType] = React.useState("AccountManager");
    const [cartNotificationOptionEnabled, updateCartNotificationOption] = React.useState(false);
    const [errContactFNameMsg, updateContactFNameErrMsg] = React.useState("");
    const [errContactLNameMsg, updateContactLNameErrMsg] = React.useState("");
    const [errContactEmailMsg, updateContactEmailErrMsg] = React.useState("");
    const [errContactPhoneMsg, updateContactPhoneErrMsg] = React.useState("");
    const [sendButtonText, updateSendButtonText] = React.useState("Send Link");

    const [showWaitModal, updateWaitModal] = React.useState(false);
    const [dialogIsOpen, setCustomerBrowseDialogOpen] = React.useState(false);
    const [editFName, setEditFName] = React.useState("");
    const [editLName, setEditLName] = React.useState("");
    const [editUserName, setEditUserName] = React.useState("");
    const [editEmail, setEditEmail] = React.useState("");
    const [shoppingCartValidationErrMsgs, setvalidationErrorMsg] = React.useState("");
    const [shoppingCartUpdateUserErrMsg, setuserValidationErrorMsg] = React.useState("");
    const [shoppingCartUpdateValidationErrMsgs, setUpdateCartValidationErrorMsg] = React.useState("");
    const [shoppingCartUpdateMsgs, setCartUpdateSuccessMsg] = React.useState("");
    const [couponCode, updateCouponCode] = React.useState("");
    const [appliedCouponCode, saveCouponCode] = React.useState("");
    const [couponCodeValMsg, updateCouponValidationMsg] = React.useState("");
    const [FIQAddUserDialogIsOpen, setFIQAddUserModalOpen] = React.useState(false);
    const [PlanUsers, addToPlanUserArray] = React.useState([]);
    const [FIQPricingLegend, addToFIQPricingLegend] = React.useState([]);
    const [SubscriptionProductName, updateProductName] = React.useState("");
    const [SubscriptionProductID, updateProductID] = React.useState("");
    const [unitPrice, updateUnitPrice] = React.useState(0);
    const [discountAmount, updateDiscountAmount] = React.useState(0);
    const [oracleCustomerID, setCurrentOracleCustomerID] = React.useState("");
    const [companyName, setCurrentCompanyName] = React.useState("");
    const [billingAddress, setCurrentBillingAddress] = React.useState("");
    const [shippingAddress, setCurrentShippingAddress] = React.useState("");
    const [shoppingCartLines, updateShoppingCartLines] = useState([]);


    const [currentCartSubTotal, updateCartSubTotal] = useState(0.00);
    const [currentCartDiscount, updateCartDiscount] = useState(0.00);
    const [currentCartTotal, updateCartTotal] = useState(0.00);
    const [planUsersSetupComplete, updatePlanUsersSetup] = useState(true);
    const [hasPlanItemInCart, setPlanItemInCart] = useState(false);

    const closeDialog = () => setCustomerBrowseDialogOpen(false);
    const closeFIQAddUserDialog = () => setFIQAddUserModalOpen(false);
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'left',
        color: theme.palette.text.secondary,
    }));
    const handleClickOpenCustomerBrowse = (event) => {
        setCustomerBrowseDialogOpen(true);
    };
    const handleClickOpenUserAdd = (event) => {
        setEditFName("");
        setEditLName("");
        setEditUserName("");
        setEditEmail("");
        setFIQAddUserModalOpen(true);
    };
    const handle_DeleteUser = async (event, emailAddress) => {
        setUserDeletionEmail(emailAddress);
        showConfirmUserDeleteModal(true);
      
    };
    const deleteUserFromSubscription = async () => {
        var success = false;
        let tempFIQUsers = [...PlanUsers];
        if (PlanUsers.length > 0) {
            for (let i = 0; i < PlanUsers.length; i++) {
                if (PlanUsers[i].email.toLowerCase() === deleteUserEmail.toLowerCase()) {
                    if (!cartLoaded) {
                        //We are deleting just from memory - there is no cart yet. 
                        tempFIQUsers.splice(i, 1);
                        success = true;
                    }
                    else {
                        if (await modifyFIQCartUser("Delete", PlanUsers[i].fname, PlanUsers[i].lname, PlanUsers[i].username, PlanUsers[i].email, null)) {
                            tempFIQUsers.splice(i, 1);
                            success = true;
                        };

                    }
                  

                }
            };
            if (success) {
                addToPlanUserArray(tempFIQUsers);
               
            };
            setUserDeletionEmail("");
            showConfirmUserDeleteModal(false);
        }
    }
    const handle_CloseUserDeletionDialog = () => {
        setUserDeletionEmail("");
        showConfirmUserDeleteModal(false);
    };
    const handle_ContactFieldChange = (e) => {
        switch (e.target.name) {
            case "ContactFName":
                updateCartContactFName(e.target.value);
                break;
            case "ContactLName":
                updateCartContactLName(e.target.value);
                break;
            case "ContactEmail":
                updateCartContactEmail(e.target.value);
                break;
            case "ContactPhone":
                updateCartContactPhone(e.target.value);
                break;
        }
        //console.log(e.target.name);
        
    }
    const handle_EditUser = (event, fname, lname, userName, emailAddress) => {
        setEditFName(fname);
        setEditLName(lname);
        setEditUserName(userName);
        setEditEmail(emailAddress);
        setFIQAddUserModalOpen(true);
    }
    const handle_NotificationTypeChange = (e) => {
        updateCartNotificationType(e.target.value);
        switch (e.target.value) {
            case "AccountManager":
                updateCartContactFName(cartAccountMgrFName);
                updateCartContactLName(cartAccountMgrLName);
                updateCartContactEmail(cartAccountMgrEmail);
                setDisabledFlag(true);
                break;
            case "Customer":
                updateCartContactFName("");
                updateCartContactLName("");
                updateCartContactEmail("");
                setDisabledFlag(false);
                break;
            case "Other":
                updateCartContactFName("");
                updateCartContactLName("");
                updateCartContactEmail("");
                setDisabledFlag(false);
                break;
        }
       
        
    };
    const setCurrentOracleID = (oracleID) =>{
        setCurrentOracleCustomerID(oracleID);
        setvalidationErrorMsg("");
        //console.log(props.bearerToken);

        //Grab the values from the db and set the rest of the values on the form. 
        var endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
      
        const body = {
            SearchQuery: oracleID
        };
        updateWaitModal(true);
        try {
            
            const serviceUrl = endPointURL + 'api/NetSuite/GetNSCustomerDetails';
            axios.post(serviceUrl, body)
                .then((response) => {
                     if (response.status === 200) {
                        setCurrentCompanyName(response.data.name);
                        setCurrentBillingAddress(netsuiteService.buildHTMLAddress(response.data.billtoaddressee, response.data.billtoaddress1, response.data.billtoaddress2, response.data.billtocity, response.data.billtostate, response.data.billtozip, response.data.billtocountry));
                        setCurrentShippingAddress(netsuiteService.buildHTMLAddress(response.data.shiptoaddressee, response.data.shiptoaddress1, response.data.shiptoaddress2, response.data.shiptocity, response.data.shiptostate, response.data.shiptozip, response.data.shiptocountry));
                      
                    }
               
                })
                .catch((error) => {
                    console.log('Error:', error);
                    updateWaitModal(false);

                });
            updateWaitModal(false);



        }
        catch (error) {
            updateWaitModal(false);
            return error;
        }

    };
   

    

    const load_CurrentCart = async () => {
        if (!cartLoaded && cartMode == "manage") {
            let cartID = await props.currentCart;
            //console.log(cartID);
            var endPointURL = process.env.REACT_APP_API_URL;
            if (process.env.REACT_APP_API_URL === undefined) {
                endPointURL = "/";
            }
            const FIQCartInfo = {
                cartID: encodeURI(cartID)
            };


            try {
                const serviceUrl = endPointURL + 'api/ShoppingCart/GetUserShoppingCart';
                axios.post(serviceUrl, FIQCartInfo)

                    .then(response => {
                        
                        //console.log(response);
                        if (response.status === 200) {
                            setCartIsLoaded(true);
                            updateCardHeaderTitle("Cart Details");
                            updateCartStatus(response.data.status);
                            updateCanEditCart(response.data.canEditCart);
                            updateCanArchiveCart(response.data.canArchiveCart);
                            if (!response.data.canEditCart) {
                                setDisabledFlag(true);
                                //console.log("IsDisabled");
                            }
                            console.log(response.data);
                            //Cart variables being set here.
                            updateCartTotal(response.data.total);
                            updateCartSubTotal(response.data.subTotal);
                            updateCartDiscount(response.data.discount);
                            updateCartNotificationType(response.data.cartNotificationType);
                            updateCartAccountMgrFName(response.data.accountManagerFName);
                            updateCartAccountMgrLName(response.data.accountManagerLName);
                            updateCartAccountMgrEmail(response.data.accountManagerEmail);


                            if (response.data.cartNotificationType === "AccountManager") {
                                updateCartContactFName(response.data.accountManagerFName);
                                updateCartContactLName(response.data.accountManagerLName);
                                updateCartContactEmail(response.data.accountManagerEmail);
                                setDisabledFlag(true);
                            }
                            else if (response.data.cartNotificationType === "Customer") {
                                updateCartContactFName(response.data.soldToFirstName);
                                updateCartContactLName(response.data.soldToLastName);
                                updateCartContactEmail(response.data.soldToEmail);
                                setDisabledFlag(false);
                            }
                            else if (response.data.cartNotificationType === "Other") {
                                updateCartContactFName(response.data.notificationFirstName);
                                updateCartContactLName(response.data.notificationLastName);
                                updateCartContactEmail(response.data.notificationEmail);
                                setDisabledFlag(false);
                            }

                            updateCanSendCartLink(response.data.canSendCartLink);
                            
                            if (response.data.shoppingCartLink != null) {
                                setDisabledFlag(true);
                                updateCartNotificationOption(true);
                            }
                            if (!!response.data.shoppingCartLink) { updateSendButtonText("Resend Link"); }
                            updateCreateDate(new Date(response.data.created).toLocaleDateString('en-US'));
                            //updateCartContactFName(response.data.soldToFirstName);
                            //updateCartContactLName(response.data.soldToLastName);
                            //updateCartContactEmail(response.data.soldToEmail);
                            updateCartContactPhone(response.data.soldToPhone);
                            updateCartLink(response.data.shoppingCartLink);

                            console.log(cartContactEmail);
                          


                            updateUnitPrice(response.data.cartLines[0].unitPrice);
                        

                            updateDiscountAmount(response.data.cartLines[0].discount);
                            if (!!response.data.expirationDate) { updateLinkExpDate(new Date(response.data.expirationDate).toLocaleDateString('en-US')) } else { updateLinkExpDate("N/A") };
                            ///console.log(response.data);
                            updateCurrentCartID(response.data.cartID);
                            setCurrentOracleID(response.data.oracleID);
                            //console.log(response.data.cartLines);
                            updateShoppingCartLines(response.data.cartLines);
                            let parser = new DOMParser();
                            //reset the array to null;
                            //addToPlanUserArray((PlanUsers) => []);

                            for (var x = 0; x < response.data.cartLines.length; x++) {
                               // console.log(response.data.cartLines[x]);
                                let extraInfo = parser.parseFromString(response.data.cartLines[x].extraInfo, "text/xml");
                                let productInfo = extraInfo.getElementsByTagName("ProductItem");
                               // console.log(productInfo[0].hasAttribute("productType"));

                                for (var p = 0; p < productInfo.length; p++) {
                                    if (productInfo[p].getElementsByTagName("productType").length > 0) {
                                        if (productInfo[p].getElementsByTagName("productType")[0].textContent === 'Plan') {
                                            setPlanItemInCart(true);
                                            console.log("FOUND PRODUCT TYPE OF PLAN");
                                        }
                                    }
                                }
                                let planUsers = extraInfo.getElementsByTagName("PlanUsers");
                                if (planUsers.length > 0) {
                                    console.log("reloading Plan Users");
                                    for (var i = 0; i < planUsers[0].children.length; i++) {
                                        console.log(planUsers[0].children[i]);
                                        if (planUsers[0].children[i].nodeType == 1 && planUsers[0].children[i].hasAttribute("FName") &&
                                            planUsers[0].children[i].hasAttribute("UserName") && planUsers[0].children[i].hasAttribute("Email")) {

                                            if (planUsers[0].children[i].getAttribute("FName").startsWith('User') ||
                                                planUsers[0].children[i].getAttribute("UserName").startsWith('UserName') ||
                                                planUsers[0].children[i].getAttribute("Email").endsWith('@changeme.com')) {
                                                updatePlanUsersSetup(false);
                                            }
                                            loadPlanUser(planUsers[0].children[i].getAttribute("Email"), planUsers[0].children[i].getAttribute("FName"), planUsers[0].children[i].getAttribute("LName"), planUsers[0].children[i].getAttribute("UserName"), planUsers[0].children[i].getAttribute("Email"));
                                            //console.log("Loaded Plan users");
                                        }
                                        // console.log(FIQUsers);

                                    }
                                }
                                

                            }

                           // console.log("PlanUsers");
                            //console.log(PlanUsers);
                          
                            setLoading(false);
                            //console.log(linkExpirationDate);
                        }


                    })

                    .catch(error => {
                        console.log(error);
                        setvalidationErrorMsg(error.message);
                        setLoading(false);
                    });





            }
            catch (error) {
                //return error;
                setvalidationErrorMsg(commonfunctions.handleError(error.status, error));
                updateWaitModal(false);
            }
        }
        else {
            //we are here because are are adding a new cart.
            updateCanEditCart(true);
        }

    };


    useEffect(() => {
         load_CurrentCart();
    }, [cartLoaded]);

    const Load_PlanUsersControl = (cartPlanUsers) => {
        const clonePlanUsers = [...PlanUsers];
        console.log(clonePlanUsers);
        return (
            <>
                {clonePlanUsers.map(PlanUser => {
                    return (<Grid item xs={12} sm={12} md={3} lg={3} key={PlanUser.email}  >
                        <Item sx={{ textAlign: 'center' }}> <AccountCircleIcon color="primary" aria-label={PlanUser.fname + ' ' + PlanUser.lname} sx={{ height: 80, width: 60 }} >
                        </AccountCircleIcon>
                            <p><font className="UWA_FIQLabel"><b>{PlanUser.fname + ' ' + PlanUser.lname}</b></font></p>
                            <p><font className="UWA_FIQLabel">{PlanUser.userName}</font><br />
                                <font className="UWA_FIQLabel">{PlanUser.email}</font></p>
                            <p className="UWA_FIQActions">

                                {canEditCart && <><IconButton tooltip="Edit User" aria-label="edit user" onClick={(e) => handle_EditUser(e, PlanUser.fname, PlanUser.lname, PlanUser.userName, PlanUser.email)}>
                                    <EditIcon className="UWA_FIQAction_EditButton" />
                                </IconButton>
                                    <IconButton tooltip="Delete User" aria-label="delete user" onClick={(e) => handle_DeleteUser(e, PlanUser.email)}>
                                        <DeleteForeverIcon className="UWA_FIQAction_DeleteButton" />
                                    </IconButton></>}
                            </p>
                        </Item>
                    </Grid>);
                     
                })}
            </>
        );
        //return (<>
           
        //     PlanUsers.map(PlanUser => (
        //    <Grid item xs={12} sm={12} md={3} lg={3} key={PlanUser.email}  >
        //        <Item sx={{ textAlign: 'center' }}> <AccountCircleIcon color="primary" aria-label={PlanUser.fname + ' ' + PlanUser.lname} sx={{ height: 80, width: 60 }} >

        //        </AccountCircleIcon>
        //            <p><font className="UWA_FIQLabel"><b>{PlanUser.fname + ' ' + PlanUser.lname}</b></font></p>
        //            <p><font className="UWA_FIQLabel">{PlanUser.userName}</font><br />
        //                <font className="UWA_FIQLabel">{PlanUser.email}</font></p>
        //            <p className="UWA_FIQActions">

        //                {canEditCart && <><IconButton tooltip="Edit User" aria-label="edit user" onClick={(e) => handle_EditUser(e, PlanUser.fname, PlanUser.lname, PlanUser.userName, PlanUser.email)}>
        //                    <EditIcon className="UWA_FIQAction_EditButton" />
        //                </IconButton>
        //                    <IconButton tooltip="Delete User" aria-label="delete user" onClick={(e) => handle_DeleteUser(e, PlanUser.email)}>
        //                        <DeleteForeverIcon className="UWA_FIQAction_DeleteButton" />
        //                    </IconButton></>}
        //            </p>
        //        </Item>

        //    </Grid>);
        //))
        //</>);
        
    }
   
    
    const setTierUnitPrice  = (userCount) => {
        for (const tier of FIQPricingLegend)
        {
            let lowerBound = tier.startingUnit;
            let upperBound = tier.endingUnit;
        

            if (userCount >= lowerBound && userCount <= upperBound) {
                updateUnitPrice(tier.price);
                //getDiscountAmount(couponCode);
                break;
            }

        }
       
    }
    const validateCouponCode = async () => {
        if (!!couponCode)
        {
            var endPointURL = process.env.REACT_APP_API_URL;
            if (process.env.REACT_APP_API_URL === undefined) {
                endPointURL = "/";
            }
            //const requestOptions = {
            //    method: 'GET',
            //    headers: { 'Content-Type': 'application/json' }
            //};
            try {
                const serviceUrl = endPointURL + "api/ChargebeeWidgets/ValidateCouponCode/" + couponCode;
                await axios.get(serviceUrl)
                    .then((response) => {
                        if (response.status === 200) {
                            saveCouponCode(couponCode);
                            getDiscountAmount(couponCode);
                            updateCartCouponCode();
                        }
                    })
                    .catch((error) => {
                       updateCouponValidationMsg(error.response.data.message);
                        //updateCouponValidationMsg("Invalid Coupon");
                    })
                
            }
            catch (error) {
                updateCouponValidationMsg(error.message);
            }
           

        }
        else {
            updateCouponValidationMsg("You must enter a coupon to validate it");
        }
    }
    const returnToDashboard = () => {
        window.location.href = '/Admin/ManageShoppingCarts';
    }
    const validateContactFields = () => {
        var success = true;
        updateContactFNameErrMsg("");
        updateContactLNameErrMsg("");
        updateContactEmailErrMsg("");
        updateContactPhoneErrMsg("");
        if (!(cartContactFName !== undefined && cartContactFName !== '' && /^[a-zA-Z '.-]*$/.test(cartContactFName) && cartContactFName?.length)) {
            updateContactFNameErrMsg("First Name is not valid");
            success  = false;
        }
        

        if (!(cartContactLName !== undefined && cartContactLName !== '' && /^[a-zA-Z '.-]*$/.test(cartContactLName) && cartContactLName?.length)) {
            updateContactLNameErrMsg("Last Name is not valid");
            success = false;
        }
        if (!(cartContactEmail !== undefined && cartContactEmail !== '' && /^\w+([\.\+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(cartContactEmail))) {
            updateContactEmailErrMsg("Email is not valid");
            success = false;
        }
        if (cartNotificationType != "AccountManager") {
            if (!(cartContactPhone !== undefined && cartContactPhone !== '' && /^[+]?[\s./0-9]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/g.test(cartContactPhone))) {
                updateContactPhoneErrMsg("Phone is not valid");
                success = false;
            }
        }
        if (!planUsersSetupComplete) {
            setUpdateCartValidationErrorMsg("You must specify the users on the plan and replace the generic values currently entered by the system.");
            success = false;
        }
        return success;
    }
    
    const updateShoppingCart = () => {
        if (validateContactFields())
        {
            var endPointURL = process.env.REACT_APP_API_URL;
            if (process.env.REACT_APP_API_URL === undefined) {
                endPointURL = "/";
            }
            const FIQCartInfo = {
                contactFirstName: cartContactFName,
                contactLastName: cartContactLName,
                contactEmail: cartContactEmail,
                contactPhone: cartContactPhone,
                cartNotificationType: cartNotificationType,
                cartID: encodeURI(props.currentCart)
            };
            try {
                const serviceUrl = endPointURL + 'api/ShoppingCart/UpdateUserCartContactInfo';
                axios.post(serviceUrl, FIQCartInfo)
                    .then(response => {
                        //console.log(response);
                        if (response.status === 200) {
                            setCartIsLoaded(false);
                            //load_CurrentCart();
                            setCartUpdateSuccessMsg("Cart updated successfully!");
                            setTimeout(() => {
                                setCartUpdateSuccessMsg("");
                            }, 8000);
                            //window.location.href = '/MyDashboard/ManageFIQCart?CartID=' + decodeURI(props.currentCart);
                        }


                    })

                    .catch(error => {
                        console.log(error);
                        setUpdateCartValidationErrorMsg(error.response.data.detail);

                    });
            }
            catch (error) {
                setUpdateCartValidationErrorMsg(error.message);
                updateWaitModal(false);
            }
        }
    }
    const updateCartCouponCode = () => {
        var endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
        const FIQCartInfo = {
            contactFirstName: cartContactFName,
            contactLastName: cartContactLName,
            contactEmail: cartContactEmail,
            contactPhone: cartContactPhone,
            cartID: encodeURI(props.currentCart),
            discountCode: couponCode,
        };
        try {
            const serviceUrl = endPointURL + 'api/ShoppingCart/UpdateCouponCodeFIQCart';
            axios.post(serviceUrl, FIQCartInfo)
                .then(response => {
                    //console.log(response);
                    if (response.status === 200) {
                        //load_CurrentCart();
                        //updateCouponValidationMsg("Cart updated successfully!");
                        //setTimeout(() => {
                        //    setCartUpdateSuccessMsg("");
                        //}, 8000);
                        //window.location.href = '/MyDashboard/ManageFIQCart?CartID=' + decodeURI(props.currentCart);
                    }


                })

                .catch(error => {
                    console.log(error);
                    updateCouponValidationMsg(error.response.data.detail);

                });
        }
        catch (error) {
            setUpdateCartValidationErrorMsg(error.message);
            updateWaitModal(false);
        }

    }
    const saveShoppingCart = ()  => {
        var isValid = true;
        if(!!oracleCustomerID) {
            isValid = true;
        }
        else
        {
            isValid = false;
            setvalidationErrorMsg("Please select a customer for this shopping cart.");
        }
        if (PlanUsers.length === 0) {
            isValid = false;
            setvalidationErrorMsg("You must enter information for at least one user.");


        }

        if (isValid) {
            var endPointURL = process.env.REACT_APP_API_URL;
            if (process.env.REACT_APP_API_URL === undefined) {
                endPointURL = "/";
            }
            var tempUsersList = [];
            for (var i = 0; i < PlanUsers.length; i++) {
                const FIQUserInfo = {
                    cartID: "",
                    name: PlanUsers[i].name,
                    userName: PlanUsers[i].userName,
                    email: PlanUsers[i].email
                };
                tempUsersList.push(FIQUserInfo);
            }
            const FIQCartInfo = {
                customerID: oracleCustomerID,
                subscribers: tempUsersList,
                CartLines: [{
                    productID: SubscriptionProductID,
                    quantity: PlanUsers.length,
                    unitprice: unitPrice,
                    discountCode: couponCode,
                    discountAmount: discountAmount
                }]
            };
           
          
            try {
                updateWaitModal(true);
                const serviceUrl = endPointURL + 'api/ShoppingCart/CreateFIQCart';
                axios.post(serviceUrl, FIQCartInfo)
                    .then(response => {
                        //console.log(response);
                        if (response.status === 200) {
                            window.location.href = '/Admin/ManageFIQCart?CartID=' + response.data.cartID;
                        }


                    })

                    .catch(error => {
                        console.log(error);
                        setvalidationErrorMsg(error.response.data.detail);
                        updateWaitModal(false);
                       
                    });

                    




            }
            catch (error) {
                console.log(error);
                setvalidationErrorMsg(error.message);
                updateWaitModal(false);
            }
        }
        return isValid;
    }
    const archiveShoppingCart = () => {
        if (window.confirm('Are you sure you want to archive this shopping cart?')) {
            var endPointURL = process.env.REACT_APP_API_URL;
            if (process.env.REACT_APP_API_URL === undefined) {
                endPointURL = "/";
            }
            const FIQCartInfo = {
                cartID: encodeURI(props.currentCart)
            };
            try {
                const serviceUrl = endPointURL + 'api/ShoppingCart/ArchiveFIQCart';
                axios.post(serviceUrl, FIQCartInfo)
                    .then(response => {
                        //console.log(response);
                        if (response.status === 200) {
                            returnToDashboard();
                        }


                    })

                    .catch(error => {
                        console.log(error);
                        setUpdateCartValidationErrorMsg(error.response.data.detail);

                    });
            }
            catch (error) {
                setUpdateCartValidationErrorMsg(error.message);
                updateWaitModal(false);
            }
        } else {
            // User cancelled the delete operation.

        }
    }
    
    const sendShoppingCartLink = () => {
        var endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
        const FIQCartInfo = {
            cartID: encodeURI(props.currentCart)
        };
        try {
            updateWaitModal(true);
            const serviceUrl = endPointURL + 'api/ShoppingCart/ResendUserCartLink';
            axios.post(serviceUrl, FIQCartInfo)
                .then(response => {
                    //console.log(response);
                    if (response.status === 200) {
                        load_CurrentCart();
                        setCartUpdateSuccessMsg("Email sent successfully to Customer!");
                        setTimeout(() => {
                            setCartUpdateSuccessMsg("");
                        }, 8000);
                    }


                })

                .catch(error => {
                    console.log(error);
                    setUpdateCartValidationErrorMsg(error.response.data.detail);

                });
            updateWaitModal(false);
        }
        catch (error) {
            setUpdateCartValidationErrorMsg(error.message);
            updateWaitModal(false);
        }
        
    }
    const getDiscountAmount = () => {

        if (!!couponCode) {
            var endPointURL = process.env.REACT_APP_API_URL;
            if (process.env.REACT_APP_API_URL === undefined) {
                endPointURL = "/";
            }
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' }
            };
            //const cartAmount = unitPrice * FIQUsers.length;
            try {
                const serviceUrl = endPointURL + "api/ChargebeeWidgets/GetCouponDiscountAmount/" + couponCode + "/" + PlanUsers.length + "/" + SubscriptionProductID;
                fetch(serviceUrl, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (!!data.discountAmount) {
                            updateDiscountAmount(data.discountAmount);
                        }
                        else {
                            saveCouponCode("");
                            updateCouponValidationMsg(data.message);
                        }
                        //console.log(data);
                    })
                    .catch((error) => {
                        saveCouponCode("");
                        updateCouponValidationMsg(error.message);
                    });
            }
            catch (error) {
                saveCouponCode("");
                updateCouponValidationMsg(error.message);
            }


        }
       

    }
    const modifyFIQCartUser = async (operation, fname, lname, userName, email, originalEmail) => {
        var endPointURL = process.env.REACT_APP_API_URL;
        
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
        const FIQCartInfo = {
            cartID: encodeURI(props.currentCart),
            fname: fname,
            lname: lname,
            userName: userName, 
            email: email,
            originalEmail: originalEmail
        };
        try {
            updateWaitModal(true);
            let endPoint = '';
            switch (operation) {
                case "Add":
                    endPoint = 'api/ShoppingCart/AddUserToFIQCart';
                    break;
                case "Edit":
                    endPoint = 'api/ShoppingCart/EditUserFIQCart';
                    break;
                case "Delete":
                    endPoint = 'api/ShoppingCart/DeleteUserFromFIQCart';
                    break;
            }
            const serviceUrl = endPointURL + endPoint;
            axios.post(serviceUrl, FIQCartInfo)
                .then(response => {
                    //console.log(response);
                    console.log("after calling " + endPoint + ", response.status = " + response.status);
                    if (response.status === 200) {
                        setCartIsLoaded(false);
                        //load_CurrentCart();
                        return true;
                       
                    }


                })

                .catch(error => {
                    console.log(error);
                    setuserValidationErrorMsg(error.response.data.detail);
                    return false;

                });
            updateWaitModal(false);
        }
        catch (error) {
            setuserValidationErrorMsg(error.message);
            updateWaitModal(false);
            return false;
        }
    };

    function handleCouponCodeChange(e) {
        updateCouponCode(e.target.value);

    }
    function clearSavedCouponCode() {
        saveCouponCode("");
        updateCouponCode("");
        updateCartCouponCode();
        updateDiscountAmount(0);
    }

   const loadPlanUser = async(originalEmail, fname, lname, userName, email) =>{
       let itemExists = false;
       var clonedPlanUsers = [...PlanUsers];
       //Reset the array to null
       //Lets make sure the email and username does not already exist
       if (clonedPlanUsers.length > 0) {
           for (let i = 0; i < clonedPlanUsers.length; i++) {
               //console.log(FIQUsers[i].name);
               if (clonedPlanUsers[i].userName.toLowerCase() === userName.toLowerCase() ||
                   clonedPlanUsers[i].email.toLowerCase() === email.toLowerCase()) {
                   //We can't add to the array since it already exists. Let the user know.
                   itemExists = true;
                   break;
               }
           };
        }
       if (!itemExists && (!!fname) && (!!lname) && (!!userName) && (!!email)) {
            updateWaitModal(true);
           setvalidationErrorMsg("");
           //clonedPlanUsers.push({ originalEmail, fname, lname, userName, email });
           addToPlanUserArray((PlanUsers) => [...PlanUsers, { originalEmail, fname, lname, userName, email }]);
            setEditFName("");
            setEditLName("");
            setEditUserName("");
            setEditEmail("");
            updateWaitModal(false);
            setFIQAddUserModalOpen(false);
       }
       //addToPlanUserArray([...clonedPlanUsers]);

    
        return !itemExists;
    };

    const updatePlanUserDetails = async (originalEmail, fname, lname, userName, email) => {
        let itemExists = false;
        if (!!originalEmail) {
            //We are editing an existing user.
            let tempFIQUsers = [...PlanUsers];
            if (tempFIQUsers.length > 0) {
                console.log(tempFIQUsers);
                //Lets make sure what we're trying to change does not already exist in the list of users. 
                const founduserName = tempFIQUsers.some(el => (el.userName.toLowerCase() === userName.toLowerCase() && el.email != originalEmail));
                if (founduserName) {
                    itemExists = true;
                }
                else {
                    for (let i = 0; i < tempFIQUsers.length; i++) {
                        if (tempFIQUsers[i].email.toLowerCase() === originalEmail.toLowerCase()) {
                            tempFIQUsers.splice(i, 1);
                            tempFIQUsers.splice(i, 0, { originalEmail, fname, lname, userName, email });
                            if (cartLoaded) {
                                await modifyFIQCartUser("Edit", fname, lname, userName, email, originalEmail);
                            }
                        }

                    };
                    addToPlanUserArray(tempFIQUsers);
                    updatePlanUsersSetup(true);
                    for (var i = 0; i < tempFIQUsers.length; i++) {
                        if (tempFIQUsers[i].fname && tempFIQUsers[i].userName && tempFIQUsers[i].email) {
                            if (tempFIQUsers[i].fname.startsWith('User') || tempFIQUsers[i].userName.startsWith('Username') || tempFIQUsers[i].email.endsWith('@changeme.com')) {
                                updatePlanUsersSetup(false);
                            }
                        }
                    }
                }

            }
        }
        else {
            //Lets make sure the email and username does not already exist
            if (PlanUsers.length > 0) {
                for (let i = 0; i < PlanUsers.length; i++) {
                    //console.log(FIQUsers[i].name);
                    if (PlanUsers[i].userName.toLowerCase() === userName.toLowerCase() || PlanUsers[i].email.toLowerCase() === email.toLowerCase()) {
                        //We can't add to the array since it already exists. Let the user know.
                        itemExists = true;
                    }


                };
            }
            if (!itemExists && (!!fname) && (!!lname) && (!!userName) && (!!email)) {
                updateWaitModal(true);
                setvalidationErrorMsg("");
                await addToPlanUserArray((PlanUsers) => [...PlanUsers, { originalEmail, fname, lname, userName, email }]);
                if (cartLoaded) {
                    await modifyFIQCartUser("Add", fname, lname, userName, email, null);
                }
                //setTierUnitPrice(FIQUsers.length+1);
                //Reset the values being passed back to Modal
                setEditFName("");
                setEditLName("");
                setEditUserName("");
                setEditEmail("");
                updateWaitModal(false);
                setFIQAddUserModalOpen(false);
            }
        }

        return !itemExists;
    }
    //async function addFIQUser(originalEmail, fname, lname, userName, email) {
    //    let itemExists = false;
    //    if (!!originalEmail) {
    //        //We are editing an existing user.
    //        let tempFIQUsers = [...FIQUsers];
    //        if (tempFIQUsers.length > 0) {
    //            console.log(tempFIQUsers);
    //            //Lets make sure what we're trying to change does not already exist in the list of users. 
    //            const founduserName = tempFIQUsers.some(el => (el.userName.toLowerCase() === userName.toLowerCase() && el.email != originalEmail));
    //            if(founduserName)
    //            {
    //                itemExists = true;
    //            }
    //            else {
    //                for (let i = 0; i < tempFIQUsers.length; i++) {
    //                    if (tempFIQUsers[i].email.toLowerCase() === originalEmail.toLowerCase()) {
    //                        tempFIQUsers.splice(i, 1);
    //                        tempFIQUsers.splice(i, 0, { fname, lname, userName, email });
    //                        if (cartLoaded) {
    //                            await modifyFIQCartUser("Edit", fname, lname, userName, email);
    //                        }
    //                    }

    //                };
    //                addToFIQUserArray(tempFIQUsers);
    //            }
               
    //        }
    //    }
    //    else {
    //        //Lets make sure the email and username does not already exist
    //        if (FIQUsers.length > 0) {
    //            for (let i = 0; i < FIQUsers.length; i++) {
    //                //console.log(FIQUsers[i].name);
    //                if (FIQUsers[i].userName.toLowerCase() === userName.toLowerCase() || FIQUsers[i].email.toLowerCase() === email.toLowerCase()) {
    //                    //We can't add to the array since it already exists. Let the user know.
    //                    itemExists = true;
    //                }


    //            };
    //        }
    //        if (!itemExists && (!!fname) && (!!lname) && (!!userName) && (!!email)) {
    //            updateWaitModal(true);
    //            setvalidationErrorMsg("");
    //            await addToFIQUserArray((FIQUsers) => [...FIQUsers, { fname, lname, userName, email }]);
    //            if (cartLoaded) {
    //                await modifyFIQCartUser("Add", fname, lname, userName, email);
    //            }
    //            //setTierUnitPrice(FIQUsers.length+1);
    //            //Reset the values being passed back to Modal
    //            setEditFName("");
    //            setEditLName("");
    //            setEditUserName("");
    //            setEditEmail("");
    //            updateWaitModal(false);
    //            setFIQAddUserModalOpen(false);
    //        }
    //    }
     
    //    return !itemExists;
    // };
    
  
    return (
        <Container>
             
            <Row >
                <Col>
                    <Card >
                        <CardHeader title={CardHeaderTitle} className="UWA_FIQCardTitle"
                            action={
                                <IconButton
                                    disabled= {!canEditCart}
                                    tooltip="Search for Customer"
                                    aria-label="settings"
                                    onClick={(e) => { handleClickOpenCustomerBrowse(e) }}
                                    sx={{ color: yellow[50] }}>
                                    <SearchIcon  />
                                </IconButton>
                            }>
                        </CardHeader>
                        {showWaitModal ? (
                            <ModalWait handleResponse={updateWaitModal}
                            />
                        ) : null}
                               
                        <CardContent className="UWA_CustomerCardContent">
                          
                            {oracleCustomerID?.length === 0 && cartMode === "create" ? (<font className='UWA_FIQCartInstructions'>Click on the search icon to assign a customer for this subscription. Please note that the customer must be an existing customer in NetSuite.</font>)
                                : (
                                    <Grid container spacing={2}>
                                        {cartMode === "manage" ? (
                                           <> 
                                                <Grid item xs={12} sm={4}>
                                                    <Item> {loading ? (<Skeleton variant="rectangular" />) : (<font className="UWA_FIQLabel"><b>Name&nbsp;</b>{companyName}</font>)}</Item>
                                               </Grid>
                                           
                                                <Grid item xs={12} sm={4}>
                                                    <Item>{loading ? (<Skeleton variant="rectangular" />) : (<font className="UWA_FIQLabel"><b>Customer ID&nbsp;</b> {oracleCustomerID}</font>)}</Item>
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <Item>{loading ? (<Skeleton variant="rectangular" />) : (<font className="UWA_FIQLabel"><b>Cart Status&nbsp;</b> {cartStatus}</font>)}</Item>
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <Item>{loading ? (<Skeleton variant="rectangular" sx={{ fontSize: '6rem' }}/>) : (<font className="UWA_FIQLabel"><b>Billing Address&nbsp;</b><p dangerouslySetInnerHTML={{ __html: billingAddress }}></p></font>)} </Item>
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <Item>{loading ? (<Skeleton variant="rectangular" sx={{ fontSize: '6rem' }}/>) : (<font className="UWA_FIQLabel"><b>Shipping Address&nbsp;</b><p dangerouslySetInnerHTML={{ __html: shippingAddress }}></p></font>)} </Item>
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12}><Item>{loading ? (<Skeleton variant="rectangular" />) : (<font className="UWA_FIQLabel"><b>Create Date</b> {createDate}</font>)}</Item></Grid>
                                                        <Grid item xs={12}><Item>{loading ? (<Skeleton variant="rectangular" />) : (<font className="UWA_FIQLabel"><b>Cart Expiration Date</b> {linkExpirationDate}</font>)}</Item></Grid>
                                                        <Grid item xs={12}><Item>{loading ? (<Skeleton variant="rectangular" />) : (<font className="UWA_FIQLabel"><b>Cart Link&nbsp;</b> <a href={cartLink} target="_new">Cart Link Preview</a> </font>)}</Item></Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    {loading ? (<Skeleton variant="rectangular" sx={{ fontSize: '6rem' }}/>) :(<><Divider role="presentation" sx={{ marginBottom: '8px'}}> <Chip label="Cart Contact Info" /></Divider>
                                                        <Stack direction={{ xs: 'row', sm: 'row' }}>
                                                            <RadioGroup
                                                                aria-labelledby="cartNotificationType"
                                                                name="cartNotificationType"
                                                                defaultValue={cartNotificationType}
                                                                onChange={handle_NotificationTypeChange}
                                                                
                                                                row>
                                                                <FormControlLabel value={"AccountManager"} control={<Radio inputProps={{ 'title': 'Account Manager' }} />} label="Account Manager" key={0} disabled={cartNotificationOptionEnabled} />
                                                                <FormControlLabel value={"Customer"} control={<Radio inputProps={{ 'title': 'Customer' }} />} label="Customer" key={1} disabled={cartNotificationOptionEnabled} />
                                                                <FormControlLabel value={"Other"} control={<Radio inputProps={{ 'title': 'Other' }} />} label="Other" key={2} disabled={cartNotificationOptionEnabled} />
                                                            </RadioGroup>
                                                        </Stack>
                                                        <Stack direction={{ xs: 'column', sm: 'row' }}>
                                               
                                                        <TextField style={{ width: '90%', margin: '5px' }}
                                                            name="ContactFName"
                                                            label="First Name"
                                                            size="small"
                                                            required
                                                                onChange={(handle_ContactFieldChange) => updateCartContactFName(handle_ContactFieldChange.target.value)}
                                                            value={cartContactFName}
                                                            helperText={errContactFNameMsg}
                                                            disabled={disabled}
                                                        />
                                                        <TextField style={{ width: '90%', margin: '5px' }}
                                                            name="ContactLName"
                                                            label="Last Name"
                                                            size="small"
                                                            required
                                                                onChange={(handle_ContactFieldChange) => updateCartContactLName(handle_ContactFieldChange.target.value)}
                                                            value={cartContactLName}
                                                            helperText={errContactLNameMsg}
                                                            disabled={disabled}
                                                        />
                                                        <TextField style={{ width: '90%', margin: '5px' }}
                                                            name="ContactEmail"
                                                            label="Email"
                                                            size="small"
                                                            required
                                                            onChange={(handle_ContactFieldChange) => updateCartContactEmail(handle_ContactFieldChange.target.value)}
                                                            helperText={errContactEmailMsg}
                                                            value={cartContactEmail}
                                                            disabled={disabled}
                                                        />
                                                        <TextField style={{ width: '90%', margin: '5px' }}
                                                            name="ContactPhone"
                                                            label="Phone"
                                                            size="small"
                                                            required
                                                            onChange={handle_ContactFieldChange}
                                                            defaultValue={cartContactPhone}
                                                            helperText={errContactPhoneMsg}
                                                            disabled={disabled}
                                                        />
                                                        </Stack>

                                                    </>)}
                                                </Grid>
                                            </>
                                        ) : (
                                                <> <Grid item xs={12} sm={8}>
                                                   <Item><font className="UWA_FIQLabel"><b>Company Name&nbsp;</b> {companyName}</font></Item>
                                                </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                <Item><font className="UWA_FIQLabel"><b>Oracle ID&nbsp;</b> {oracleCustomerID}</font></Item>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <Item><font className="UWA_FIQLabel"><b>Billing Address&nbsp;</b><p dangerouslySetInnerHTML={{ __html: billingAddress }}></p></font> </Item>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <Item><font className="UWA_FIQLabel"><b>Shipping Address&nbsp;</b><p dangerouslySetInnerHTML={{ __html: shippingAddress }}></p></font> </Item>
                                                    </Grid>
                                                </>

                                        )}
                                      
                                       
                                       
                                    </Grid>
                                )}
                            </CardContent>
                        <CardActions>
                            {(currentCartID === "") ?
                                (<><span className="UWA_FIQCartActions"></span>
                                </>
                                )
                                : (<> <Grid container spacing={2}>
                                      
                                        <Grid item xs={7} sm={7} md={7}>
                                        <Button size="small" className="UWA_btn_cancelFIQcart" onClick={()=>returnToDashboard()}>DASHBOARD</Button>
                                        {canArchiveCart && <Button size="small" className="UWA_btn_deleteFIQcart" onClick={() => archiveShoppingCart()}>ARCHIVE</Button>}
                                          </Grid>
                                        <Grid item xs={5} sm={5} container  justifyContent="flex-end">
                                       
                                        {canEditCart && <Button size="small" className="UWA_btn_createFIQcart" onClick={() => updateShoppingCart()} >SAVE/GET LINK</Button>}
                                        {canSendCartLink && <Button size="small" className="UWA_btn_sendFIQcartLink" onClick={() => sendShoppingCartLink()} startIcon={<SendIcon />}>{sendButtonText}</Button>}
                                        {!canSendCartLink && <Button size="small" className="UWA_btn_sendFIQcartLinkDisabled" startIcon={<SendIcon />} disabled>{sendButtonText}</Button>}
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                        {shoppingCartUpdateValidationErrMsgs && <Alert severity="error"><>{shoppingCartUpdateValidationErrMsgs}</></Alert>}
                                        {shoppingCartUpdateMsgs && <Alert severity="success"><>{shoppingCartUpdateMsgs}</></Alert>}
                                        </Grid>
                                      </Grid>
                                    </>
                                )}
                        </CardActions>
                        <NetSuiteCustomerBrowse open={dialogIsOpen} onClose={closeDialog} selectedOracleCustomerID={setCurrentOracleID} />
                    </Card>
                </Col>
            </Row>
           
            <Row>
                <Col>
                    <Card className="mx-auto my-4">
                        <CardHeader title="Cart Contents" className="UWA_FIQCardTitle" >

                        </CardHeader>
                        <CardContent>
                            {loading ? (<Skeleton variant="rectangular" sx={{ fontSize: '6rem' }} />)
                                : (<>
                                    <Grid container spacing={2} className="UWA_SubscriptionCart">
                                        <Grid container spacing={2} className="UWA_FIQCartHeader">
                                            <Grid item xs={4} align="left">
                                                <b>Item</b>
                                            </Grid>
                                            <Grid item xs={1} align="center">
                                                <b>Product</b>
                                            </Grid>
                                            <Grid item xs={1} align="right">
                                                <b>Quantity</b>
                                            </Grid>

                                            <Grid item xs={2} align="left">
                                                <b>Discount Type</b>
                                            </Grid>
                                            <Grid item xs={1} align="right">
                                                <b>MSRP</b>
                                            </Grid>
                                            <Grid item xs={1} align="right">
                                                <b>Unit Price</b>
                                            </Grid>
                                            <Grid item xs={1} align="right">
                                                <b>Discounts</b>
                                            </Grid>
                                            <Grid item xs={1} align="right">
                                                <b> Sub Total</b>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2}>

                                            {
                                                shoppingCartLines.map((cartLine, index) => {
                                                    console.log("Index-" + index);
                                                    let parser = new DOMParser();
                                                    cartLine.extraInfo = cartLine.extraInfo.replaceAll(" & ", " &amp; ");
                                                    let extraInfo = parser.parseFromString(cartLine.extraInfo, "text/xml").childNodes[0];
                                                    var discountValue = "";
                                                    if (cartLine.appliedDiscount > 0) {
                                                        discountValue = cartLine.appliedDiscount;
                                                        if (cartLine.discountType === 'dollarDiscount') {
                                                            discountValue = "$" + discountValue;
                                                        }
                                                        else {
                                                            discountValue += "%";
                                                        }
                                                        discountValue += " (" + cartLine.discountDuration + ")";
                                                    }
                                                    else {
                                                        discountValue = "N/A";
                                                    }

                                                    let externalName = extraInfo.getElementsByTagName("externalName")[0].textContent;
                                                    let productType = extraInfo.getElementsByTagName("productType")[0].textContent;
                                                    return (<React.Fragment key={cartLine.lineNumber}>
                                                        <Grid item xs={4} align="left">
                                                            {externalName}
                                                        </Grid>
                                                        <Grid item xs={1} align="center">
                                                            {productType}
                                                        </Grid>
                                                        <Grid item xs={1} align="center">
                                                            {cartLine.quantity}
                                                        </Grid>

                                                        <Grid item xs={2} align="left">
                                                            {discountValue}
                                                        </Grid>
                                                        <Grid item xs={1} align="right">
                                                            {commonfunctions.formatCartPriceToCurrency(cartLine.msrp)}
                                                        </Grid>
                                                        <Grid item xs={1} align="right">
                                                            {commonfunctions.formatCartPriceToCurrency(cartLine.unitPrice)}
                                                        </Grid>
                                                        <Grid item xs={1} align="right">
                                                            {commonfunctions.formatCartPriceToCurrency(cartLine.discount)}
                                                        </Grid>
                                                        <Grid item xs={1} align="right">
                                                            {commonfunctions.formatCartPriceToCurrency(cartLine.subTotal)}
                                                        </Grid>
                                                    </React.Fragment>)

                                                })}
                                            <Grid item xs={8} align="right" >
                                                <b> Totals </b>
                                            </Grid>
                                            <Grid item xs={1} align="right" className="UWA_SubscriptionCartTotals">
                                                {commonfunctions.formatCartPriceToCurrency(currentCartSubTotal)}
                                            </Grid>
                                            <Grid item xs={1} align="right" className="UWA_SubscriptionCartTotals">
                                                &nbsp;
                                            </Grid>
                                            <Grid item xs={1} align="right" className="UWA_SubscriptionCartTotals">
                                                {commonfunctions.formatCartPriceToCurrency(currentCartDiscount)}
                                            </Grid>

                                            <Grid item xs={1} align="right" className="UWA_SubscriptionCartTotals">
                                                {commonfunctions.formatCartPriceToCurrency(currentCartTotal)}
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                </>)}
                            
                        </CardContent>
                        <CardActions>
                            <Grid container spacing={2} justifyContent="flex-start">
                            {(currentCartID === "") ?
                                (<> 
                                    <Grid item xs={3} md={3}>
                                        <span className="UWA_FIQCartActions">
                                            <Button size="small" className="UWA_btn_cancelFIQcart" onClick={() => returnToDashboard()}>DASHBOARD</Button>
                                                <Button size="small" className="UWA_btn_createFIQcart" onClick={() => saveShoppingCart()}>SAVE/NEXT</Button>
                                        </span>
                                    </Grid>
                                    <Grid item xs={9}></Grid>
                                 
                                    </>
                                    )
                                : (<><span className="UWA_FIQCartActions"></span>

                                        </>
                                        )}
                                <Grid item xs={12}>{shoppingCartValidationErrMsgs && <Alert severity="error"><>{shoppingCartValidationErrMsgs}</></Alert>}</Grid>
                                
                            </Grid>
                            </CardActions>
                    </Card>
                </Col>
            </Row>
            {(hasPlanItemInCart === true) ?
                <>
                    <Row>
                        <Col>
                            <Card className="mx-auto my-4">
                                <CardHeader title="Plan Users" className="UWA_FIQCardTitle" action={
                                    <IconButton disabled={!canEditCart} tooltip="Add Plan User" aria-label="settings" onClick={(e) => { handleClickOpenUserAdd(e) }} sx={{ color: yellow[50] }}>
                                        <PersonAddIcon />
                                    </IconButton>
                                }>

                                </CardHeader>


                                <CardContent>
                                    <Grid container spacing={2} alignItems="center" justifyContent="center">
                                        {planUsersSetupComplete === false ?
                                        <>
                                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                                    <Alert severity="error">You must setup the usernames and emails of the Plan users below.</Alert>
                                                </Grid>
                                            </> : <></>}
                                        {PlanUsers?.length === 0 ?
                                            (<font className='UWA_FIQCartInstructions'>Click on the Add User Icon to add a user for this subscription</font>)
                                            : <Load_PlanUsersControl cartPlanUsers={PlanUsers} />}

                                    </Grid>
                                    {shoppingCartUpdateUserErrMsg && <Alert severity="error"><>{shoppingCartUpdateUserErrMsg}</></Alert>}
                                </CardContent>
                                <CardActions>
                                    {/*<Button size="small">Share</Button>*/}
                                    {/*<Button size="small">Learn More</Button>*/}
                                </CardActions>
                                <FIQAddUserModal open={FIQAddUserDialogIsOpen} onClose={closeFIQAddUserDialog} updatePlanUser={updatePlanUserDetails} editFName={editFName} editLName={editLName} editUserName={editUserName} editEmail={editEmail} />

                            </Card>
                        </Col>
                    </Row>
                    <Dialog
                        open={confirmUserDeleteModal}
                        onClose={handle_CloseUserDeletionDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"Confirm User Deletion"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                               Are you sure you want to delete this user from the subscription?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={()=> handle_CloseUserDeletionDialog()}>No</Button>
                            <Button onClick={()=> deleteUserFromSubscription()} autoFocus>
                                Yes
                            </Button>
                        </DialogActions>
                            </Dialog>
                </> :

                <></>}
        </Container>
        
    );
   
}
