import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import FeasibilityIQCart from './FeasibilityIQCart';
import { useOktaAuth } from "@okta/okta-react";

export default function CreateFeasibilityIQCart(props) {
    const { authState, oktaAuth } = useOktaAuth();
    const [ bearerToken, setBearerToken] = useState("");
    useEffect(() => {
        document.title = "Create Shopping Cart";

        if (!authState || !authState.isAuthenticated) {
            // When user isn't authenticated dont allow the user to get to this page.
            //setUserInfo(null);
           // console.log("DUMMY");
        } else {
            oktaAuth.getUser().then((info) => {
              
                setBearerToken(authState.accessToken.accessToken);
                //setUserInfo(info);
            });
        }
    }, [authState, oktaAuth]); // Update if authState changes
   // console.log(props.oktaToken);
   

    return (
        <Container>
            <Row>
                <Col>
                    <h3>Add Shopping Cart</h3>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FeasibilityIQCart  bearerToken={bearerToken} mode="create"/>
                </Col>
            </Row>
                
        </Container>
        );
    
}