import React, { useState, useEffect, useCallback } from 'react';
import Skeleton from '@mui/material/Skeleton';
import cartfunctions from '../_helpers/cartfunctions';
import { Container, Row, Col } from 'reactstrap';
import { Button } from '@mui/material';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import AddBoxIcon from '@mui/icons-material/AddBox';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { commonfunctions } from '../_helpers/commonfunctions';
import Alert from '@mui/material/Alert';

export default function UWA_ShoppingCart(props) {
    const [loading, setLoading] = useState(false);
    const [readOnlyMode] = useState(props.ReadOnlyMode === undefined ? "" : props.ReadOnlyMode);
    const [shoppingCartPageLink] = useState(props.shoppingCartPageLink);
    const [checkOutPageLink] = useState(props.checkOutPageLink);
    const [cartUpdateErrorMsg, updateErrorMsg]  = useState("");
    const [quantityEditNotAllowedMsg] = useState("Unable to edit quantities on this line due to it being a special shopping cart.")
    const handleAddToCartClick = async (itemID, unitPrice) => {
        await cartfunctions.addQtyToCart(itemID, unitPrice);
        props.updateCartContents();
        //LoadShoppingCart();
       
    };
    const handleRemoveFromCartClick = async (itemID, unitPrice) => {
        await cartfunctions.removeQtyfromCart(itemID, unitPrice);
        props.updateCartContents();
        
   };
    const handleCheckOutClick = useCallback(() => {
        window.location.href = (checkOutPageLink === '' ? '/CheckoutWizard' : checkOutPageLink);
    });
    //const LoadShoppingCart = async () => {
    //    var endPointURL = process.env.REACT_APP_API_URL;
    //    if (process.env.REACT_APP_API_URL === undefined) {
    //        endPointURL = "/";
    //    }
    //    const body = {
    //        cartID: props.cartID
    //    };
    //    try {
    //        const serviceUrl = endPointURL + 'api/ShoppingCart/GetShoppingCart';
    //        await axios.post(serviceUrl, body)
    //            .then((response) => {
    //                if (response.status === 200) {
    //                    //console.log(response.data);
    //                    setCartDetails(response.data);
    //                     if (response.data.cartLines.length > 0) {
    //                        updateHasCartLines(true);
    //                    }

    //                }

    //            })
    //            .catch((error) => {
    //                console.log(error);

    //            });
    //    }
    //    catch (error) {
    //        console.log(error);
    //    }
    //}
    //const clearCart = async () => {
    //    var endPointURL = process.env.REACT_APP_API_URL;
    //    if (process.env.REACT_APP_API_URL === undefined) {
    //        endPointURL = "/";
    //    }
    //    const body = {
    //        cartID: currentCartID
    //    };
    //    try {
    //        const serviceUrl = endPointURL + 'api/ShoppingCart/ClearShoppingCart';
    //        await axios.post(serviceUrl, body)
    //            .then((response) => {
    //                if (response.status === 200) {
    //                    //LoadShoppingCart();
    //                    this.props.updateCartContents(cartDetails);

    //                }

    //            })
    //            .catch((error) => {
    //                console.log(error);
    //                updateErrorMsg(error.response.data.detail);
    //                setTimeout(() => {
    //                    updateErrorMsg("");
    //                }, 8000);

    //            });
    //    }
    //    catch (error) {
    //        console.log(error);
    //    }

      
       
    //}
    const clearCart = async () => {
        await cartfunctions.clearCart()
            .then((result) => {
                //console.log(result);
                props.updateCartContents();
                //window.location.href = (shoppingCartPageLink === '' ? '/GetShoppingCart' : shoppingCartPageLink);
            })
            .catch((error) => {
                //console.log(error);
                updateErrorMsg(error.response.data.detail);
                setTimeout(() => {
                    updateErrorMsg("");
                }, 8000);

            });

    };
    const handleEditCartClick = useCallback(() => {
        window.location.href = (shoppingCartPageLink === '' ? '/GetShoppingCart' : shoppingCartPageLink);
    });
   
    useEffect(() => {
        //LoadShoppingCart();
        setLoading(false);
    }, []);
    
   return (
        <><Container>
            <Row>
                <Col md={12} >
                   {loading ? (<Skeleton variant="rectangular" sx={{ fontSize: '6rem' }}/>) : (<>  <TableContainer component={Card}>
                       {(readOnlyMode === "") ?
                           (
                               <Table>
                                   <TableHead>
                                       <TableRow>
                                           <TableCell align="left"> <b>Name</b></TableCell>
                                           <TableCell align="right"> <b>Unit Price</b></TableCell>
                                           <TableCell align="center"> <b>Quantity</b></TableCell>
                                           <TableCell align="right"> <b>Discount</b></TableCell>
                                           <TableCell align="right"> <b>Total Unit Price</b></TableCell>
                                       </TableRow>
                                   </TableHead>
                                   <TableBody>
                                       {props.cartContents.cartLines?.map(cartline => {
                                        
                                           return (<TableRow key={cartline.productID}
                                               sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                           >
                                               <TableCell component="th" scope="row">{cartline.description}
                                                   {cartline.extraInfo?.length > 0 && <Alert severity="error" className="UWA_CartEditNotAllowedMsg"><>{quantityEditNotAllowedMsg}</></Alert>}
                                               </TableCell>
                                               <TableCell component="th" scope="row" align="right">{commonfunctions.formatCartPriceToCurrency(cartline.unitPrice)}</TableCell>
                                               <TableCell component="th" scope="row" align="right">
                                                   <Typography sx={{
                                                       maxWidth: 200, // percentage also works
                                                       whiteSpace: 'nowrap',
                                                       overflow: 'hidden',
                                                       textOverflow: 'ellipsis',
                                                   }}>
                                                       {cartline.extraInfo == null && <Button onClick={() => handleRemoveFromCartClick(cartline.productID, cartline.unitPrice)} style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px' }}><IndeterminateCheckBoxIcon color="primary" /></Button>}
                                                       {cartline.extraInfo != null && <Button sx={{ color: red[500] }} disabled style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px' }}><IndeterminateCheckBoxIcon /></Button>}
                                                       <input type="text" disabled size="2" value={cartline.quantity} />
                                                       {cartline.extraInfo == null && <Button onClick={() => handleAddToCartClick(cartline.productID, cartline.unitPrice)} style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px' }} ><AddBoxIcon color="primary" sx={{ pl: '0.1em', }} /></Button>}
                                                       {cartline.extraInfo != null && <Button sx={{ color: red[500] }} disabled style={{ maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px' }} ><AddBoxIcon sx={{ pl: '0.1em', }} /></Button>}
                                                   </Typography>

                                               </TableCell>
                                               <TableCell component="th" scope="row" align="right"><font className={cartline.discount > 0 ? "UWA_CartDiscountAmount" : ""}>{commonfunctions.formatCartPriceToCurrency(cartline.discount)}</font></TableCell>
                                               <TableCell component="th" scope="row" align="right">{commonfunctions.formatCartPriceToCurrency(cartline.quantity * cartline.unitPrice)}</TableCell>
                                           </TableRow>);
                                       })}

                                       {props.cartContents.cartLines?.length > 0 ?
                                           (
                                               <><TableRow>
                                                   <TableCell colSpan="4" rowSpan="4" component="th" scope="row" align="left">
                                                       {props.cartContents.cartLines?.length > 0 && (props.cartContents.cartLines[0].extraInfo == null) && <Button size="small" sx={{ color: red[500] }} startIcon={<RemoveShoppingCartIcon />} onClick={() => clearCart()} className="btn_clearcart">Clear Cart</Button>}
                                                       {cartUpdateErrorMsg && <Alert severity="error"><>{cartUpdateErrorMsg}</></Alert>}
                                                   </TableCell>
                                               </TableRow>
                                                   <TableRow>

                                                       <TableCell component="th" scope="row" align="right">Sub Total&nbsp;&nbsp;<b>{commonfunctions.formatCartPriceToCurrency(props.cartContents.subTotal)}</b></TableCell>
                                                   </TableRow>
                                                   <TableRow>

                                                       <TableCell component="th" scope="row" align="right">Discount&nbsp;&nbsp;<b><font className={props.cartContents.discountAmount > 0 ? "UWA_CartDiscountAmount" : ""}>{commonfunctions.formatToCurrency(props.cartContents.discountAmount)}</font></b></TableCell>
                                                   </TableRow>
                                                   <TableRow>
                                                       <TableCell component="th" scope="row" align="right">Cart Total &nbsp;&nbsp;<b>{commonfunctions.formatCartPriceToCurrency(props.cartContents.total)}</b></TableCell>
                                                   </TableRow>
                                                   <TableRow>
                                                       <TableCell colSpan="5" scope="row" align="right"> <Button onClick={() => handleCheckOutClick()} color="success" variant="contained" className="btn_checkout">CHECK OUT</Button></TableCell>
                                                   </TableRow></>
                                           ) :
                                           (
                                               <TableRow>
                                                   <TableCell component="th" scope="row" align="center" colSpan="5">Your shopping cart is empty</TableCell>
                                               </TableRow>
                                           )
                                       }

                                   </TableBody>
                               </Table>
                           ) :
                           (
                               <Table>
                                  

                                   {(props.cartContents.cartLines?.length > 0) ?
                                       (
                                        <TableBody>
                                               <TableRow>
                                                   <TableCell component="th" scope="row" align="right">Items</TableCell>
                                                   <TableCell component="th" scope="row" align="right">{commonfunctions.formatCartPriceToCurrency(props.cartContents.subTotal)}</TableCell>
                                               </TableRow>
                                               <TableRow>
                                                   <TableCell component="th" scope="row" align="right">Count</TableCell>
                                                   <TableCell component="th" scope="row" align="right">{props.cartContents.quantity}</TableCell>
                                               </TableRow>
                                               <TableRow>
                                                   <TableCell component="th" scope="row" align="right">Discounts</TableCell>
                                                   <TableCell component="th" scope="row" align="right"><font className={props.cartContents.discountAmount > 0 ? "UWA_CartDiscountAmount" : ""}>{commonfunctions.formatCartPriceToCurrency(props.cartContents.discountAmount)}</font></TableCell>
                                               </TableRow>
                                               <TableRow>
                                                   <TableCell component="th" scope="row" align="right">S&H</TableCell>
                                                   <TableCell component="th" scope="row" align="right">{commonfunctions.formatCartPriceToCurrency(0.00)}</TableCell>
                                               </TableRow>
                                               <TableRow>
                                                   <TableCell component="th" scope="row" align="right"><b>Sub Total</b></TableCell>
                                                   <TableCell component="th" scope="row" align="right"><b>{commonfunctions.formatCartPriceToCurrency(props.cartContents.total)}</b></TableCell>
                                               </TableRow>
                                               <TableRow>
                                                   {props.cartContents.cartLines[0].extraInfo == null && <TableCell colSpan="2" align="left"><Button size="small" sx={{ color: red[500] }} startIcon={<ShoppingCartIcon />} onClick={() => handleEditCartClick()}>Edit Cart</Button></TableCell>}



                                               </TableRow>
                                           </TableBody>
                                       ) :
                                       (<TableBody>
                                           <TableRow>
                                               <TableCell component="th" scope="row" align="center" colSpan="2">Your shopping cart is empty</TableCell>
                                           </TableRow>
                                       </TableBody>
                                       )
                                   }

                               </Table>
                           )
                       }

                   </TableContainer>
                   </>)}
                </Col>
            </Row>
        </Container></>
        );

};