import React, { useEffect, useState, useRef, useCallback } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ClearIcon from '@mui/icons-material/Clear';
import Card from '@mui/material/Card';
import Tooltip from "@mui/material/Tooltip";
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Checkbox from '@mui/material/Checkbox';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import DiscountIcon from '@mui/icons-material/Discount';
import { red } from '@mui/material/colors';
import { Radio, RadioGroup, FormControl, FormControlLabel, InputLabel, CardHeader, CardMedia, Avatar,  Select, MenuItem, Switch } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { ModalWait } from '../WaitModal';
import InputAdornment from '@mui/material/InputAdornment';
import Alert from '@mui/material/Alert';
import Popover from '@mui/material/Popover';
import { commonfunctions } from '../../_helpers/commonfunctions';



const LoadDiscountsButton = ({ product, updateDiscounts }) => {
    const discountProductItem = useRef(product);
    //const [currentProductItem, updateCurrentProductItem] = useState(product);
    const [anchorEl, setAnchorEl] = useState(null);
    const [discountValue, setDiscountValue] = useState(product.discountValue);
    const [discountDuration, setDiscountDuration] = useState(product.discountDuration);
    const [discountType, setDiscountType] = useState(product.discountType);
    const [productType, setProductType] = useState(product.productType);
    const popoverOpen = Boolean(anchorEl)
    //const popoverOpen = Boolean(popoverAnchor);
    const handleProductDiscountPopover = (event) => {
        event.preventDefault()
        if (anchorEl === null) {
            setAnchorEl(event.currentTarget)
        } else {
            updateDiscounts(discountProductItem.current);
            setAnchorEl(null)
        }
    }
    const handleCustomDiscountValueChange = (e) => {
        product.discountValue = e.target.value;
        discountProductItem.current = product;
        setDiscountValue(e.target.value);
        //updateCurrentProductItem(product);
        //setDiscountProductItem(product);
        
    }
    const handleDiscountTypeChange = (e) => {
        product.discountType = e.target.value;
        discountProductItem.current = product;
        setDiscountType(e.target.value);
        //updateCurrentProductItem(product);
        
    }
    const handleDiscountDurationChange = (e) => {
        product.discountDuration = e.target.value;
        discountProductItem.current = product;
        setDiscountDuration(e.target.value);
        //updateCurrentProductItem(product);
       
    }
    return (
        <><IconButton aria-owns={popoverOpen ? 'discountPopOverID' : undefined} aria-haspopup={true} variant="contained" onClick={handleProductDiscountPopover} color="primary">
            <DiscountIcon />
        </IconButton>
            <Popover
                id={'discountPopOverID'}
                open={popoverOpen}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                onClose={handleProductDiscountPopover}
            >


                <Grid container justifyContent="space-between" sx={{ width: 300, padding: 2 }}>
                    <Grid item xs={6}>
                        <TextField style={{ width: '100%' }}
                            name="discount"
                            label="Discount Applied"
                            size="small"
                            value={discountValue}
                            onChange={handleCustomDiscountValueChange}
                            autoFocus
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <RadioGroup
                            aria-labelledby="discountChoicesLabel"
                            name="row-discountChoiceGroup"
                            row
                            defaultValue={discountType}
                            onChange={handleDiscountTypeChange}
                        >
                            <FormControlLabel value="dollarDiscount" control={<Radio inputProps={{ 'title': 'Discount in Dollars' }} />} label="$" />
                            <FormControlLabel value="percentageDiscount" control={<Radio inputProps={{ 'title': 'Discount as a Percentage' }} />} label="%" />
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={12}>
                    </Grid>
                    <Grid item xs={12} align="right">
                        {productType == 'Plan' ?
                            <>
                            <RadioGroup
                                aria-labelledby="discountDurationLabel"
                                name="row-discountDurationGroup"
                                row
                                defaultValue={discountDuration}
                                onChange={handleDiscountDurationChange}
                            >
                                <FormControlLabel value="One Time" control={<Radio inputProps={{ 'title': 'Discount applies one time' }} />} label="One Time" />
                                <FormControlLabel value="Forever" control={<Radio inputProps={{ 'title': 'Discount applies forever' }} />} label="Forever" />
                            </RadioGroup>
                            </> :
                            <>
                                <RadioGroup
                                    aria-labelledby="discountDurationLabel"
                                    name="row-discountDurationGroup"
                                    row
                                    defaultValue={discountDuration}
                                    onChange={handleDiscountDurationChange}
                                >
                                    <FormControlLabel value="One Time" control={<Radio inputProps={{ 'title': 'Discount applies one time' }} />} label="One Time" />
                                    <FormControlLabel value="Forever" control={<Radio disabled inputProps={{ 'title': 'Discount applies forever' }} />} label="Forever" />
                                </RadioGroup>
                            </>}
                       
                    </Grid>
                </Grid>



            </Popover>
        </>)
}
export default function CartCreationWizard(props) {
    const steps = ['Start', 'Product Family', 'Product', 'Quantity', 'Finalize'];
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());
    const [errorMsg, updateErrorMsg] = useState("");
    const [infoMsg, updateInfoMsg] = useState("");
    const [customerSearchResults, updateCustomerSearchResults] = useState([]);
    const [searchResultCount, updateSearchResultCount] = useState(0);
    const [availableProductSkeleton, isLoadingAvialableProductSkeletion] = useState(false);
    const [availableProducts, setAvailableProduct] = useState([]);
    const [productFamilies, setProductFamilies] = useState([]);


    const customerSearchQuery = useRef("");
    const [pageNumber, setPageNumber] = useState(1);
    const [ItemsPerPage] = useState(2);
    const currentPosition = ((pageNumber * ItemsPerPage) - ItemsPerPage);

    let paginatedCustomerSearchResults = customerSearchResults.slice(currentPosition, currentPosition + ItemsPerPage);

    /**Current Values indicate these are used to build out the Shopping Cart****/
    const currentCustomerID = useRef("");
    const currentProductFamilyID = useRef([]);
    const currentProductSKU = useRef([]);
    const [currentProductList, updateCurrentProductList] = useState([]);
    const [callbackProductItem, updateCallBackProductItem] = useState([]);
    const [popoverTarget, updatePopOverTarget] = useState(null);
    const currentCustomerIsLoaded = useRef(false);
    const currentProductFamilyIsLoaded = useRef(false);
    const currentProductSKUIsLoaded = useRef(false);
    const [currentCartSubTotal, updateCartSubTotal] = useState(0.00);
    const [currentCartDiscount, updateCartDiscount] = useState(0.00);
    const [currentCartTotal, updateCartTotal] = useState(0.00);


    const [currentCustomerDetails, setCurrentCustomerDetails] = useState([]);


    const [showWaitModal, toggleWaitModal] = useState(false);


    const isStepOptional = (step) => {
        return step === -1;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handlePrevPage = () => {

        if (pageNumber === 1) return;
        toggleWaitModal(true);
        setPageNumber(pageNumber - 1);
        toggleWaitModal(false);

    }
    const handleNextPage = () => {
        if (Math.round(searchResultCount / (pageNumber + 1)) >= ItemsPerPage) {
            toggleWaitModal(true);
            setPageNumber(pageNumber + 1);
            toggleWaitModal(false);

        }
        else return;

    }
    const handleNext = () => {
        let newSkipped = skipped;
        updateErrorMsg("");
        switch (activeStep) {
            case 0:
                if (!currentCustomerIsLoaded.current) {
                    updateErrorMsg("You must select a customer before proceeding to the next step");
                    return;
                }
                break;
            case 1:
                if (!currentProductFamilyIsLoaded.current) {
                    updateErrorMsg("You must select a Product Family before proceeding to the next step");
                    return;
                }
                break;
            case 2:
                if (!currentProductSKU.current) {
                    updateErrorMsg("You must select a Product before proceeding to the next step");
                    return;
                }
                break;
            case 3:
                if (!validateDiscountChoices()) {
                    return;
                }
                get_ShoppingCartPricing();
                break;
            case 4:
                
                break;
        }

        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };
    const handleBack = () => {
        updateErrorMsg("");
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };
    const handleReset = () => {
        setActiveStep(0);
    };
    const createShoppingCart = async () => {
        var endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
        const serviceUrl = endPointURL + 'api/ShoppingCart/CreateUserShoppingCart';
        const requestOptions = {
            customerID: currentCustomerID.current,
            CartLines: currentProductSKU.current
        };

        await axios.post(serviceUrl, requestOptions)
            .then(res => {
                if (res.status === 200) {
                    var redirectUrl = '/Admin/ManageShoppingCart?CartID=' + res.data.cartID;
                    setTimeout(() => {
                        window.location.href = redirectUrl;
                    }, 1000);
                }

            })
            .catch((error) => {
                console.log('Error:', error);
                if (error.response.data.message) {
                    updateErrorMsg(error.response.data.message);
                }
                else {
                    updateErrorMsg(error.response.data);
                }
            });
    }

    const handleSearchFieldChange = (e) => {
        customerSearchQuery.current = (e.target.value);
      
    }

    const validateDiscountChoices = () => {
        var cloneItems = [...currentProductSKU.current];
        for (let i = 0; i < cloneItems.length; i++) {
            if (cloneItems[i].discountValue) {
                if (!cloneItems[i].discountType) {
                    updateErrorMsg("You must select whether the discount is a dollar value or percentage on Item (" + cloneItems[i].externalName + ")");
                    return false;
                }
                if (!cloneItems[i].discountDuration) {
                    updateErrorMsg("You must select whether the discount is a one time or forever on Item (" + cloneItems[i].externalName + ")");
                    return false;
                }
            }
        }
        return true;
        

    }
   
    const handleProductFamilySelectionChange = (e) => {
        //console.log(e.target.value);
        currentProductFamilyID.current = (e.target.value);
        currentProductFamilyIsLoaded.current = true;
        currentProductSKUIsLoaded.current = false;
        currentProductSKU.current = "";
    }
    const handleProductFamilyCheckBoxSelectionChange = (e) => {
        //console.log(e.target.value);
        if (e.target.checked) {
            if (currentProductFamilyID.current.indexOf(e.target.value) < 0) {
                currentProductFamilyID.current.push(e.target.value);
            }
        }
        else {
            var prodFamilyItemIndex = currentProductFamilyID.current.indexOf(e.target.value);
            if (prodFamilyItemIndex > -1) {
                currentProductFamilyID.current.splice(prodFamilyItemIndex, 1);
                setProductFamilies([]);
                get_AvailableProductFamilies();
            }

        }
       
        currentProductFamilyIsLoaded.current = true;
        currentProductSKUIsLoaded.current = false;
        currentProductSKU.current = [];
        //console.log(currentProductFamilyID.current);
    }
    
    const handleUpdateDiscount = useCallback((e) => {
        var cloneItems = [...currentProductSKU.current];

        var currentItemIndex = cloneItems.findIndex(item => item.id == e.id);
        if (currentItemIndex > -1) {

            var currentProduct = cloneItems[currentItemIndex];
            console.log(currentProduct);
            if (e.discountValue) {
                currentProduct.discountDuration = e.discountDuration;
                currentProduct.discountType = e.discountType;
                currentProduct.discountValue = e.discountValue;
            }
            else {
                currentProduct.discountDuration = "";
                currentProduct.discountType = "";
                currentProduct.discountValue = 0;
            }
           
            currentProductSKU.current = cloneItems;
            updatePopOverTarget(true);
            updateCurrentProductList(currentProductSKU.current);
         
            //console.log("Loading after reload");
           
        }
        //console.log(currentProductSKU.current);
    }, callbackProductItem)
    const handleProductSelectionChange = (e) => {
        currentProductSKU.current = (e.target.value);

    }
    const handleQuantityChange = (e) => {
        var cloneItems = [...currentProductSKU.current];

        var currentItemIndex = cloneItems.findIndex(item => item.id == e.target.title);
        if (currentItemIndex > -1) {

            var currentProduct = cloneItems[currentItemIndex];
            console.log(currentProduct);
            currentProduct.qty = e.target.value;
            currentProductSKU.current = cloneItems;
            updateCurrentProductList(currentProductSKU.current);
        }
        //console.log(currentProductSKU.current);
    }
    const handleBillingFrequencyChange = (e) => {
        var cloneItems = [...currentProductSKU.current];

        var currentItemIndex = cloneItems.findIndex(item => item.id == e.target.name);
        if (currentItemIndex > -1) {

            var currentProduct = cloneItems[currentItemIndex];
            //console.log(currentProduct);
            currentProduct.billingFrequency = e.target.value;
            currentProductSKU.current = cloneItems;
        }
        console.log(currentProductSKU.current);
        updateCurrentProductList(currentProductSKU.current);
    }
    const handleProductSelectionCheckBoxChange = (e) => {
        //console.log("before");
        //console.log(currentProductSKU.current);
        updateErrorMsg("");
        var selectedProductIndex = availableProducts.findIndex(item => item.id == e.target.value);
        var selectedProduct = null;
        if (selectedProductIndex > -1) {
            selectedProduct = availableProducts[selectedProductIndex];
        }
        //let selectedProduct = {
        //    id: e.target.value,
        //    name: e.target.dataset.name,
        //    externalname: e.target.dataset.externalname,
        //    producttype: e.target.dataset.producttype,
        //    itemfamilyid: e.target.dataset.itemfamilyid,
        //    qty: 1,
        //    discounttype: '',
        //    unitprice: 0.00
        //};
        if (selectedProductIndex > -1) {
            if (e.target.checked) {
                //If there is already a plan in the list then don't allow this one to be added. 
                if (currentProductSKU.current.length === 0) {
                    currentProductSKU.current.push(selectedProduct);
                }
                else {
                    if (selectedProduct.productType === "Plan") {
                        var productItemIndex = currentProductSKU.current.findIndex(item => item.productType === "Plan");
                        if (productItemIndex > -1) {
                            updateErrorMsg("You can only have one Plan in your shopping cart. Please create another cart if you need to setup multiple plans.");
                            e.target.checked = false;
                            var cloneAvailableProducts = [...availableProducts];
                            setAvailableProduct([]);
                            setAvailableProduct(cloneAvailableProducts);
                           
                            //return;
                        }
                        else {
                            currentProductSKU.current.push(selectedProduct);
                        }
                    }
                    else {
                        currentProductSKU.current.push(selectedProduct);
                    }
                }


            }
            else {
                var itemIndex = currentProductSKU.current.findIndex(item => item.id == e.target.value);
                if (itemIndex > -1) {
                    currentProductSKU.current.splice(itemIndex, 1);
                    var cloneAvailableProducts = [...availableProducts];
                    setAvailableProduct([]);
                    setAvailableProduct(cloneAvailableProducts);
                }
            }
        }
        updateCurrentProductList(currentProductSKU.current);
        //console.log("After");
        console.log(currentProductSKU.current);
    }
    const btnSearch_Click = async () => {
        await getNSSearchResults(customerSearchQuery.current);
    }
    const get_AvailableProductFamilies = async () => {
        updateInfoMsg("");
        updateErrorMsg("");
        currentProductFamilyIsLoaded.current = false;
        var endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
        const serviceUrl = endPointURL + 'api/ChargebeeWidgets/GetAvailableProductFamilies';

        // toggleWaitModal(true);
        await axios.get(serviceUrl)
            .then(res => {
                if (res.status === 200) {
                   // console.log(res.data);
                    setProductFamilies(res.data.availableProductFamilies);
                   
                }

            })
            .catch((error) => {
                console.log('Error:', error);
                if (error.response.data.message) {
                    updateErrorMsg(error.response.data.message);
                }
                else {
                    updateErrorMsg(error.response.data);
                }
               
            });
    }
    const get_AvailableProducts = async () => {
        updateInfoMsg("");
        isLoadingAvialableProductSkeletion(true);
        //updateErrorMsg("");
        var endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
        const serviceUrl = endPointURL + 'api/ChargebeeWidgets/GetAvailableProducts/' + currentProductFamilyID.current;

        // toggleWaitModal(true);
        await axios.get(serviceUrl)
            .then(res => {
                if (res.status === 200) {
                    //availablePlans.current = (res.data.availablePlans);
                    console.log(res.data);
                    setAvailableProduct(res.data.availableProducts);
                    currentProductSKUIsLoaded.current = true;
                    isLoadingAvialableProductSkeletion(false);
                }

            })
            .catch((error) => {
                console.log('Error:', error);
                if (error.response.data.message) {
                    updateErrorMsg(error.response.data.message);
                }
                else {
                    updateErrorMsg(error.response.data);
                }
                //  toggleWaitModal(false);
            });
    }
    const get_ShoppingCartPricing = useCallback((e) => {
        
        updateInfoMsg("");
        isLoadingAvialableProductSkeletion(true);
        //updateErrorMsg("");
        var endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
        const serviceUrl = endPointURL + 'api/ShoppingCart/BuildShoppingCartEstimate';
        const requestOptions = {
            customerID: currentCustomerID.current,
            CartLines: currentProductSKU.current
        };

       axios.post(serviceUrl, requestOptions)
            .then(res => {
                if (res.status === 200) {
                    //console.log("CART Data");
                    console.log(currentProductList);
                    console.log(res.data);
                    updateCurrentProductList(res.data.cartInfo.cartLines);
                    currentProductSKU.current = res.data.cartInfo.cartLines;
                    updateCartTotal(res.data.cartInfo.total);
                    updateCartSubTotal(res.data.cartInfo.subTotal);
                    updateCartDiscount(res.data.cartInfo.discountAmount);
                    console.log(res.data.CartInfo);
                }

            })
            .catch((error) => {
                console.log('Error:', error);
                if (error.response.data.message) {
                    updateErrorMsg(error.response.data.message);
                }
                else {
                    updateErrorMsg(error.response.data);
                }
            });
    }, callbackProductItem);

    const getNSSearchResults = async (searchValue) => {
        if (!searchValue) {
            updateErrorMsg("You must enter a value to search for!");
            return;
        }
        toggleWaitModal(true);
        updateInfoMsg("");
        updateErrorMsg("");
        var endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
        const serviceUrl = endPointURL + 'api/NetSuite/GetNSCustomers';
        customerSearchQuery.current = (searchValue);
        const requestOptions = {
            SearchQuery: customerSearchQuery.current
        };
        await axios.post(serviceUrl, requestOptions)
            .then(res => {
                if (res.status === 200) {
                    toggleWaitModal(false);
                    setPageNumber(1);
                    updateSearchResultCount(res.data.count);
                    updateCustomerSearchResults(res.data.items);
                    currentProductFamilyID.current = ([]);
                    if (res.data.count === 0) {
                        updateInfoMsg("No Customers found matching the search criteria");
                    }
                }

            })
            .catch((error) => {
                console.log('Error:', error);
                toggleWaitModal(false);
                if (error.response.data.message) {
                    updateErrorMsg(error.response.data.message);
                }
                else {
                    updateErrorMsg(error.response.data);
                }

            });

    }
    const handleCustomerSelection = (e) => {
        var oracleID = e.currentTarget.dataset.key;

        //console.log(oracleID);
        currentCustomerID.current = oracleID;
        updateCustomerSearchResults([]);
        updateSearchResultCount(0);
        setPageNumber(1);
        customerSearchQuery.current = ("");
        load_CurrentCustomerDetails();
    }
    const showSearchSection = () => {
        customerSearchQuery.current = "";
        currentCustomerIsLoaded.current = false;
        setCurrentCustomerDetails([]);

    }
    const LoadCustomerSelectionStep = () => {
        if (currentCustomerIsLoaded.current) {
            return (
                <>

                    <Box display="flex" justifyContent="flex-end" >
                        <Tooltip title="Customer Search">
                            <Button variant="outlined" aria-label="Customer Search" onClick={showSearchSection} startIcon={<ManageSearchIcon />}>Customer Search</Button>
                        </Tooltip>
                    </Box>

                    {currentCustomerDetails && currentCustomerDetails.map(customerRecord => (
                        <div key={customerRecord.id}>
                            <Card sx={{ margin: 1 }} className="UWA_SearchResultCard" id={customerRecord.id}>
                                <CardHeader sx={{ borderBottom: '1px solid #ccc', backgroundColor: '#EEEEED', padding: '4px' }}
                                    avatar={
                                        <Avatar aria-label="">
                                            <BusinessRoundedIcon />
                                        </Avatar>
                                    }
                                    title={customerRecord.name + ' (' + customerRecord.id + ')'}
                                />
                                <span className="UWA_SearchResult_CB"></span>
                                <Box sx={{ display: 'flex', flexDirection: 'column', p: '4px' }}>
                                    <CardContent sx={{ flex: '1 0 auto' }}>

                                        <Typography component="div">
                                            <b>Sales Rep</b> {customerRecord.salesrepfirstname}  {customerRecord.salesreplastname}
                                        </Typography>
                                        {customerRecord.contactemail ? (
                                            <>
                                                <Typography component="div">
                                                    <b>Billing Contact</b> {customerRecord.contactfirstname}  {customerRecord.contactlastname} ( {customerRecord.contactemail} )
                                                </Typography>
                                            </>
                                        ) : (
                                            <>
                                                <p>
                                                    <b>Billing Contact</b>  <span className="has-error">  MISSING BILLING CONTACT INFO </span>

                                                </p>
                                            </>
                                        )}

                                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                            <Typography component="div" sx={{ paddingRight: 3 }}>
                                                <b>Billing Address</b>
                                                <p dangerouslySetInnerHTML={{ __html: commonfunctions.builAddress(customerRecord.billtoaddressee, customerRecord.billtoaddress1, customerRecord.billtoaddress2, customerRecord.billtocity, customerRecord.billtostate, customerRecord.billtozip, customerRecord.billtocountry) }} />
                                            </Typography>
                                            <Typography component="div">
                                                <b>Shipping Address</b>
                                                <p dangerouslySetInnerHTML={{ __html: commonfunctions.builAddress(customerRecord.shiptoaddressee, customerRecord.shiptoaddress1, customerRecord.shiptoaddress2, customerRecord.shiptocity, customerRecord.shiptostate, customerRecord.shiptozip, customerRecord.shiptocountry) }} />
                                            </Typography>
                                        </Box>

                                    </CardContent>

                                </Box>

                            </Card>

                        </div>
                    ))}
                </>
            );
        }
        else {
            return (
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            Welcome to the Client Cart Creation Wizard. Lets start by selecting the customer.
                        </Grid>
                        <Grid item xs={12}>
                            <TextField sx={{ m: 1, width: '100%' }}
                                name="search"
                                label="Search by Customer Name or Oracle #"
                                size="small"
                                onChange={handleSearchFieldChange}
                                defaultValue={customerSearchQuery.current}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {customerSearchResults?.length > 0 ? (<ClearIcon onClick={(e) => { btnClearResults_Click(e) }} />) : (null)}
                                            <ManageSearchIcon onClick={(e) => { btnSearch_Click(e) }} />
                                        </InputAdornment>
                                    ),
                                }}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        btnSearch_Click(e);
                                    }
                                }}
                            />
                        </Grid>

                    </Grid>
                    {paginatedCustomerSearchResults && paginatedCustomerSearchResults.map(customerRecord => (
                        <div key={customerRecord.id}>
                            <Card sx={{ display: 'flex', margin: 1 }} className="UWA_SearchResultCard" id={customerRecord.id}>

                                <span className="UWA_SearchResult_CB"></span>
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <CardContent sx={{ flex: '1 0 auto' }}>
                                        <Typography component="div" variant="h6" className="UWA_SearchresultName">
                                            <BusinessRoundedIcon /> {customerRecord.name} ({customerRecord.id})
                                        </Typography>
                                        <Typography className="UWA_SearchResultAddress" color="text.secondary" component="div">
                                            <b>Sales Rep</b> {customerRecord.salesrepfirstname}  {customerRecord.salesreplastname}
                                        </Typography>

                                        {customerRecord.contactemail ? (
                                            <>
                                                <Typography className="UWA_SearchResultAddress" color="text.secondary">
                                                    <b>Billing Contact</b> {customerRecord.contactfirstname}  {customerRecord.contactlastname} ( {customerRecord.contactemail} )
                                                </Typography>
                                            </>
                                        ) : (
                                            <>
                                                <p className="UWA_SearchResultAddress">
                                                    <b>Billing Contact</b> <font className="has-error"> MISSING BILLING CONTACT INFO </font>
                                                </p>

                                            </>
                                        )}
                                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                            <Typography className="UWA_SearchResultAddress" color="text.secondary" component="div" sx={{ paddingRight: 3 }}>
                                                <b>Billing Address</b>
                                                <p dangerouslySetInnerHTML={{ __html: commonfunctions.builAddress(customerRecord.billtoaddressee, customerRecord.billtoaddress1, customerRecord.billtoaddress2, customerRecord.billtocity, customerRecord.billtostate, customerRecord.billtozip, customerRecord.billtocountry) }} />
                                            </Typography>
                                            <Typography className="UWA_SearchResultAddress" color="text.secondary" component="div">
                                                <b>Shipping Address</b>
                                                <p dangerouslySetInnerHTML={{ __html: commonfunctions.builAddress(customerRecord.shiptoaddressee, customerRecord.shiptoaddress1, customerRecord.shiptoaddress2, customerRecord.shiptocity, customerRecord.shiptostate, customerRecord.shiptozip, customerRecord.shiptocountry) }} />
                                            </Typography>
                                        </Box>
                                        {customerRecord.contactemail ? (
                                            <>
                                                <Button variant="contained" className="UWA_btn_Submit" onClick={handleCustomerSelection} data-key={customerRecord.id}>
                                                    SELECT
                                                </Button>
                                            </>
                                        ) : (
                                            <>
                                                <Button variant="contained" disabled data-key={customerRecord.id}>
                                                    SELECT
                                                </Button>
                                            </>
                                        )}

                                    </CardContent>

                                </Box>

                            </Card>

                        </div>
                    ))}
                    {customerSearchResults?.length > 0 ? (
                        <>
                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                <Box component="span" style={{ float: "left" }}>
                                    <font>Page #: {pageNumber}</font>
                                </Box>
                                <Box sx={{ flex: '1 1 auto' }} />
                                <Box component="span" style={{ float: "right" }}>
                                    <font>Total Records Found: {searchResultCount}</font>
                                    <IconButton color="secondary" onClick={handlePrevPage} aria-label="Previous" sx={{ color: red[600] }}>
                                        <ArrowLeftIcon />
                                    </IconButton>
                                    <IconButton color="secondary" onClick={handleNextPage} aria-label="Next" sx={{ color: red[600] }}>
                                        <ArrowRightIcon />
                                    </IconButton>
                                </Box>
                            </Box>


                        </>

                    ) : (null)}
                </>
            );
        }
    }

    const LoadProductFamilySelectionStep = () => {
       
            return (
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <br />
                        </Grid>
                        <FormControl sx={{ width: '100%' }}>
                            <RadioGroup
                                aria-labelledby="addPlansLabel"
                                name="row-addPlansGroup"
                                defaultValue={currentProductFamilyID.current}
                                onChange={handleProductFamilySelectionChange}
                            >
                             
                              
                                    <Card sx={{ margin: 1, width: '100%' }}>
                                                <CardHeader className="UWA_SecondaryCardHeader" sx={{ padding: '4px' }} 
                                                    avatar={
                                                        <Avatar aria-label="Product Family">
                                                            <CardMembershipIcon />
                                                        </Avatar>
                                                    }
                                                    title="Available Product Families"
                                                />

                                                <Box>
                                                    <CardContent >
                                                        {productFamilies.sort().map((productFamily, index) => {
                                                            //console.log(productFamily);
                                                            return (
                                                                <React.Fragment key={productFamily.id}>
                                                                    <div key={productFamily.id}>
                                                                        <Typography component="div" variant="h6" className="UWA_ProductFamily">
                                                                            <FormControlLabel value={productFamily.id} control={<Radio inputProps={{ 'title': productFamily.id }} />} label={productFamily.name} key={productFamily.id} />
                                                                        </Typography>
                                                                        <Typography className="UWA_PlanDescription" color="text.secondary" component="div">
                                                                            <i> {productFamily.description + ' (' + productFamily.id + ') '}</i>
                                                                        </Typography>
                                                                    </div>
                                                                </React.Fragment>
                                                            );
                                                        })}
                                               
                                                    </CardContent>

                                                </Box>
                                            </Card>
                                
                             
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </>
            )
       
    }

    const LoadProductFamilySelectionStepWithCheckBox = () => {
        return (
            <>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <br />
                    </Grid>
                    <FormControl sx={{ width: '100%' }}>
                        


                            <Card sx={{ margin: 1, width: '100%' }}>
                                <CardHeader className="UWA_SecondaryCardHeader" sx={{ padding: '4px' }}
                                    avatar={
                                        <Avatar aria-label="Product Family">
                                            <CardMembershipIcon />
                                        </Avatar>
                                    }
                                    title="Available Product Families"
                                />

                                <Box>
                                    <CardContent >
                                    {productFamilies.sort(function (a, b) {
                                        return (b.name === null) - (a.name === null) || -(b.name > a.name) || +(b.name < a.name);
                                    }).map((productFamily, index) => {
                                            //console.log(productFamily);
                                            return (
                                                <React.Fragment key={productFamily.id}>
                                                    <div key={productFamily.id}>
                                                        <Typography component="div" variant="h6" className="UWA_ProductFamily">
                                                            {currentProductFamilyID.current.indexOf(productFamily.id) > -1 ?
                                                                (
                                                                    <FormControlLabel value={productFamily.id} control={<Checkbox inputProps={{ 'title': productFamily.id }} onChange={handleProductFamilyCheckBoxSelectionChange} labelplacement="end" checked />} label={productFamily.name} key={productFamily.id} />
                                                                )
                                                                :
                                                                (<FormControlLabel value={productFamily.id} control={<Checkbox inputProps={{ 'title': productFamily.id }} onChange={handleProductFamilyCheckBoxSelectionChange} labelplacement="end" />} label={productFamily.name} key={productFamily.id} />
                                                                )
                                                            }
                                                        </Typography>
                                                        <Typography className="UWA_PlanDescription" color="text.secondary" component="div">
                                                            <i> {productFamily.description + ' (' + productFamily.id + ') '}</i>
                                                        </Typography>
                                                    </div>
                                                </React.Fragment>
                                            );
                                        })}

                                    </CardContent>

                                </Box>
                            </Card>
                   </FormControl>
                </Grid>
            </>
        )
    }
    const LoadProductSelectionStep = () => {
        return (
            <>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <br />
                    </Grid>
                    <FormControl sx={{ width: '100%' }}>
                        <RadioGroup
                            aria-labelledby="addPlansLabel"
                            name="row-addPlansGroup"
                            defaultValue={currentProductSKU.current}
                            onChange={handleProductSelectionChange}
                        >
                            <Card sx={{ margin: 1, width: '100%' }}>
                                <CardHeader className="UWA_SecondaryCardHeader" sx={{ padding: '4px' }}
                                    avatar={
                                        <Avatar aria-label="Product Family">
                                            <CardMembershipIcon />
                                        </Avatar>
                                    }
                                    title={currentProductFamilyID.current + " SKU's"}
                                />

                                <Box>
                                    <CardContent >
                                        {availableProducts.sort(function (a, b) {
                                            return (b.external_name === null) - (a.external_name === null) || -(b.external_name > a.external_name) || +(b.external_name < a.external_name);
                                        }).map((product) => {
                                            //console.log(product);
                                            var defaultAvatarColor = '';
                                            var defaultAvatarCode = '';
                                            var defaultAltLabel = '';
                                            if (product.productType === 'Plan') {
                                                defaultAvatarColor = '#0b5345';
                                                defaultAvatarCode = 'P';
                                                defaultAltLabel = "Plan";
                                            }
                                            else if (product.productType === 'Addon') {
                                                defaultAvatarColor = '#b9770e';
                                                defaultAvatarCode = 'A';
                                                defaultAltLabel = "Addon";
                                            }
                                            else if (product.productType === 'Charge') {
                                                defaultAvatarColor = '#8e44ad';
                                                defaultAvatarCode = 'C';
                                                defaultAltLabel = "Charge";
                                            }
                                            var isSpecialSKU = product.description.includes('Used for');
                                            if (isSpecialSKU) {
                                                return (
                                                    <React.Fragment key={product.id}>
                                                        <div key={product.id}>
                                                            <Typography component="div" variant="h6" className="UWA_PlanNameSpecial" sx={{ display: "flex", alignItems: "center" }}>
                                                                <FormControlLabel value={product.id} control={<Radio inputProps={{ 'title': product.id }} />} label={product.externalName} key={product.id} />
                                                                <Tooltip title={defaultAltLabel}><Avatar style={{ margin: "4px", fontSize: "1em" }} alt={defaultAltLabel} sx={{ width: 22, height: 22, bgcolor: defaultAvatarColor }}>{defaultAvatarCode}</Avatar></Tooltip>
                                                            </Typography>
                                                            <Typography className="UWA_PlanDescription" color="text.secondary" component="div" sx={{ display: "flex", alignItems: "center" } }>
                                                                <i> {product.description + ' (' + product.id + ') '}</i>
                                                            </Typography>
                                                        </div>
                                                    </React.Fragment>
                                                );
                                            }
                                            else {
                                                return (
                                                    <React.Fragment key={product.id}>
                                                        <div key={product.id}>
                                                            <Typography component="div" variant="h6" className="UWA_PlanName" sx={{ display: "flex", alignItems: "center"}}>
                                                                <FormControlLabel value={product.id} control={<Radio inputProps={{ 'title': product.id }} />} label={product.externalName} key={product.id} />
                                                                <Tooltip title={defaultAltLabel}><Avatar style={{ margin: "4px", fontSize: "1em" }} alt={defaultAltLabel} sx={{ width: 22, height: 22, bgcolor: defaultAvatarColor }}>{defaultAvatarCode}</Avatar></Tooltip>
                                                            </Typography>
                                                            <Typography className="UWA_PlanDescription" color="text.secondary" component="div" >
                                                                <i> {product.description + ' (' + product.id + ') '}</i>
                                                            </Typography>
                                                        </div>
                                                    </React.Fragment>
                                                );
                                            }
                                           
                                        })}

                                    </CardContent>

                                </Box>
                            </Card>
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </>
        );
    }
    const LoadProductSelectionStepWithCheckBox = () => {
        return (
            <>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <br />
                    </Grid>
                    <FormControl sx={{ width: '100%' }}>
                       
                        {currentProductFamilyID.current.sort().map((planFamily) => {
                           // console.log(availableProducts);
                            return (
                                <React.Fragment key={planFamily}>
                                    <Card sx={{ margin: 1, width: '100%' }}>
                                        <CardHeader className="UWA_SecondaryCardHeader" sx={{ padding: '4px' }}
                                            avatar={
                                                <Avatar aria-label="Product Family">
                                                    <CardMembershipIcon />
                                                </Avatar>
                                            }
                                            title={planFamily + " SKU's"}
                                        />

                                        <Box>
                                            <CardContent >
                                                {(availableProductSkeleton ?
                                                    <>
                                                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                                    </>
                                                    :
                                                    
                                                        availableProducts.filter(item => item.itemFamilyId == planFamily).sort(function (a, b) {
                                                            return (b.external_name === null) - (a.external_name === null) || -(b.external_name > a.external_name) || +(b.external_name < a.external_name);
                                                        }).map((product) => {
                                                            //console.log(product);
                                                            var defaultAvatarColor = '';
                                                            var defaultAvatarCode = '';
                                                            var defaultAltLabel = '';
                                                            if (product.productType === 'Plan') {
                                                                defaultAvatarColor = '#0b5345';
                                                                defaultAvatarCode = 'P';
                                                                defaultAltLabel = "Plan";
                                                            }
                                                            else if (product.productType === 'Addon') {
                                                                defaultAvatarColor = '#b9770e';
                                                                defaultAvatarCode = 'A';
                                                                defaultAltLabel = "Addon";
                                                            }
                                                            else if (product.productType === 'Charge') {
                                                                defaultAvatarColor = '#8e44ad';
                                                                defaultAvatarCode = 'C';
                                                                defaultAltLabel = "Charge";
                                                            }
                                                            var isSpecialSKU = product.description.includes('Used for');
                                                            if (!isSpecialSKU) {
                                                                return (
                                                                    <React.Fragment key={product.id}>
                                                                        <div key={product.id}>
                                                                            <Typography component="div" variant="h6" className="UWA_PlanName" sx={{ display: "flex", alignItems: "center" }}>
                                                                                {currentProductSKU.current.findIndex(item => item.id === product.id) > -1 ?
                                                                                    (<FormControlLabel value={product.id} control={<Checkbox checked inputProps={{ 'title': product.id, 'data-producttype': product.productType, 'data-itemfamilyid': product.itemFamilyId, 'data-externalname': product.externalName, 'data-name': product.name }} />} label={product.externalName} key={product.id} onChange={handleProductSelectionCheckBoxChange} />
                                                                                    )
                                                                                    : (<FormControlLabel value={product.id} control={<Checkbox inputProps={{ 'title': product.id, 'data-producttype': product.productType, 'data-itemfamilyid': product.itemFamilyId, 'data-externalname': product.externalName, 'data-name': product.name }} />} label={product.externalName} key={product.id} onChange={handleProductSelectionCheckBoxChange} />
                                                                                    )}
                                                                                <Tooltip title={defaultAltLabel}><Avatar style={{ margin: "4px", fontSize: "1em" }} alt={defaultAltLabel} sx={{ width: 22, height: 22, bgcolor: defaultAvatarColor }}>{defaultAvatarCode}</Avatar></Tooltip>
                                                                            </Typography>
                                                                            <Typography className="UWA_PlanDescription" color="text.secondary" component="div" >
                                                                                <i> {product.description + ' (' + product.id + ') '}</i>
                                                                            </Typography>
                                                                        </div>
                                                                    </React.Fragment>
                                                                );
                                                            }

                                                        })
                                                    
                                                )}
                                                
                                               

                                            </CardContent>

                                        </Box>
                                    </Card>
                                </React.Fragment>
                            )
                        })}
                            
                      
                    </FormControl>
                </Grid>
            </>
        );
    }
   
    const LoadQtyAndDiscountsStep = () => {
        return (
            <>
                <Card sx={{ margin: 1, width: '100%' }}>
                    <CardHeader className="UWA_SecondaryCardHeader" sx={{ padding: '4px' }}
                        avatar={
                            <Avatar aria-label="Product Family">
                                <ShoppingCartIcon />
                            </Avatar>
                        }
                        title="Quantity Information"
                    />
                    <CardContent>
                       
                        <Grid container spacing={2}>
                            <Grid item xs={5} align="left" sx={{ borderBottom: 1, fontWeight:'bold' }}>
                                Item
                            </Grid>
                            <Grid item xs={2} align="left" sx={{ borderBottom: 1, fontWeight: 'bold' }}>
                                Type
                            </Grid>
                            <Grid item xs={2} align="center" sx={{ borderBottom: 1, fontWeight: 'bold'  }}>
                                Qty
                            </Grid>
                            <Grid item xs={2} align="left" sx={{ borderBottom: 1, fontWeight: 'bold' }}>
                                Billing Frequency
                            </Grid>
                            <Grid item xs={1} align="left" sx={{ borderBottom: 1, fontWeight: 'bold' }}>
                             </Grid>
                           
                           
                        </Grid>
                        {currentProductList.map((product) => {
                         return(   <React.Fragment key={product.id}>
                                <Grid container spacing={2}>
                                     <Grid item xs={5} align="left" sx={{ borderBottom: 1}}>
                                         {product.externalName}
                                     </Grid>
                                     <Grid item xs={2} align="left" sx={{ borderBottom: 1 }}>
                                     {product.productType}
                                     </Grid>
                                     <Grid item xs={2} align="center" sx={{ borderBottom: 1 }}>
                                     <TextField style={{ width: '100%', paddingTop: '9px' }}
                                         inputProps={{ min: 0, 'title': product.id, style: { textAlign: 'center' } }}
                                         name="qty"
                                         label=""
                                         size="small"
                                         variant="standard"
                                         defaultValue={product.qty}
                                         onChange={handleQuantityChange}
                                     />
                                     </Grid>
                                     <Grid item xs={2} sx={{ borderBottom: 1 }}>
                                     <FormControl sx={{ m: 1, minWidth: 120, }} size="small" variant="standard">
                                         <Select
                                             labelId="billingFrequency-label"
                                             id="billingFrequencyOption"
                                             value={product.billingFrequency}
                                             autoWidth
                                             label="Billing Frequency"
                                             onChange={handleBillingFrequencyChange}
                                             name={product.id}
                                          >
                                     
                                           {product.availableBillingFrequencies.map((billingFrequency) => {
                                               return (
                                                   <MenuItem value={billingFrequency} key={billingFrequency}>{billingFrequency}</MenuItem>
                                             )
                                           })}
                                        </Select>
                                       </FormControl>
                                 </Grid>
                                 <Grid item xs={1} align="left" sx={{ borderBottom: 1 }}>
                                     <LoadDiscountsButton product={product} updateDiscounts={handleUpdateDiscount} />
                                     
                                     
                                     </Grid>
                                </Grid>
                            </React.Fragment>
                            )
                        })}
                    </CardContent>
                </Card>
            </>
        )
    }
    const LoadReviewStep = () => {
        return (
            <>
                <Card sx={{ margin: 1, width: '100%' }}>
                    <CardHeader className="UWA_SecondaryCardHeader" sx={{ padding: '4px' }}
                        avatar={
                            <Avatar aria-label="Product Family">
                                <ShoppingCartIcon />
                            </Avatar>
                        }
                        title="Review Information and Finalize Cart"
                    />
                    <CardContent>

                        <Grid container spacing={2}>
                            <Grid item xs={4} align="left" sx={{ borderBottom: 1, fontWeight: 'bold' }}>
                                Item
                            </Grid>
                            <Grid item xs={1} align="center" sx={{ borderBottom: 1, fontWeight: 'bold' }}>
                                Qty
                            </Grid>
                            <Grid item xs={2} align="left" sx={{ borderBottom: 1, fontWeight: 'bold' }}>
                                Discounts
                            </Grid>
                            <Grid item xs={1} align="right" sx={{ borderBottom: 1, fontWeight: 'bold' }}>
                                MSRP
                            </Grid>
                            <Grid item xs={2} align="right" sx={{ borderBottom: 1, fontWeight: 'bold' }}>
                                Disc. Unit Price
                            </Grid>
                            <Grid item xs={2} align="right" sx={{ borderBottom: 1, fontWeight: 'bold' }}>
                                Ext. Price
                            </Grid>
                        </Grid>
                        {   
                            currentProductList.map((product) => {
                            var discountValue = "";
                            
                            
                            if (product.discountValue) {
                                discountValue = product.discountValue;
                                if (product.discountType === 'dollarDiscount') {
                                    discountValue = "$" + discountValue;
                                }
                                else {
                                    discountValue += "%";
                                }
                                discountValue += " (" + product.discountDuration + ")";
                            }
                            return (<React.Fragment key={product.id}>
                                <Grid container spacing={2}>
                                    <Grid item xs={4} align="left" sx={{ borderBottom: 1}}>
                                        {product.externalName}
                                    </Grid>
                                    <Grid item xs={1} align="center" sx={{ borderBottom: 1 }}>
                                        {product.qty}
                                    </Grid>
                                    <Grid item xs={2} align="left" sx={{ borderBottom: 1 }}>
                                        {discountValue}
                                    </Grid>
                                    <Grid item xs={1} align="right" sx={{ borderBottom: 1 }}>
                                        {commonfunctions.formatCartPriceToCurrency(product.unitPrice)}
                                    </Grid>
                                    <Grid item xs={2} align="right" sx={{ borderBottom: 1 }}>
                                        {commonfunctions.formatCartPriceToCurrency(product.extendedPrice)}
                                    </Grid>
                                    <Grid item xs={2} align="right" sx={{ borderBottom: 1 }}>
                                        {commonfunctions.formatCartPriceToCurrency(product.extendedAmount)}
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                            )
                            })}
                        <Grid container spacing={2}>
                            <Grid item xs={10} align="right" sx={{ fontWeight: 'bold' }}>
                                MSRP Total
                            </Grid>
                            <Grid item xs={2} align="right" sx={{ borderBottom: 1, fontWeight: 'bold' }}>
                                {commonfunctions.formatCartPriceToCurrency(currentCartSubTotal)}
                            </Grid>
                            <Grid item xs={10} align="right" sx={{ fontWeight: 'bold' }}>
                                Discount(s)
                            </Grid>
                            <Grid item xs={2} align="right" sx={{ borderBottom: 1, fontWeight: 'bold', color: 'red' }}>
                                {commonfunctions.formatCartPriceToCurrency(currentCartDiscount)}
                            </Grid>
                            <Grid item xs={10} align="right" sx={{ fontWeight: 'bold' }}>
                                Total
                            </Grid>
                            <Grid item xs={2} align="right" sx={{ borderBottom: 1, fontWeight: 'bold' }}>
                                {commonfunctions.formatCartPriceToCurrency(currentCartTotal)}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </>
        )
    }
    const load_CurrentCustomerDetails = async () => {
        if (currentCustomerID.current === "") {
            return;
        }
        updateInfoMsg("");
        updateErrorMsg("");
        var endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
        const serviceUrl = endPointURL + 'api/NetSuite/GetNSCustomers';
        const requestOptions = {
            SearchQuery: currentCustomerID.current
        };

        await axios.post(serviceUrl, requestOptions)
            .then(res => {
                if (res.status === 200) {
                    currentCustomerIsLoaded.current = true;
                    setCurrentCustomerDetails(res.data.items);
                }

            })
            .catch((error) => {
                console.log('Error:', error);
                if (error.response.data.message) {
                    updateErrorMsg(error.response.data.message);
                }
                else {
                    updateErrorMsg(error.response.data);
                }
            });
    }




    const LoadShoppingCartStep = (x) => {
        if (x.currentStep === 0) {
            return (<LoadCustomerSelectionStep></LoadCustomerSelectionStep>)
        }
        else if (x.currentStep === 1) {
           
            return (<LoadProductFamilySelectionStepWithCheckBox/>);
        }
        else if (x.currentStep === 2) {
            return (<LoadProductSelectionStepWithCheckBox />);

        }
        else if (x.currentStep === 3) {
            return (<LoadQtyAndDiscountsStep />);
        }
        else if (x.currentStep === 4) {
           
            return (<LoadReviewStep />);
        }
        else return null;
    }


    useEffect(() => {
        if (activeStep == 0) {
           
            if (!currentCustomerIsLoaded.current && currentCustomerID.current != '') {
                toggleWaitModal(true);
                load_CurrentCustomerDetails();
                toggleWaitModal(false);
            }
        }

        if (activeStep == 1) {
            if (productFamilies.length === 0) {
                get_AvailableProductFamilies();
            }
        }
        if (activeStep == 2) {
            if (!currentProductSKUIsLoaded.current) {
                get_AvailableProducts();
            }
        }
        if (activeStep == 4) {
          
        }
       
        if (activeStep == 5) {
            createShoppingCart();
        }


    }, [activeStep]);

   
    return (
        <Box sx={{ flex: '1 1 auto' }}>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    if (isStepOptional(index)) {
                        labelProps.optional = (
                            <Typography variant="caption">Optional</Typography>
                        );
                    }
                    if (isStepSkipped(index)) {
                        stepProps.completed = false;
                    }

                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps} sx={{ display: "flex", justifyContent: "space-between", paddingLeft: "2", paddingRight :"2",  whiteSpace: "nowrap" }}>{label}</StepLabel>

                        </Step>
                    );
                })}
            </Stepper>
            {activeStep === steps.length ? (
                <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                        You have completed the steps for creating a Client Shopping Cart. If there are no errors, you will be redirected to the Shopping Cart page to review your selection.
                    </Typography>
                    {errorMsg ? <Alert severity="error" >{errorMsg}</Alert> : ""}
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button onClick={handleReset}>Reset</Button>
                    </Box>
                </React.Fragment>
            ) : (
                    <React.Fragment>
                        {showWaitModal ? (
                            <ModalWait

                            />
                        ) : null}
                        <Grid container sx={{ padding: '4px' }}>
                            <Grid item xs={12} md={12}>
                                <LoadShoppingCartStep currentStep={activeStep} />
                            </Grid>

                        </Grid>
                        {infoMsg ? <Alert severity="info" >{infoMsg}</Alert> : ""}
                        {errorMsg ? <Alert severity="error" >{errorMsg}</Alert> : ""}
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Button
                                color="inherit"
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                sx={{ mr: 1 }}
                            >
                                Back
                            </Button>
                            <Box sx={{ flex: '1 1 auto' }} />
                            {isStepOptional(activeStep) && (
                                <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                                    Skip
                                </Button>
                            )}

                            <Button onClick={handleNext}>
                                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                            </Button>
                        </Box>
                    </React.Fragment>
            )}
        </Box>
    );
}