import React, { useEffect, useState, useRef } from 'react';
import Box from '@mui/material/Box';
import { red } from '@mui/material/colors';
import Alert from '@mui/material/Alert';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Card from '@mui/material/Card';
import Tooltip from "@mui/material/Tooltip";
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Radio, RadioGroup, FormControl, FormControlLabel, InputLabel, CardHeader, CardMedia, Avatar, Select, MenuItem, Switch } from '@mui/material';
import { Container, Row, Col } from 'reactstrap';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { ModalWait } from '../WaitModal';
import { commonfunctions } from '../../_helpers/commonfunctions';
import ClearIcon from '@mui/icons-material/Clear';
import axios from 'axios';
import InputAdornment from '@mui/material/InputAdornment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import dayjs from 'dayjs';
import ConnectingAirportsIcon from '@mui/icons-material/ConnectingAirports';
import PersonIcon from '@mui/icons-material/Person';

import RWABadge from './MetaDataComponents/RWABadge.js';
import RWAWBBadge from './MetaDataComponents/RWAWBBadge.js';
import UnknownBadge from './MetaDataComponents/UnknownBadge.js';
import AGPTextronBadge from './MetaDataComponents/AGPTextronBadge.js';
import PermissionsGate from '../../security/PermissionGate';

import { SCOPES } from '../../security/permission-maps';   






export default function CreateNewSubscription(props) {
    const steps = ['Start', 'Pick Plan', 'Billing/Addons', 'Items', 'Finalize'];

    const [expandedSection, setExpandedSection] = React.useState(0);

    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());
    const customerSearchQuery = useRef("");
    /**Current Values indicate these are used to build out the subscription****/
    const currentCustomerID = useRef(props.currentCustomerID);
    const currentPlanID = useRef("");
    const [currentSKUSupportsTrial, setCurrentSKUSupportsTrial] = useState(false);
    const [currentSubscriptionStartDate, setStartDate] = useState(dayjs());
    const [currentCustomerDetails, setCurrentCustomerDetails] = useState([]);
    const [currentPlanDetails, setCurrentPlanDetails] = useState([]);
    const [currentPlanItemPriceID, setCurrentPlanItemPriceID] = useState("");
    const [currentBillingFrequencies, setCurrentBillingFrequencies] = useState([]);
    const [currentAddons, setCurrentAddons] = useState([]);
    const [currentExtendedInfoItems, setCurrentExtendedInfoItems] = useState([]);
    const [customDiscountValue, setCustomDiscountValue] = useState(0);
    const [customDiscountType, setCustomDiscountType] = useState("percentageDiscount");
    const [customDiscountDuration, setCustomDiscountDuration] = useState(null);
    //const currentEstimate = useRef([]);
    const [currentEstimate, setCurrentEstimate] = useState([]);
    const [consolidatedInvoicing, setConsolidatedInvoicing] = useState(false);
    const [completeInvoices, setCompleteInvoices] = useState(false);
    const [applyDiscount, setDiscountFlag] = useState(false);
    const [isTrial, setIsTrialFlag] = useState(false);
    const [notifyUsers, setNotificationFlag] = useState(false);
    const [trialInterval, setTrialInterval] = useState(0);
    const [trialIntervalFieldDisabled, setTrialIntervalFieldDisabledFlag] = useState(true);
    const currentCustomerIsLoaded = useRef(false);
    const refreshSubscriptionEstimate = useRef(true);
  

   

    const newExtendedInfo_Key = useRef("");
    const newExtendedInfo_FName = useRef("");
    const newExtendedInfo_LName = useRef("");
    const newExtendedInfo_Email = useRef("");
    const newExtendedInfo_AddOns = useRef([]);


    const [availablePlans, setAvailablePlans] = useState([]);
    const [planFamilies, setPlanFamilies] = useState([]);
    const [searchResultCount, updateSearchResultCount] = useState(0);
    const [errorMsg, updateErrorMsg] = useState("");
    const [infoMsg, updateInfoMsg] = useState("");
    const [showWaitModal, toggleWaitModal] = useState(false);
    const [userEntryFieldsButtonState, toggleUserEntryClearFieldButton] = useState(true);
    const [dpsStatusCheckStatus, updateDPSCheckStatus] = useState(0);
    const [customerSearchResults, updateCustomerSearchResults] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [ItemsPerPage] = useState(2);
    //const [applyDiscount, setDiscountFlag] = useState(false);
    //const [customDiscountValue, setCustomDiscountValue] = useState(0);
    //const [customDiscountType, setCustomDiscountType] = useState("percentageDiscount");

    const currentPosition = ((pageNumber * ItemsPerPage) - ItemsPerPage);
    let paginatedCustomerSearchResults = customerSearchResults.slice(currentPosition, currentPosition + ItemsPerPage);



    const handlePrevPage = () => {

        if (pageNumber === 1) return;
        toggleWaitModal(true);
        setPageNumber(pageNumber - 1);
        toggleWaitModal(false);

    }
    const handleNextPage = () => {
        if (Math.round(searchResultCount / (pageNumber + 1)) >= ItemsPerPage) {
            toggleWaitModal(true);
            setPageNumber(pageNumber + 1);
            toggleWaitModal(false);

        }
        else return;

    }
    const isStepOptional = (step) => {
        return step === -1;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        updateErrorMsg("");
        switch (activeStep) {
            case 0:
                if (!currentCustomerIsLoaded.current) {
                    updateErrorMsg("You must select a customer before proceeding to the next step");
                    return;
                }
                break;
            case 1:
                if (currentPlanID.current === "") {
                    updateErrorMsg("You must select a plan before proceeding to the next step");
                    return;
                }
                getBillingFrequencies(currentPlanDetails.id);
                break;
            case 2:
                if (currentPlanItemPriceID === "") {
                    updateErrorMsg("You must select a billing frequency before proceeding to the next step");
                    return;
                }
                if ((applyDiscount && customDiscountValue <= 0) || (applyDiscount && customDiscountValue === ''))
                {
                    updateErrorMsg("You must enter a discount value before proceeding to the next step");
                    return;
                }
                if ((applyDiscount && customDiscountType === "percentageDiscount" && customDiscountValue > 100)) {
                    updateErrorMsg("Discount Percentage cannot be more than 100%");
                    return;
                }
                if (applyDiscount && !customDiscountDuration) {
                    updateErrorMsg("You must enter a discount duration before proceeding to the next step");
                    return;
                }
                if (isTrial && isNaN(+trialInterval)) 
                {
                    updateErrorMsg("You must enter a duration for the trial before proceeding to the next step");
                    return;
                }
                break;
            case 3:
                if (currentExtendedInfoItems.length < 1 && (currentPlanDetails.extended_plan_type === 'REGISTRY' || currentPlanDetails.extended_plan_type === 'USER')) {
                    if (currentPlanDetails.extended_plan_type === 'REGISTRY') {
                        updateErrorMsg("You must enter at least registry on this subscription");
                    }
                    if (currentPlanDetails.extended_plan_type === 'USER') {
                        updateErrorMsg("You must enter at least user on this subscription");
                    }
                    return;
                }
                break;
        }

        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        updateErrorMsg("");
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const handlePlanSelectionChange = (e) => {
        currentPlanID.current = (e.target).value;
        var currentPlanDetails = availablePlans.find(plan => plan.id == currentPlanID.current);
        setCurrentPlanDetails(currentPlanDetails);
        setCustomDiscountDuration(currentPlanDetails && currentPlanDetails.plan_discount_type ? currentPlanDetails.plan_discount_type : null);
        //console.log(currentPlanDetails);
        setCurrentAddons([]);
        setCurrentExtendedInfoItems([]);
        setCurrentPlanItemPriceID("");
        setCurrentSKUSupportsTrial(false);
        setTrialInterval(0);
        resetAddOnItems();
    }
    const handleSearchFieldChange = (e) => {
        customerSearchQuery.current = (e.target.value);
        //if (e.target.value.length >= 3) {
        //    getNSSearchResults(e.target.value);
        //}
    }
    const handleNotifyUsersChange = (e) => {
        setNotificationFlag(!notifyUsers);
    }
    const handleTrialIntervalChange = (e) => {
        setTrialInterval(e.target.value);
    }
    const handleBillingFrequencyChange = (e) => {
        setCurrentPlanItemPriceID(e.target.value);
        var currentItemPriceID = currentPlanDetails.planPriceList.find(itemPrice => itemPrice.id == e.target.value);
        if (currentItemPriceID.trialPeriod != null) {
            setCurrentSKUSupportsTrial(true);
            setTrialInterval(currentItemPriceID.trialPeriod);
        }
        else {
            setCurrentSKUSupportsTrial(false);
        }
        //console.log(currentItemPriceID);
    }
    const btnSearch_Click = async () => {
        await getNSSearchResults(customerSearchQuery.current);
    }
    const btnClearResults_Click = async () => {
        updateCustomerSearchResults([]);
        updateSearchResultCount(0);
        setPageNumber(1);
        customerSearchQuery.current = ("");
        currentPlanID.current = ("");
        setCurrentPlanDetails([]);
    }
    const getNSSearchResults = async (searchValue) => {
        if (!searchValue) {
            updateErrorMsg("You must enter a value to search for!");
            return;
        }
        toggleWaitModal(true);
        updateInfoMsg("");
        updateErrorMsg("");
        var endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
        const serviceUrl = endPointURL + 'api/NetSuite/GetNSCustomers';
        customerSearchQuery.current = (searchValue);
        const requestOptions = {
            SearchQuery: customerSearchQuery.current
        };
        await axios.post(serviceUrl, requestOptions)
            .then(res => {
                if (res.status === 200) {
                    toggleWaitModal(false);
                    setPageNumber(1);
                    updateSearchResultCount(res.data.count);
                    updateCustomerSearchResults(res.data.items);
                    if (res.data.count === 0) {
                        updateInfoMsg("No Customers found matching the search criteria");
                    }
                }

            })
            .catch((error) => {
                console.log('Error:', error);
                toggleWaitModal(false);
                if (error.response.data.message) {
                    updateErrorMsg(error.response.data.message);
                }
                else {
                    updateErrorMsg(error.response.data);
                }

            });

    }
    const load_CurrentCustomerDetails = async () => {
        if (currentCustomerID.current === "") {
            return;
        }
        updateInfoMsg("");
        updateErrorMsg("");
        var endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
        const serviceUrl = endPointURL + 'api/NetSuite/GetNSCustomers';
        const requestOptions = {
            SearchQuery: currentCustomerID.current
        };
        
        await axios.post(serviceUrl, requestOptions)
            .then(res => {
                if (res.status === 200) {
                    currentCustomerIsLoaded.current = true;
                    setCurrentCustomerDetails(res.data.items);
                }

            })
            .catch((error) => {
                console.log('Error:', error);
                if (error.response.data.message) {
                    updateErrorMsg(error.response.data.message);
                }
                else {
                    updateErrorMsg(error.response.data);
                }
            });
    }

    const getConsolidatedInvoicing = async (customerID) => {
        if (!customerID) {
            updateErrorMsg("Customer ID cannot be null");
            return;
        }
        updateInfoMsg("");
        updateErrorMsg("");
        var endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
        const serviceUrl = endPointURL + 'api/ChargebeeWidgets/GetCustomerConsolidatedInvoicing/' + customerID;
        await axios.get(serviceUrl)
            .then(res => {
                if (res.status === 200) {
                    setConsolidatedInvoicing(res.data.result);
                }
            })
            .catch((error) => {
                console.log('Error:', error);
                if (error.response.data.message) {
                    updateErrorMsg(error.response.data.message);
                }
                else {
                    updateErrorMsg(error.response.data);
                }
            });
    }

    const updateConsolidatedInvoicing = async (consolidatedInvoicing) => {
        if (!currentCustomerID.current) {
            updateErrorMsg("Customer ID cannot be null");
            return;
        }
        updateInfoMsg("");
        updateErrorMsg("");
        var endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
        const serviceUrl = endPointURL + 'api/ChargebeeWidgets/UpdateCustomerConsolidatedInvoicing';
        const requestOptions = {
            id: currentCustomerID.current,
            consolidated_invoicing: consolidatedInvoicing
        };
        await axios.post(serviceUrl, requestOptions)
            .then(res => {
                if (res.status === 200) {
                    // we're good
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                if (error.response.data.message) {
                    updateErrorMsg(error.response.data.message);
                }
                else {
                    updateErrorMsg(error.response.data);
                }
            });
    }

    const getBillingFrequencies = async (planId) => {
        if (!planId) {
            updateErrorMsg("Plan ID cannot be null");
            return;
        }
        updateInfoMsg("");
        updateErrorMsg("");
        var endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
        const serviceUrl = endPointURL + 'api/ChargebeeWidgets/GetPlanBillingFrequencies/' + planId;
        await axios.get(serviceUrl)
            .then(res => {
                if (res.status === 200) {
                    setCurrentBillingFrequencies(res.data.result);
                }
            })
            .catch((error) => {
                console.log('Error:', error);
                if (error.response.data.message) {
                    updateErrorMsg(error.response.data.message);
                }
                else {
                    updateErrorMsg(error.response.data);
                }
            });
    }

    const getNewSubscriptionEstimate = async () => {
        console.log("getting estimate");
        var endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
        const serviceUrl = endPointURL + 'api/ChargebeeWidgets/GetNewSubscriptionEstimate';
        let planPriceInfo = currentPlanDetails.planPriceList.filter(item => item.id === currentPlanItemPriceID);
        const requestOptions = {
            CustomerID: currentCustomerID.current,
            CustomerInfo: currentCustomerDetails[0],
            StartDate: currentSubscriptionStartDate,
            PlanItemPriceID: currentPlanItemPriceID,
            PlanPricingModel: planPriceInfo[0].pricingModel,
            PlanPeriodUnit: planPriceInfo[0].periodUnit,
            PlanPeriod: planPriceInfo[0].period,
            DiscountType: customDiscountType,
            Discount: customDiscountValue,
            DiscountDuration: customDiscountDuration,
            PlanAddons: currentAddons,
            ExtendedPlanType: currentPlanDetails.extended_plan_type,
            ExtendedInfo: currentExtendedInfoItems,
            InvoiceImmediately: !consolidatedInvoicing
        };
        //currentAddons.filter(item => item.id !== addonID))
        await axios.post(serviceUrl, requestOptions)
            .then(res => {
                if (res.status === 200) {
                    setCurrentEstimate(res.data.estimateDetails);
                    //currentEstimate.current = res.data.estimateDetails;
                }

            })
            .catch((error) => {
                console.log('Error:', error);
                if (error.response.data.message) {
                    updateErrorMsg(error.response.data.message);
                }
                else {
                    updateErrorMsg(error.response.data);
                }
            });
      
          
         
    }
    const createNewSubscription = async () => {
        var endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
        const serviceUrl = endPointURL + 'api/ChargebeeWidgets/CreateNewSubscription';
        let planPriceInfo = currentPlanDetails.planPriceList.filter(item => item.id === currentPlanItemPriceID);
        const requestOptions = {
            CustomerID: currentCustomerID.current,
            CustomerInfo: currentCustomerDetails[0],
            StartDate: currentSubscriptionStartDate,
            PlanItemPriceID: currentPlanItemPriceID,
            PlanPricingModel: planPriceInfo[0].pricingModel,
            PlanPeriodUnit: planPriceInfo[0].periodUnit,
            PlanPeriod: planPriceInfo[0].period,
            PlanDiscountType: planPriceInfo[0].plan_discount_type,
            DiscountType: customDiscountType,
            Discount: customDiscountValue,
            DiscountDuration: customDiscountDuration,
            PlanAddons: currentAddons,
            ExtendedPlanType: currentPlanDetails.extended_plan_type,
            ExtendedInfo: currentExtendedInfoItems,
            InvoiceImmediately: !consolidatedInvoicing,
            IsTrial: isTrial,
            TrialInterval: trialInterval
        };

        await axios.post(serviceUrl, requestOptions)
            .then(res => {
                if (res.status === 200) {
                    if (completeInvoices) {
                        invoiceUnbilledCharges(currentCustomerID.current);
                    }

                    var redirectUrl = '/Admin/ManageSubscription?SubscriptionID=' + res.data.subscriptionID + "&CustomerID=" + currentCustomerID.current;
                    setTimeout(() => {
                        window.location.href = redirectUrl;
                    }, 1000);
                }

            })
            .catch((error) => {
                console.log('Error:', error);
                if (error.response.data.message) {
                    updateErrorMsg(error.response.data.message);
                }
                else {
                    updateErrorMsg(error.response.data);
                }
            });
    }
    const get_AvailablePlans = async () => {
        updateInfoMsg("");
        updateErrorMsg("");
        var endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
        const serviceUrl = endPointURL + 'api/ChargebeeWidgets/GetAvailablePlans';

       // toggleWaitModal(true);
        await axios.get(serviceUrl)
            .then(res => {
                if (res.status === 200) {
                    //availablePlans.current = (res.data.availablePlans);
                    //console.log(res.data.availablePlans);
                    setAvailablePlans(res.data.availablePlans);
                   // toggleWaitModal(false);
                    //Lets create the list of plan families.
                    var planFamilies = res.data.availablePlans.map(x => x.item_family_id);
                    var uniquePlanFamilies = [...new Set(planFamilies)];
                    setPlanFamilies(uniquePlanFamilies);
                }

            })
            .catch((error) => {
                console.log('Error:', error);
                if (error.response.data.message) {
                    updateErrorMsg(error.response.data.message);
                }
                else {
                    updateErrorMsg(error.response.data);
                }
              //  toggleWaitModal(false);
            });
    }

    const invoiceUnbilledCharges = async (customerId) => {
        if (!customerId) {
            updateErrorMsg("Customer ID cannot be null");
            return;
        }
        updateInfoMsg("");
        updateErrorMsg("");
        var endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
        const serviceUrl = endPointURL + 'api/ChargebeeWidgets/InvoiceUnbilledCharges';
        const requestOptions = {
            id: customerId
        };
        await axios.post(serviceUrl, requestOptions)
            .then(res => {
                if (res.status === 200) {
                    console.log("successfully called InvoiceUnbilledCharges for " + customerId);
                }
            })
            .catch((error) => {
                console.log('Error:', error);
                if (error.response.data.message) {
                    updateErrorMsg(error.response.data.message);
                }
                else {
                    updateErrorMsg(error.response.data);
                }
            });
    }

    const showSearchSection = () => {
        customerSearchQuery.current = "";
        currentCustomerIsLoaded.current = false;
        setCurrentCustomerDetails([]);

    }
    const handleDiscountTypeChange = (event) => {
        setCustomDiscountValue(0);
        setCustomDiscountType(event.target.value);
    }
    const handleCustomDiscountValueChange = (event) => {
        setCustomDiscountValue((event.target).value);
    }
    const handleDiscountDurationChange = (event) => {
        setCustomDiscountDuration((event.target).value);
    }
    const handleAddOnSelectionChange = (e) => {
        //lets see if item is in array, otherwise add it. 
        var addonID = e.currentTarget.dataset.addonid;
        var addonName = e.currentTarget.dataset.addonname;
        let addOnItem = {
            id: addonID,
            name: addonName
        };
         if (e.target.checked) {
            //we are adding to array.
            var index = -1;
            if (currentAddons.length > 0) {
                index = currentAddons.findIndex(item => item.id === addonID);
            }
            
            index === -1 ? (setCurrentAddons(currentAddons => [...currentAddons, addOnItem])) : null;
       }
        else {
            //We might need to remove from array
             var index = currentAddons.findIndex(item => item.id === addonID);
            index > -1 ? (setCurrentAddons(currentAddons.filter(item => item.id !== addonID))): null;
        }
        //console.log(currentAddons);
        resetAddOnItems();
        setCurrentExtendedInfoItems([]);
    }
    const handleNewExtendedInfoAddonChange = (e) => {
        var addonID = e.currentTarget.dataset.addonid;
        var addonName = e.currentTarget.dataset.addonname;
        let extAddOnInfo = newExtendedInfo_AddOns.current;
        let addOnItem = {
            id: addonID,
            name: addonName
        };
        if (e.target.checked) {
            //we are adding to array.
            var index = -1;
            if (newExtendedInfo_AddOns.current.length > 0) {
                index = newExtendedInfo_AddOns.current.findIndex(item => item.id === addonID);
            }

            //index === -1 ? (extAddOnInfo.current.push((newExtendedInfo_AddOns => [...newExtendedInfo_AddOns, addOnItem])) : null;
            index === -1 ? (extAddOnInfo.push(addOnItem)) : null;
        }
        else {
            //We might need to remove from array
            var index = extAddOnInfo.findIndex(item => item.id === addonID);
            index > -1 ? (extAddOnInfo.pop(addOnItem)) : null;
        }
        
    }
    const handleApplyDiscountsChange = () => {
        if (applyDiscount) {
            setCustomDiscountValue(0);
            setCustomDiscountDuration(null);
        }
        else {
            setCustomDiscountDuration(currentPlanDetails && currentPlanDetails.plan_discount_type ? currentPlanDetails.plan_discount_type : null);
        }
        setDiscountFlag(!applyDiscount);
    }
    const handleIsTrialSubscription = () => {
        setIsTrialFlag(!isTrial);
      
        setTrialIntervalFieldDisabledFlag(!trialIntervalFieldDisabled);
    }
    const handleAddUserFormChange = (e) => {
        
        if (e.target.name === 'registryName') {
            newExtendedInfo_Key.current = (e.target.value.toUpperCase());
        }

        if (e.target.name === 'userName') {
            newExtendedInfo_Key.current = (e.target.value);
        }
        if (e.target.name === 'firstName') {
            newExtendedInfo_FName.current = (e.target.value);
        }
        if (e.target.name === 'lastName') {
            newExtendedInfo_LName.current = (e.target.value);
        }
        if (e.target.name === 'email') {
            newExtendedInfo_Email.current = (e.target.value);
        }

    }
    const handleUnbilledChargesChange = () => {
        setConsolidatedInvoicing(!consolidatedInvoicing);
    }
    const handleCompleteInvoicesChange = () => {
        setCompleteInvoices(!completeInvoices);
    }
    const userPassesDPSCheck = async() => {
       
        var endPointURL = process.env.REACT_APP_API_URL;
        if (process.env.REACT_APP_API_URL === undefined) {
            endPointURL = "/";
        }
        //console.log(currentCustomerDetails[0]);
        const dpsuserinfo = {
            firstName: newExtendedInfo_FName.current,
            lastName: newExtendedInfo_LName.current,
            email: newExtendedInfo_Email.current,
            companyName: currentCustomerDetails[0].name,
            customerID: currentCustomerID.current 
        };
        const serviceUrl = endPointURL + 'api/ChargebeeWidgets/ValidateUserViaDPS';
        await axios.post(serviceUrl, dpsuserinfo)
            .then(res => {
                if (res.status === 200) {
                    if (res.data.dpsResults.hits !== null) {
                        updateErrorMsg("There was an issue verifying this user (" + dpsuserinfo.firstName + " " + dpsuserinfo.lastName + ") via the DPS Service. ");
                        toggleUserEntryClearFieldButton(false);
                        updateDPSCheckStatus(1);
                        return false;
                    }
                    else {
                        let newExtendedItem = {
                            'key': newExtendedInfo_Key.current,
                            'firstName': newExtendedInfo_FName.current,
                            'lastName': newExtendedInfo_LName.current,
                            'email': newExtendedInfo_Email.current,
                            'notifyUser': notifyUsers,
                            'addons': newExtendedInfo_AddOns.current
                        };
                        (setCurrentExtendedInfoItems(currentExtendedInfoItems => [...currentExtendedInfoItems, newExtendedItem]));
                        updateDPSCheckStatus(2);
                        return true;

                    }

                }
                else {
                    updateErrorMsg("There was an issue verifying this user (" + dpsuserinfo.firstName + " " + dpsuserinfo.lastName + ") via the DPS Service. ");
                    toggleUserEntryClearFieldButton(false);
                    updateDPSCheckStatus(1);
                }
            })
            .catch((error) => {
                console.log('Error:', error);
                if (error.response.data.message) {
                    updateErrorMsg(error.response.data.message);
                }
                else {
                    updateErrorMsg(error.response.data);
                   
                }
                updateDPSCheckStatus(1);
                return false;
            });
            
    }
    const submitNewExtendedInfoItem = async() => {
        //Perform validation of the required fields depending on whether it's a user or registry based.
        //For User based they must enter username, first name, last name and email.
        updateErrorMsg("");
        if (currentPlanDetails && currentPlanDetails.extended_plan_type === 'USER') {
            if (newExtendedInfo_Key.current === '') {
                updateErrorMsg("You must enter a Username");
                return;
            }
            if (newExtendedInfo_FName.current === '') {
                updateErrorMsg("You must enter a First Name");
                return;
            }
            if (newExtendedInfo_LName.current === '') {
                updateErrorMsg("You must enter a Last Name");
                return;
            }
            if (newExtendedInfo_Email.current === '') {
                updateErrorMsg("You must enter an Email Address");
                return;
            }
        }
        else {
            if (newExtendedInfo_Key.current === '') {
                updateErrorMsg("You must enter a Tail #");
                return;
            }
        }
        let newExtendedItem = {
            'key': newExtendedInfo_Key.current,
            'firstName': newExtendedInfo_FName.current,
            'lastName': newExtendedInfo_LName.current,
            'email': newExtendedInfo_Email.current,
            'notifyUser': notifyUsers,
            'addons': newExtendedInfo_AddOns.current
        };
      
        var index = currentExtendedInfoItems.findIndex(item => item.key === newExtendedInfo_Key.current);
        if (index === -1) {
            toggleWaitModal(true);
            //Let us run a DPS check if this is for adding a new user.
            if (currentPlanDetails && currentPlanDetails.extended_plan_type === 'USER') {
                try {
                    let result = await userPassesDPSCheck(newExtendedItem);
                   
                    if (result) {
                       // (setCurrentExtendedInfoItems(currentExtendedInfoItems => [...currentExtendedInfoItems, newExtendedItem]));
                        resetAddOnItems();
                    }
                }
                catch(err) {

                }
                
              

                //if (await userPassesDPSCheck(newExtendedItem) === false) {
                //    updateErrorMsg("There was an issue verifying this user (" + newExtendedItem.firstName + " " + newExtendedItem.lastName + ") via the DPS Service. ");
                //    toggleUserEntryClearFieldButton(false);
                //    return;
                //}
                //else {
                //    //Lets add to the array for user info
                //    
                //    resetAddOnItems();
                //}
            }
            else {
                //Lets add to the array for registry info
                (setCurrentExtendedInfoItems(currentExtendedInfoItems => [...currentExtendedInfoItems, newExtendedItem]));
                resetAddOnItems();
            }
            toggleWaitModal(false);
           
        }
        else {
            updateErrorMsg("You must select a unique username or Tail #");
        }
       
    }
    
    const AddNewUserForm = () => {
        return ((
            (<Grid container sx={{ padding: '3px', margin: '3px', border: '1px solid #ccc', borderRadius: '5px', width: '99%' }} spacing={2}>
                <Grid item xs={12} sx={{ borderBottom: '1px solid #ccc' }}>
                    Add New User
                </Grid>
                <Grid item xs={12}>
                    <TextField style={{ width: '100%' }}
                        name="userName"
                        id="userName"
                        label="Username"
                        size="small"
                        required
                        inputProps={{ 'maxLength': 15 }}
                        defaultValue={newExtendedInfo_Key.current}
                        //onChange={event => handleAddUserFormChange(event.target.value)}
                        onChange={handleAddUserFormChange}
                     />
                </Grid>
                <Grid item xs={12}>
                    <TextField style={{ width: '100%' }}
                        name="firstName"
                        id="firstName"
                        label="First Name"
                        size="small"
                        required
                    //onChange={event => setExtendedInfoFName(event.target.value)}
                        defaultValue={newExtendedInfo_FName.current}
                        onChange={handleAddUserFormChange}
                        inputProps={{ 'data-fieldname': 'firstName' }}

                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField style={{ width: '100%' }}
                        name="lastName"
                        id="lastName"
                        label="Last Name"
                        size="small"
                        required
                    //onChange={event => setExtendedInfoLName(event.target.value)}
                        defaultValue={newExtendedInfo_LName.current}
                        onChange={handleAddUserFormChange}
                        inputProps={{ 'data-fieldname': 'lastName' }}

                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField style={{ width: '100%' }}
                        name="email"
                        id="email"
                        label="Email"
                        size="small"
                        required
                    //onChange={event => setExtendedInfoEmail(event.target.value)}
                        defaultValue={newExtendedInfo_Email.current}
                        onChange={handleAddUserFormChange}
                        inputProps={{ 'data-fieldname': 'email' }}

                    />
                </Grid>
                {currentAddons.length > 0 ? (
                    <Grid item xs={12}>
                    <hr />
                    </Grid>
                ) : (null)}
                
                <Grid item xs={12}>
                    {currentAddons && currentAddons.map(item => (
                        (newExtendedInfo_AddOns.current?.findIndex(addon => addon.id === item.id) > -1) ? (
                            <FormControlLabel control={<Switch onChange={handleNewExtendedInfoAddonChange} checked inputProps={{ 'data-addonid': item.id, 'data-addonname': item.name }} />} label={item.name + ' (' + item.id + ')'} key={item.id} />
                        ) : (
                            <FormControlLabel control={<Switch onChange={handleNewExtendedInfoAddonChange} inputProps={{ 'data-addonid': item.id, 'data-addonname': item.name }} />} label={item.name + ' (' + item.id + ')'} key={item.id} />
                        )
                    ))}

                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" onClick={submitNewExtendedInfoItem}>Submit</Button>&nbsp;&nbsp;
                    <Button variant="contained" onClick={resetAddOnItems} disabled={userEntryFieldsButtonState}>Clear</Button>
                </Grid>
            </Grid>)
        ));
    }
    const AddNewRegistryForm = () => {
        return (
            <Grid container sx={{ padding: '3px', margin: '3px', border: '1px solid #ccc', borderRadius: '5px', width: '99%' }} spacing={2}>
                <Grid item xs={12} sx={{ borderBottom: '1px solid #ccc' }}>
                    Add New Registry/Tail
                </Grid>
                <Grid item xs={12}>
                    <TextField style={{ width: '100%' }}
                        name="registryName"
                        label="Registry/Tail #"
                        size="small"
                        required
                        onChange={handleAddUserFormChange}
                        autoFocus
                        inputProps={{ maxLength: 8, style: { textTransform: "uppercase" } }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <hr />
                </Grid>
                <Grid item xs={12}>
                    {currentAddons && currentAddons.map(item => (
                        (newExtendedInfo_AddOns.current?.findIndex(addon => addon.id === item.id) > -1) ? (
                            <FormControlLabel control={<Switch onChange={handleNewExtendedInfoAddonChange} checked inputProps={{ 'data-addonid': item.id, 'data-addonname': item.name }} />} label={item.name + ' (' + item.id + ')'} key={item.id} />
                        ) : (
                            <FormControlLabel control={<Switch onChange={handleNewExtendedInfoAddonChange} inputProps={{ 'data-addonid': item.id, 'data-addonname': item.name }} />} label={item.name + ' (' + item.id + ')'} key={item.id} />
                        )




                    ))}

                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" onClick={submitNewExtendedInfoItem}>Submit</Button>
                </Grid>
            </Grid>
        
        );

    }
    const LoadEstimateDetails = () => {
        //console.log(currentEstimate);
        //console.log("INSIDE BLOCK");
        
        return (
            <>
                <Card square={true} style={{ marginTop: "1px", backgroundColor: "#EBF5FB" }} >
                    <CardContent>
                        <Typography gutterBottom variant="h6" component="div">
                            Subscription Details 
                        </Typography>
                        <Grid container>
                            <Grid item xs={4} align="left" sx={{ borderBottom: 1 }}>
                                Item
                            </Grid>
                            <Grid item xs={2} align="center" sx={{ borderBottom: 1 }}>
                               Qty
                            </Grid>
                            <Grid item xs={2} align="center" sx={{ borderBottom: 1 }}>
                                Unit Price
                            </Grid>
                            <Grid item xs={2} align="center" sx={{ borderBottom: 1 }}>
                                Discount
                            </Grid>
                            <Grid item xs={2} align="right" sx={{ borderBottom: 1 }}>
                                Sub Total
                            </Grid>
                            {currentEstimate.items && currentEstimate.items.map((item) => {
                                console.log(item);
                                return (<React.Fragment key={item.itemID}>

                                    <Grid container >
                                        <Grid item xs={4} align="left" sx={{ borderBottom: 1 }}>
                                            {item.itemDescription}
                                            {(item.dateFrom && item.dateTo) ? (
                                                <>
                                                    <br /><i>{commonfunctions.formatToDate(item.dateFrom) + '-' + commonfunctions.formatToDate(item.dateTo)}</i>
                                                </>) : ""}
                                        </Grid>


                                       
                                        <Grid item xs={2} align="center" sx={{ borderBottom: 1 }}>
                                            {item.estimatedQty}
                                        </Grid>
                                        <Grid item xs={2} align="center" sx={{ borderBottom: 1 }}>
                                            {commonfunctions.formatToCurrency(item.unitPrice)}
                                        </Grid>
                                        <Grid item xs={2} align="center" sx={{ borderBottom: 1 }}>
                                            {item.discountPercentage === null ? commonfunctions.formatToCurrency(item.discountAmt) : commonfunctions.formatToPercentage(item.discountPercentage)}
                                        </Grid>
                                        <Grid item xs={2} align="right" sx={{ borderBottom: 1 }}>
                                            {commonfunctions.formatToCurrency(item.extendedPrice)}
                                        </Grid>
                                    </Grid>
                                </React.Fragment>)
                            })}
                            {currentEstimate.roundOffAmount != "0" && currentEstimate.roundOffAmount != "" ? (
                                <>
                                    <Grid item xs={10} align="right" >
                                        <b>Round off&nbsp;&nbsp;&nbsp;</b>
                                    </Grid>
                                    <Grid item xs={2} align="right" sx={{ borderTop: "1px solid #ccc" }}>
                                        <b>{commonfunctions.formatToCurrency(currentEstimate.roundOffAmount)}</b>
                                    </Grid>
                                </>
                            ) : ("")}

                            <Grid item xs={10} align="right" >
                                <b>Total&nbsp;&nbsp;&nbsp;</b>
                            </Grid>
                            <Grid item xs={2} align="right" sx={{ borderTop: "1px solid #ccc" }}>
                                <b>{commonfunctions.formatToCurrency(currentEstimate.subscriptionTotal)}</b>
                            </Grid>
                        </Grid>
                       
                      
                     
                    </CardContent>
                </Card>
            </>
        );
    }
    const LoadCustomerSelectionStep = () => {
        if (currentCustomerIsLoaded.current) {
            return (
                <>

                    <Box display="flex" justifyContent="flex-end" >
                        <Tooltip title="Customer Search">
                            <Button variant="outlined" aria-label="Customer Search" onClick={showSearchSection} startIcon={<ManageSearchIcon />}>Customer Search</Button>
                        </Tooltip>
                    </Box>

                    {currentCustomerDetails && currentCustomerDetails.map(customerRecord => (
                        <div key={customerRecord.id}>
                            <Card sx={{ margin: 1 }} className="UWA_SearchResultCard" id={customerRecord.id}>
                                <CardHeader sx={{ borderBottom: '1px solid #ccc', backgroundColor: '#EEEEED', padding: '4px' }}
                                    avatar={
                                        <Avatar aria-label="">
                                            <BusinessRoundedIcon />
                                        </Avatar>
                                    }
                                    title={customerRecord.name + ' (' + customerRecord.id + ')'}
                                />
                                <span className="UWA_SearchResult_CB"></span>
                                <Box sx={{ display: 'flex', flexDirection: 'column', p: '4px' }}>
                                    <CardContent sx={{ flex: '1 0 auto' }}>

                                        <Typography component="div">
                                            <b>Sales Rep</b> {customerRecord.salesrepfirstname}  {customerRecord.salesreplastname}
                                        </Typography>
                                        {customerRecord.contactemail ? (
                                            <>
                                                <Typography component="div">
                                                    <b>Billing Contact</b> {customerRecord.contactfirstname}  {customerRecord.contactlastname} ( {customerRecord.contactemail} )
                                                </Typography>
                                            </>
                                        ) : (
                                                <>
                                                    <p>
                                                        <b>Billing Contact</b>  <span className="has-error">  MISSING BILLING CONTACT INFO </span>
                                                       
                                                    </p>
                                                </>
                                        ) }
                                     
                                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                            <Typography component="div" sx={{ paddingRight: 3 }}>
                                                <b>Billing Address</b>
                                                <p dangerouslySetInnerHTML={{ __html: commonfunctions.builAddress(customerRecord.billtoaddressee, customerRecord.billtoaddress1, customerRecord.billtoaddress2, customerRecord.billtocity, customerRecord.billtostate, customerRecord.billtozip, customerRecord.billtocountry) }} />
                                            </Typography>
                                            <Typography component="div">
                                                <b>Shipping Address</b>
                                                <p dangerouslySetInnerHTML={{ __html: commonfunctions.builAddress(customerRecord.shiptoaddressee, customerRecord.shiptoaddress1, customerRecord.shiptoaddress2, customerRecord.shiptocity, customerRecord.shiptostate, customerRecord.shiptozip, customerRecord.shiptocountry) }} />
                                            </Typography>
                                        </Box>

                                    </CardContent>

                                </Box>

                            </Card>

                        </div>
                    ))}
                </>
            );
        }
        else {
            return (
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            Welcome to the Subscription creation wizard. Lets start by selecting the customer.
                        </Grid>
                        <Grid item xs={12}>
                            <TextField sx={{ m: 1, width: '100%' }}
                                name="search"
                                label="Search by Customer Name or Oracle #"
                                size="small"
                                onChange={handleSearchFieldChange}
                                defaultValue={customerSearchQuery.current}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {customerSearchResults?.length > 0 ? (<ClearIcon onClick={(e) => { btnClearResults_Click(e) }} />) : (null)}
                                            <ManageSearchIcon onClick={(e) => { btnSearch_Click(e) }} />
                                        </InputAdornment>
                                    ),
                                }}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        btnSearch_Click(e);
                                    }
                                }}
                            />
                        </Grid>

                    </Grid>
                    {paginatedCustomerSearchResults && paginatedCustomerSearchResults.map(customerRecord => (
                        <div key={customerRecord.id}>
                            <Card sx={{ display: 'flex', margin: 1 }} className="UWA_SearchResultCard" id={customerRecord.id}>

                                <span className="UWA_SearchResult_CB"></span>
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <CardContent sx={{ flex: '1 0 auto' }}>
                                        <Typography component="div" variant="h6" className="UWA_SearchresultName">
                                            <BusinessRoundedIcon /> {customerRecord.name} ({customerRecord.id})
                                        </Typography>
                                        <Typography className="UWA_SearchResultAddress" color="text.secondary" component="div">
                                            <b>Sales Rep</b> {customerRecord.salesrepfirstname}  {customerRecord.salesreplastname}
                                        </Typography>
                                       
                                        {customerRecord.contactemail  ? (
                                            <>
                                                <Typography className="UWA_SearchResultAddress" color="text.secondary">
                                                    <b>Billing Contact</b> {customerRecord.contactfirstname}  {customerRecord.contactlastname} ( {customerRecord.contactemail} )
                                                </Typography>
                                            </>
                                        ) : (
                                                <>
                                                    <p className="UWA_SearchResultAddress">
                                                     <b>Billing Contact</b> <font className="has-error"> MISSING BILLING CONTACT INFO </font>
                                                    </p>
                                                  
                                                </>
                                        )}
                                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                            <Typography className="UWA_SearchResultAddress" color="text.secondary" component="div" sx={{ paddingRight: 3 }}>
                                                <b>Billing Address</b>
                                                <p dangerouslySetInnerHTML={{ __html: commonfunctions.builAddress(customerRecord.billtoaddressee, customerRecord.billtoaddress1, customerRecord.billtoaddress2, customerRecord.billtocity, customerRecord.billtostate, customerRecord.billtozip, customerRecord.billtocountry) }} />
                                            </Typography>
                                            <Typography className="UWA_SearchResultAddress" color="text.secondary" component="div">
                                                <b>Shipping Address</b>
                                                <p dangerouslySetInnerHTML={{ __html: commonfunctions.builAddress(customerRecord.shiptoaddressee, customerRecord.shiptoaddress1, customerRecord.shiptoaddress2, customerRecord.shiptocity, customerRecord.shiptostate, customerRecord.shiptozip, customerRecord.shiptocountry) }} />
                                            </Typography>
                                        </Box>
                                        {customerRecord.contactemail ? (
                                            <>
                                                <Button  variant="contained" className="UWA_btn_Submit" onClick={handleCustomerSelection} data-key={customerRecord.id}>
                                                    SELECT
                                                </Button>
                                            </>
                                        ) : (
                                                <>
                                                    <Button  variant="contained" disabled data-key={customerRecord.id}>
                                                        SELECT
                                                    </Button>
                                                </>
                                        )}
                                        
                                    </CardContent>

                                </Box>

                            </Card>

                        </div>
                    ))}
                    {customerSearchResults?.length > 0 ? (
                        <>
                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                <Box component="span" style={{ float: "left" }}>
                                    <font>Page #: {pageNumber}</font>
                                </Box>
                                <Box sx={{ flex: '1 1 auto' }} />
                                <Box component="span" style={{ float: "right" }}>
                                    <font>Total Records Found: {searchResultCount}</font>
                                    <IconButton color="secondary" onClick={handlePrevPage} aria-label="Previous" sx={{ color: red[600] }}>
                                        <ArrowLeftIcon />
                                    </IconButton>
                                    <IconButton color="secondary" onClick={handleNextPage} aria-label="Next" sx={{ color: red[600] }}>
                                        <ArrowRightIcon />
                                    </IconButton>
                                </Box>
                            </Box>


                        </>

                    ) : (null)}
                </>
            );
        }
    }
    const LoadSubscriptionStep = (x) => {
        //console.log(x.currentStep);
        if (x.currentStep === 0) {
            return(<LoadCustomerSelectionStep/>)
          
        }
        else if (x.currentStep === 1) {

            return (
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <br/>
                        </Grid>
                        <FormControl sx={{ width: '100%' }}>
                            <RadioGroup
                                aria-labelledby="addPlansLabel"
                                name="row-addPlansGroup"
                                defaultValue={currentPlanID.current}
                                onChange={handlePlanSelectionChange}
                            >
                        {planFamilies.sort().map((planFamily, index) => {
                            //console.log(planFamily);
                            return (
                                <React.Fragment key={planFamily}>
                                   <Card sx={{ margin: 1, width:'100%'}} key={planFamily.item_family_id}>
                                        <CardHeader className="UWA_SecondaryCardHeader" sx={{ padding: '4px' }} onClick={() => setExpandedSection(index === expandedSection ? null : index)}
                                            avatar={
                                                <Avatar aria-label="Plan Info">
                                                    <CardMembershipIcon />
                                                </Avatar>
                                            }
                                            title={planFamily}
                                            action={
                                                <>
                                                    <IconButton >
                                                        {expandedSection === index ? (<RemoveIcon />) : (<AddIcon />)}
                                                    </IconButton>
                                                   
                                                </> 
                                            }
                                       />
                                         
                                        <Box >
                                            <CardContent >
                                                {expandedSection === index ?
                                                    (
                                                       
                                                    <>
                                                            {availablePlans.filter(item => item.item_family_id == planFamily).sort(function (a, b) {
                                                                return (b.external_name === null) - (a.external_name === null) || -(b.external_name > a.external_name) || +(b.external_name < a.external_name);
                                                            }).map((item) => {
                                                                var isSpecialSKU = item.description.includes('Used for');
                                                                //console.log(isSpecialSKU);
                                                                if (isSpecialSKU)
                                                                {
                                                                    return (<React.Fragment key={item.id}>
                                                                        <div key={item.id}>
                                                                            <Typography component="div" variant="h6" className="UWA_PlanNameSpecial">
                                                                                <FormControlLabel value={item.id} control={<Radio inputProps={{ 'title': item.id }} />} label={item.external_name} key={item.id} />
                                                                            </Typography>
                                                                            <Typography className="UWA_PlanDescription" color="text.secondary" component="div">
                                                                                <i> {item.description + ' (' + item.id + ') '}</i>
                                                                            </Typography>
                                                                        </div>
                                                                    </React.Fragment>)
                                                                }
                                                                else {
                                                                    return (<React.Fragment key={item.id}>
                                                                        <div key={item.id}>
                                                                            <Typography component="div" variant="h6" className="UWA_PlanName">
                                                                                <FormControlLabel value={item.id} control={<Radio inputProps={{ 'title': item.id }} />} label={item.external_name} key={item.id} />
                                                                            </Typography>
                                                                            <Typography className="UWA_PlanDescription" color="text.secondary" component="div">
                                                                                <i> {item.description + ' (' + item.id + ') '}</i>
                                                                            </Typography>
                                                                        </div>
                                                                    </React.Fragment>)
                                                                };
                                                           
                                                        })}
                                                    </>


                                                ): (null)}
                                       
                                            </CardContent>

                                        </Box>
                                        </Card>
                                </React.Fragment>
                            )
                        }) }
                            </RadioGroup>
                        </FormControl>
                  </Grid>
                </>
            );
        }
        else if (x.currentStep === 2)
        {
            return (
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <br/>
                        </Grid>
                        <Card sx={{ margin: 1, width:'100%' }} className="UWA_SearchResultCard" >
                            <CardHeader className="UWA_SecondaryCardHeader"
                                avatar={
                                    <Avatar aria-label="">
                                        <AttachMoneyIcon />
                                    </Avatar>
                                }
                                title="Billing Details"
                            />
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                    <FormControl sx={{ mR:'1px',pR: '4px', minWidth: 190 }}>
                                        <InputLabel>Billing Frequency</InputLabel>
                                        <Select
                                            value={currentPlanItemPriceID}
                                            label="Billing Frequency"
                                            onChange={handleBillingFrequencyChange}
                                        >
                                            {currentPlanDetails && currentBillingFrequencies.map(item => (
                                                <MenuItem value={item.id} key={item.id}>{item.frequency}</MenuItem>
                                       
                                            )) }   
                                        </Select>
                                   
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en'}>
                                           
                                                    <DatePicker sx={{ width: 190 }}
                                                            label="Start Date"
                                                            value={currentSubscriptionStartDate}
                                                            onChange={(newValue) => { setStartDate(newValue); }}
                                       
                                                        />
                                                </LocalizationProvider>
                                    </Grid>
                                    {currentSKUSupportsTrial ? (<>
                                        <Grid item xs={6}><FormControlLabel control={<Switch checked={isTrial} onChange={handleIsTrialSubscription} />} label="Is Trial Subscription" /></Grid>
                                        <Grid item xs={6}>
                                            <TextField style={{ width: '100%' }}
                                                name="trialInterval"
                                                label="Trial Interval (In Days)"
                                                size="small"
                                                value={trialInterval}
                                                onChange={handleTrialIntervalChange}
                                                disabled={trialIntervalFieldDisabled}
                                            />
                                        </Grid>
                                        {isTrial ? (
                                            <>
                                                <Grid item xs={12}><FormControlLabel control={<Switch checked={notifyUsers} onChange={handleNotifyUsersChange} />} label="Notify Users" /></Grid>
                                            </>

                                        ): null}
                                    </>) : null}
                                    <PermissionsGate scopes={[SCOPES.canManageSubscriptions]} showMessage="false" >
                                    <Grid item xs={5}><FormControlLabel control={<Switch checked={applyDiscount} onChange={handleApplyDiscountsChange} />} label="Apply Discount" /></Grid>
                                  
                                    {applyDiscount ? (
                                        <>
                                            <Grid item xs={4}>
                                                <TextField style={{ width: '100%' }}
                                                    name="discount"
                                                    label="Discount Applied"
                                                    size="small"
                                                    value={customDiscountValue}
                                                    onChange={handleCustomDiscountValueChange}
                                                    autoFocus
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <RadioGroup
                                                    aria-labelledby="discountChoicesLabel"
                                                    name="row-discountChoiceGroup"
                                                    row
                                                    defaultValue={customDiscountType}
                                                    onChange={handleDiscountTypeChange}
                                                >
                                                    <FormControlLabel value="dollarDiscount" control={<Radio inputProps={{ 'title': 'Discount in Dollars' }} />} label="$" />
                                                    <FormControlLabel value="percentageDiscount" control={<Radio inputProps={{ 'title': 'Discount as a Percentage' }} />} label="%" />
                                                </RadioGroup>
                                            </Grid>
                                            <Grid item xs={5}>
                                            </Grid>
                                            <Grid item xs={6} align="right">
                                                <RadioGroup
                                                    aria-labelledby="discountDurationLabel"
                                                    name="row-discountDurationGroup"
                                                    row
                                                    defaultValue={customDiscountDuration}
                                                    onChange={handleDiscountDurationChange}
                                                >
                                                    <FormControlLabel value="One Time" control={<Radio inputProps={{ 'title': 'Discount applies one time' }} />} label="One Time" />
                                                    <FormControlLabel value="Forever" control={<Radio inputProps={{ 'title': 'Discount applies forever' }} />} label="Forever" />
                                                </RadioGroup>
                                            </Grid>
                                            </>) : null}
                                    </PermissionsGate>
                               </Grid>
                            </CardContent>
                        </Card>
                        {currentPlanDetails.addOnList?.length > 0 ? (<Card sx={{ margin: 1, width: '100%' }} className="UWA_SearchResultCard" >
                            <CardHeader className="UWA_SecondaryCardHeader"
                                avatar={
                                    <Avatar aria-label="">
                                        <PlaylistAddIcon />
                                    </Avatar>
                                }
                                title="Addons"
                            />
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        {currentPlanDetails && currentPlanDetails.addOnList.sort().map(item => (
                                            (currentAddons?.findIndex(addon => addon.id === item.id) > -1) ? (
                                                <FormControlLabel control={<Switch onChange={handleAddOnSelectionChange} checked inputProps={{ 'data-addonid': item.id, 'data-addonname': item.name }} />} label={item.name + ' (' + item.id + ')'} key={item.id} />
                                            ) : (
                                                    <FormControlLabel control={<Switch onChange={handleAddOnSelectionChange} inputProps={{ 'data-addonid': item.id, 'data-addonname': item.name }} />} label={item.name + ' (' + item.id + ')'} key={item.id} />
                                            )
                                            
                                        ))}
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>) : (null)}
                    </Grid>
                </>
            );
        }
        else if (x.currentStep === 3) {
            return (
                <>
                    <Grid container spacing={2}>
                        <LoadExtendedInfoPanel planInfo={currentPlanDetails} />
                  
                    </Grid>
                </>
            );
        }
        else if (x.currentStep === 4) {
            
            
            return (
                <Grid container spacing={2}>
                    <LoadSubscriptionEstimate />
                    <PermissionsGate scopes={[SCOPES.canManageSubscriptions]} showMessage="false" >
                    <Grid item xs={12} align="right">
                        <FormControlLabel
                            control={<Switch checked={consolidatedInvoicing}
                                onChange={handleUnbilledChargesChange}
                            />}
                            label="Add to unbilled charges" /><br/>
                        <FormControlLabel
                            control={<Switch checked={completeInvoices}
                                onChange={handleCompleteInvoicesChange}
                                inputProps={{ 'title': 'Create a single invoice for all the unbilled charges for this customer' }}
                            />}
                            label="Complete consolidated invoices" />
                        </Grid>
                    </PermissionsGate>
                </Grid>
            )
        }
        else return null;

    };
    const LoadSubscriptionEstimate = () => {
       
       return (
            <>
                <Grid item>
                    Below is the estimate for the subscription. Please click "Finish" to create the subscription or use the back button to make changes to the subscription. 
                   <LoadEstimateDetails/>
                </Grid>
            </>
        );
    }
    const LoadExtendedInfoPanel = (currentPlan) => {
        if (currentPlanDetails && currentPlanDetails.extended_plan_type === 'USER') {
          
            return (
                <>
                    <Grid item xs={12}>
                        <br/>
                    </Grid>
                    <Card sx={{ margin: 1, width: '100%' }} className="UWA_SearchResultCard" >
                        <CardHeader className="UWA_SecondaryCardHeader"
                            avatar={
                                <Avatar aria-label="">
                                    <CardMembershipIcon />
                                </Avatar>
                            }
                            title="User Info"
                        />
                        <CardContent>
                            {currentExtendedInfoItems.length === 0 ? (
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        Please use the form below to add users for this subscription. If there are any add-ons for this subscription you can select them below when adding the user.
                                    </Grid>
                                </Grid>
                            ) : (null)}
                            <div className="UWA_ExtendedInfoItemsWrapper">
                                {currentExtendedInfoItems && currentExtendedInfoItems.map(item => (
                                    <React.Fragment key={item.key}>
                                    <Card style={{ display: 'inline-block', padding: '3px', minHeight: '17vh', width:'200px', margin: '5px' }} spacing={2} key={item.key}>
                                        <CardMedia>

                                            <Avatar sx={{ width: 35, height: 35, margin: "auto", bgcolor: "#3333cc" }}>
                                                <PersonIcon sx={{ width: 30, height: 30, bgcolor: "#3333cc" }} />
                                            </Avatar>
                                        </CardMedia>
                                        <CardContent>
                                            <Container >
                                                <Row>
                                                    <Col xs={12} md={12}>
                                                        <center>
                                                            <b>{item.key}</b>
                                                        </center>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} md={12}>
                                                        <TextField style={{ width: '100%' }}
                                                            name="fullName"
                                                            label="Name"
                                                            size="small"
                                                            InputProps={{
                                                                readOnly: true,
                                                            }} variant="standard"
                                                            defaultValue={commonfunctions.formatFullName(item.firstName, item .lastName)}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} md={12}>
                                                        <TextField style={{ width: '100%' }}
                                                            name="email"
                                                            label="Email"
                                                            size="small"
                                                            InputProps={{
                                                                readOnly: true,
                                                            }} variant="standard"
                                                            defaultValue={item.email}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} md={12}>
                                                        <center>
                                                            {item.addons && item.addons.sort((p1, p2) => p1.id > p2.id ? 1 : -1).map((addON, index) => {

                                                                if (addON.id === "RWA-WB-102173")
                                                                    return (<React.Fragment key={item.id}><RWAWBBadge /></React.Fragment>)
                                                                else if (addON.id === "RW-ANALYSIS-102172")
                                                                    return (<React.Fragment key={item.id}><RWABadge /></React.Fragment>)
                                                                else if (addON.id === "APG-TEXTRON-102394")
                                                                    return (<React.Fragment key={item.id}><AGPTextronBadge /></React.Fragment>)
                                                                else return (<React.Fragment key={item.id}><UnknownBadge name={item.id} /></React.Fragment>)
                                                            })}
                                                        </center>
                                                    </Col>
                                                </Row>
                                            </Container>

                                        </CardContent>
                                    </Card>
                                    </React.Fragment>
                                ))}
                            </div>
                            <AddNewUserForm />
                        </CardContent>
                    </Card>
                </>

            );
        }
        else if (currentPlanDetails && currentPlanDetails.extended_plan_type === 'REGISTRY') {
         
            return (
                <>
                    <Grid item xs={12}>
                        <br/>
                    </Grid>
                    <Card sx={{ margin: 1, width: '100%' }} className="UWA_SearchResultCard" >
                        <CardHeader className="UWA_SecondaryCardHeader"
                            avatar={
                                <Avatar aria-label="">
                                    <CardMembershipIcon />
                                </Avatar>
                            }
                            title="Registry Info"
                        />
                        <CardContent>
                            {currentExtendedInfoItems.length === 0 ? (
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                     Please use the form below to add registries for this subscription. If the registry needs the add-on simply select the add-on before clicking the submit button. 
                                    </Grid>
                                </Grid>
                            ) : (null)}
                            <div className="UWA_ExtendedInfoItemsWrapper">
                                {currentExtendedInfoItems && currentExtendedInfoItems.map(item => (
                                    <React.Fragment key={item.key}>
                                        <Card style={{ display: 'inline-block', padding: '3px', minHeight:'17vh', margin:'5px' }} spacing={2} >
                                            <CardMedia>

                                                <Avatar sx={{ width: 35, height: 35, margin: "auto", bgcolor: "#3333cc" }}>
                                                    <ConnectingAirportsIcon sx={{ width: 30, height: 30, bgcolor:"#3333cc" }} />
                                                </Avatar>
                                            </CardMedia>
                                            <CardContent>
                                                <Container >
                                                    <Row>
                                                        <Col xs={12} md={12}>
                                                            <center>
                                                                <b>{item.key}</b>
                                                            </center>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={12} md={12}>
                                                            <center>
                                                                {item.addons && item.addons.sort((p1, p2) => p1.id > p2.id ? 1 : -1).map((addON, index) => {

                                                                    if (addON.id === "RWA-WB-102173")
                                                                        return (<React.Fragment key={item.id}><RWAWBBadge /></React.Fragment>)
                                                                    else if (addON.id === "RW-ANALYSIS-102172")
                                                                        return (<React.Fragment key={item.id}><RWABadge /></React.Fragment>)
                                                                    else if (addON.id === "APG-TEXTRON-102394")
                                                                        return (<React.Fragment key={item.id}><AGPTextronBadge /></React.Fragment>)
                                                                    else return (<React.Fragment key={item.id}><UnknownBadge name={item.id} /></React.Fragment>)
                                                                })}
                                                            </center>
                                                        </Col>
                                                    </Row>
                                                </Container>
                                       
                                            </CardContent>
                                        </Card>
                                    </React.Fragment>
                            ))}
                            </div>
                            <AddNewRegistryForm/>
                        </CardContent>
                    </Card>
                </>);
        }
        else {
            return (
                <>
                    <Grid item xs={12}>
                       Please click on the next button to proceed to next step.
                    </Grid>
                </>
            );
        }
    }
    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };
    const resetAddOnItems = () => {
        newExtendedInfo_Key.current = "";
        newExtendedInfo_FName.current = "";
        newExtendedInfo_LName.current = "";
        newExtendedInfo_Email.current = "";
        newExtendedInfo_AddOns.current = [];
        toggleUserEntryClearFieldButton(true);
        updateErrorMsg("");
    }
    const handleReset = () => {
        setActiveStep(0);

        resetAddOnItems();
    };

    useEffect(() => {
        if (dpsStatusCheckStatus === 2) {
            resetAddOnItems();
            updateDPSCheckStatus(0);
        }
    }, [dpsStatusCheckStatus]);
    //useEffect(() => {
    //    if (!currentCustomerIsLoaded.current && currentCustomerID.current != '') {
    //        toggleWaitModal(true);
    //        load_CurrentCustomerDetails();
    //        toggleWaitModal(false);
    //    }
    //    //get_AvailablePlans();

    //}, []);
    useEffect(() => {
        if (activeStep == 0) {
            if (availablePlans.length === 0) {
                get_AvailablePlans();
            }
            
            if (!currentCustomerIsLoaded.current && currentCustomerID.current != '') {
                toggleWaitModal(true);
                load_CurrentCustomerDetails();
                toggleWaitModal(false);
            }
        }
        if (activeStep == 4) {
            getNewSubscriptionEstimate();
        }
        else if (activeStep == 5) {
            createNewSubscription();
        }
    }, [activeStep]);

 

    useEffect(() => {
        setNotificationFlag(isTrial);
    },[isTrial])
    const handleCustomerSelection = (e) => {
        var oracleID = e.currentTarget.dataset.key;

        //console.log(oracleID);
        currentCustomerID.current = oracleID;
        updateCustomerSearchResults([]);
        updateSearchResultCount(0);
        setPageNumber(1);
        customerSearchQuery.current = ("");
        resetAddOnItems();
        load_CurrentCustomerDetails();
        getConsolidatedInvoicing(oracleID);
    }
    return (
        <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    if (isStepOptional(index)) {
                        labelProps.optional = (
                            <Typography variant="caption">Optional</Typography>
                        );
                    }
                    if (isStepSkipped(index)) {
                        stepProps.completed = false;
                    }
                   
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                          
                        </Step>
                    );
                })}
            </Stepper>
            {activeStep === steps.length ? (
                <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                        You have completed the subscription creation process. If there were no errors while generating your subscription you will be redirected to the newly created subscription shortly. 
                    </Typography>
                    {errorMsg ? <Alert severity="error" >{errorMsg}</Alert> : ""}
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button onClick={handleReset}>Reset</Button>
                    </Box>
                </React.Fragment>
            ) : (
                    <React.Fragment>
                        {showWaitModal ? (
                            <ModalWait

                            />
                        ) : null}
                        <Grid container sx={{ padding:'4px' }}>
                            <Grid item xs={12} md={12}>
                                <LoadSubscriptionStep currentStep={activeStep} />
                            </Grid>

                        </Grid>
                        {infoMsg ? <Alert severity="info" >{infoMsg}</Alert> : ""}
                        {errorMsg ? <Alert severity="error" >{errorMsg}</Alert> : ""}
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Button
                            color="inherit"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{ mr: 1 }}
                        >
                            Back
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        {isStepOptional(activeStep) && (
                            <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                                Skip
                            </Button>
                        )}

                        <Button onClick={handleNext}>
                            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                        </Button>
                    </Box>
                </React.Fragment>
            )}
        </Box>
    );
}